import ApiService from '@/common/api.service';

const TransitionPeriodService = {
  createTransitionPeriodWorkspace(payload) {
    const resource = 'transition-periods/';

    return ApiService.post(resource, payload);
  },
  getDetails(workspace_id) {
    const resource = `transition-periods/${workspace_id}/details/`;

    return ApiService.get(resource);
  },

  getDistinctTransitionPeriodFrequencies() {
    const resource = 'transition-periods/frequencies/';
    return ApiService.get(resource);
  },

  getDistinctTransitionPeriodPlannedWeekNumbers(payload) {
    const resource = 'transition-periods/planned-week-no/';
    return ApiService.post(resource, payload);
  },
};

export default TransitionPeriodService;
