<template>
  <div class="rounded-md border border-gray-100 bg-white">
    <!-- @slot header content -->
    <slot name="header">
      <div v-if="title" class="px-4 sm:px-6 py-2 sm:py-4 border-b border-gray-100">
        <!-- @slot substitute content within header wrapper -->
        <slot name="header-inner">
          <div class="flex justify-between items-down">
            <h6 class="text-gray-700 text-xl font-bold truncate">{{ title }}</h6>

            <icon-chev-down
              v-if="collapsable"
              class="text-gray-600 mt-0.5 cursor-pointer w-6 h-6 transform"
              :class="{
              'rotate-180': !isCollapsed
            }"
              @click.native="toggleCollapsed"
            ></icon-chev-down>
          </div>
        </slot>
      </div>
    </slot>
    <!-- @slot body content -->
    <slot name="body">
      <div v-if="!isCollapsed" class="px-4 sm:px-6 py-2 sm:py-4">
        <!-- @slot substitute content within body wrapper -->
        <slot></slot>
      </div>
    </slot>

    <!-- @slot footer content -->
    <slot v-if="!!$slots.footer || !!$slots['footer-inner']" name="footer">
      <div class="px-4 sm:px-6 py-2 sm:py-4 border border-t border-gray-100">
        <!-- @slot substitute content within footer wrapper -->
        <slot name="footer-inner"></slot>
      </div>
    </slot>
  </div>
</template>

<script>
/**
 * @displayName Card
 */
export default {
  name: 'BaseCard',
  props: {
    /**
     * Title of card
     */
    title: {
      type: String,
      default: '',
    },
    collapsable: {
      type: Boolean,
      description: 'Whether a chevron will be showed in the header to toggle visibility of the body',
    },
  },
  data() {
    return {
      isCollapsed: false,
    };
  },
  methods: {
    toggleCollapsed() {
      this.isCollapsed = !this.isCollapsed;
    },
  },
};
</script>
