<template>
  <div class="rounded-md p-4 border-2 border-gray-200 bg-gray-50">
    <div class="flex" :class="[alignCenter ? 'items-center' : 'items-start']">
      <div class="flex-shrink-0">
        <icon-check-circle-solid v-if="type === 'success'" classes="h-6 w-6 text-green-500"></icon-check-circle-solid>
        <icon-information-circle-solid v-else-if="type === 'info'" classes="h-6 w-6 text-blue-500"></icon-information-circle-solid>
        <icon-x-circle-solid v-else-if="type === 'error'" classes="h-6 w-6 text-red-500"></icon-x-circle-solid>
        <icon-exclamation v-else-if="type === 'warning'" class="h-6 w-6 text-yellow-700"></icon-exclamation>
      </div>
      <div class="ml-3 w-full">
        <p
          class="text-sm leading-5 font-medium flex items-center justify-between"
          :class="text800[color]"
        >
          <slot name="title">{{ title }}</slot>

          <svg
            v-if="loading"
            :class="text800[color]"
            class="animate-spin -ml-1 mr-3 h-5 w-5"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle
              class="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              stroke-width="4"
            />
            <path
              class="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            />
          </svg>
        </p>
        <div v-if="description || $slots.default" class="mt-2 text-sm leading-5 text-gray-900">
          <slot>
            <p>{{ description }}</p>
          </slot>
        </div>
      </div>
      <div v-if="dismissable" class="ml-auto pl-3">
        <div class="-mx-1.5 -my-1.5">
          <button
            class="inline-flex rounded-md p-1.5 focus:outline-none transition ease-in-out duration-150"
            :class="[...dismissClass]"
            aria-label="Dismiss"
            @click="dismiss"
          >
            <!-- Heroicon name: x -->
            <svg class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
              <path
                fill-rule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clip-rule="evenodd"
              />
            </svg>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { background50 } from '@/util/tailwind/backgrounds';
import { text500, text700, text800 } from '@/util/tailwind/text';
import { focusBackground100 } from '@/util/tailwind/focus';
import { hoverBackground100 } from '@/util/tailwind/hover';

import { stateToColor } from '@/util/tailwind/util';

import { state } from '@/util/validator.props';

/**
 * @displayName Alert
 */
export default {
  name: 'BaseAlert',
  props: {
    /**
     * Title to be displayed in alert
     */
    title: {
      type: String,
      default: '',
    },
    /**
     * Description to be displayed under title
     */
    description: {
      type: String,
      default: '',
    },
    /**
     * Alert type to control icon and colors
     * @values success | warning | error | info
     */
    type: {
      type: String,
      required: true,
      validator: state,
    },
    /**
     * Whether the alert should be dismissable through an X icon
     */
    dismissable: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    alignCenter: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      background50: Object.freeze(background50),
      text500: Object.freeze(text500),
      text700: Object.freeze(text700),
      text800: Object.freeze(text800),
    };
  },
  computed: {
    color() {
      return stateToColor(this.type);
    },
    dismissClass() {
      return [text500[this.color], focusBackground100[this.color], hoverBackground100[this.color]];
    },
  },
  methods: {
    dismiss() {
      this.$destroy();

      if (this.$el.parentNode) {
        this.$el.parentNode.removeChild(this.$el);
      }
    },
  },
};
</script>
