<template>
  <transition name="fade">
    <div v-show="visible" class="loading-mask">
      <div class="mask-inner">
        <div
          class="spinner"
          :style="{ height: `${height}px`, width: `${width}px` }"
        >
          <div class="double-bounce1 bg-indigo-500"></div>
          <div class="double-bounce2 bg-indigo-500"></div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
/**
 * @displayName Loading
 */
export default {
  name: 'Loading',
  data() {
    return {
      visible: false,
      height: 40,
      width: 40,
    };
  },
};
</script>

<style>
.loading-directive {
  position: relative !important;
}

.loading-mask {
  position: absolute;
  @apply bg-gray-100 opacity-50;
  margin: 0;
  top: 0;
  right: 0;
  z-index: 9999;
  bottom: 0;
  left: 0;
  transition: opacity 0.1s;
}

.mask-inner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.spinner {
  position: relative;
}

.double-bounce1,
.double-bounce2 {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  opacity: 0.6;
  position: absolute;
  top: 0;
  left: 0;

  -webkit-animation: sk-bounce 2s infinite ease-in-out;
  animation: sk-bounce 2s infinite ease-in-out;
}

.double-bounce2 {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}

@-webkit-keyframes sk-bounce {
  0%,
  100% {
    -webkit-transform: scale(0);
  }
  50% {
    -webkit-transform: scale(1);
  }
}

@keyframes sk-bounce {
  0%,
  100% {
    transform: scale(0);
    -webkit-transform: scale(0);
  }
  50% {
    transform: scale(1);
    -webkit-transform: scale(1);
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
