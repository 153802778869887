export const border300 = {
  red: 'border-red-300',
  green: 'border-green-300',
  blue: 'border-blue-300',
  teal: 'border-teal-300',
  yellow: 'border-yellow-300',
  pink: 'border-pink-300',
  purple: 'border-purple-300',
  gray: 'border-gray-300',
  indigo: 'border-indigo-300',
  orange: 'border-orange-300',
};

export const border400 = {
  red: 'border-red-400',
  green: 'border-green-400',
  blue: 'border-blue-400',
  teal: 'border-teal-400',
  yellow: 'border-yellow-400',
  pink: 'border-pink-400',
  purple: 'border-purple-400',
  gray: 'border-gray-400',
  indigo: 'border-indigo-400',
  orange: 'border-orange-400',
};

export const border600 = {
  red: 'border-red-600',
  green: 'border-green-600',
  blue: 'border-blue-600',
  teal: 'border-teal-600',
  yellow: 'border-yellow-600',
  pink: 'border-pink-600',
  purple: 'border-purple-600',
  gray: 'border-gray-600',
  indigo: 'border-indigo-600',
  orange: 'border-orange-600',
};
