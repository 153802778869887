export const hoverBackground50 = {
  red: 'hover:bg-red-50',
  green: 'hover:bg-green-50',
  blue: 'hover:bg-blue-50',
  teal: 'hover:bg-teal-50',
  yellow: 'hover:bg-yellow-50',
  pink: 'hover:bg-pink-50',
  purple: 'hover:bg-purple-50',
  gray: 'hover:bg-gray-50',
  indigo: 'hover:bg-indigo-50',
};

export const hoverBackground100 = {
  red: 'hover:bg-red-100',
  green: 'hover:bg-green-100',
  blue: 'hover:bg-blue-100',
  teal: 'hover:bg-teal-100',
  yellow: 'hover:bg-yellow-100',
  pink: 'hover:bg-pink-100',
  purple: 'hover:bg-purple-100',
  gray: 'hover:bg-gray-100',
  indigo: 'hover:bg-indigo-100',
};

export const hoverBackground500 = {
  red: 'hover:bg-red-500',
  green: 'hover:bg-green-500',
  blue: 'hover:bg-blue-500',
  teal: 'hover:bg-teal-500',
  yellow: 'hover:bg-yellow-500',
  pink: 'hover:bg-pink-500',
  purple: 'hover:bg-purple-500',
  gray: 'hover:bg-gray-500',
  indigo: 'hover:bg-indigo-500',
};

export const hoverBackground700 = {
  red: 'hover:bg-red-700',
  green: 'hover:bg-green-700',
  blue: 'hover:bg-blue-700',
  teal: 'hover:bg-teal-700',
  yellow: 'hover:bg-yellow-700',
  pink: 'hover:bg-pink-700',
  purple: 'hover:bg-purple-700',
  gray: 'hover:bg-gray-700',
  indigo: 'hover:bg-indigo-700',
  orange: 'hover:bg-orange-700',
};

export const hoverText500 = {
  red: 'hover:text-red-500',
  green: 'hover:text-green-500',
  blue: 'hover:text-blue-500',
  teal: 'hover:text-teal-500',
  yellow: 'hover:text-yellow-500',
  pink: 'hover:text-pink-500',
  purple: 'hover:text-purple-500',
  gray: 'hover:text-gray-500',
  indigo: 'hover:text-indigo-500',
  orange: 'hover:text-orange-500',
};
