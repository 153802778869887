import ExternalAccessInterceptor from '@/common/external_access_interceptor';

import axios from 'axios';
import queryBuilder from '@/util/queryBuilder';

const SharedService = {
  instance: null,

  init(token) {
    this.instance = axios.create({
      baseURL: process.env.VUE_APP_APIURL,
      headers: {
        'Accept-Language': 'da-DK',
        'External-Access-Token': token,
      },
    });

    // eslint-disable-next-line no-new
    new ExternalAccessInterceptor(this.instance);

    return this;
  },

  getFilters() {
    if (this.instance == null) {
      throw new Error('SharedService has not been initted');
    }

    return this.instance.get('shared/');
  },

  filter(payload) {
    if (this.instance == null) {
      throw new Error('SharedService has not been initted');
    }

    return this.instance.post('shared/', payload);
  },

  getWorkspaceDetails() {
    if (this.instance == null) {
      throw new Error('SharedService has not been initted');
    }

    return this.instance.get('shared/workspace/');
  },

  getTaskDetails(body) {
    if (this.instance == null) {
      throw new Error('SharedService has not been initted');
    }
    return this.instance.post('shared/details/', body);
  },

  getDrivingListInfo() {
    if (this.instance == null) {
      throw new Error('SharedService has not been initted');
    }

    return this.instance.get('shared/driving-lists/');
  },

  getAllVehicles() {
    if (this.instance == null) {
      throw new Error('SharedService has not been initted');
    }

    return this.instance.get('shared/vehicles/');
  },

  getFrequencies() {
    if (this.instance == null) {
      throw new Error('SharedService has not been initted');
    }

    return this.instance.get('shared/frequencies/');
  },

  getMostIrregularFrequencyIntervals() {
    if (this.instance == null) {
      throw new Error('SharedService has not been initted');
    }

    return this.instance.get('shared/frequency-intervals/');
  },

  getFrequenciesInUseAsSelectOptions() {
    if (this.instance == null) {
      throw new Error('SharedService has not been initted');
    }

    return this.instance.get('shared/frequencies/uses/?selectOptions=1');
  },

  downloadDrivingLists() {
    if (this.instance == null) {
      throw new Error('SharedService has not been initted');
    }

    const resource = 'shared/driving-lists/downloads/';
    this.instance.get(resource).then(({ data }) => {
      const link = document.createElement('a');
      link.href = data;
      link.click();
      link.remove();
    });
  },
  aggregateBy(aggregation, countingType) {
    if (this.instance == null) {
      throw new Error('SharedService has not been initted');
    }

    const resource = `shared/aggregations/${aggregation}/${countingType}`;
    return this.instance.get(resource);
  },
  getCursor(link, params, taskIdList) {
    if (this.instance == null) {
      throw new Error('SharedService has not been initted');
    }
    console.log('inside getcursor shared');
    const resource = link || 'tasks/';
    const resourceToQuery = queryBuilder(link, resource, params);
    console.log('resourceToQuery', resourceToQuery);
    return this.instance.post(`shared/${resourceToQuery}`, {
      tasks: taskIdList,
    });
  },
};

export default SharedService;
