/* eslint-disable no-param-reassign */
/* eslint-disable camelcase */
/* eslint-disable object-curly-newline */
/* eslint-disable no-shadow */
import ImportService from '@/services/import.service';

import * as jobTypes from '@/util/jobTypes';
import * as a from '../actions.type';
import * as m from '../mutations.type';

const state = {
  mergePlan: null,
  isUploadingForImport: false,
  userChosenId: null,
  filename: null,
  newFrequencyMappings: null,
  newFrequenciesToMap: [],
  hasExecuteMergePlan: false,
  completedSteps: [],
  // current fields used after navigating away from importuploadview
  currentConfiguration: null,
  currentJobId: null,
  currentFilename: null,
};

const getters = {
  mergePlan() {
    return state.mergePlan;
  },
  userChosenId(state) {
    return state.userChosenId;
  },
  filename(state) {
    return state.filename;
  },
  newFrequencyMappings(state) {
    return state.newFrequencyMappings;
  },
  newFrequenciesToMap(state) {
    return state.newFrequenciesToMap;
  },
  isUploadingForImport(state) {
    return state.isUploadingForImport;
  },
  hasExecuteMergePlan(state) {
    return state.hasExecuteMergePlan;
  },
  completedSteps(state) {
    return state.completedSteps;
  },
};

const actions = {
  [a.BUILD_MERGE_PLAN]({ commit, state }) {
    return new Promise((resolve, reject) => {
      ImportService.buildMergePlan({ job_id: state.currentJobId, configuration: state.currentConfiguration, user_chosen_id: state.userChosenId, frequency_mappings: state.newFrequencyMappings, filename: state.currentFilename })
        .then(({ data }) => {
          const jobTypePayload = {
            type: jobTypes.NONINITIALIMPORT,
            jobId: data,
          };
          commit(`jobs/${m.SET_JOB_ID_BY_TYPE}`, jobTypePayload, { root: true });
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [a.UPLOAD_FOR_MERGE_PLAN](_context, payload) {
    return new Promise((resolve, reject) => {
      ImportService.uploadForMergePlan(payload)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [a.SUBMIT_MERGE_PLAN_CONFIRM]({ commit }) {
    return new Promise((resolve, reject) => {
      ImportService.submitMergePlan(state.mergePlan.id)
        .then(({ data }) => {
          const jobTypePayload = {
            type: jobTypes.EXECUTEMERGEPLAN,
            jobId: data,
          };
          commit(`jobs/${m.SET_JOB_ID_BY_TYPE}`, jobTypePayload, { root: true });
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [a.IMPORT_TASKS]({ commit }, payload) {
    commit(m.SET_IS_UPLOADING_FOR_IMPORT, true);
    return new Promise((resolve, reject) => {
      ImportService.uploadFile(payload)
        .then(({ data }) => {
          const jobTypePayload = {
            type: jobTypes.IMPORT_TASKS,
            jobId: data,
          };
          commit(`jobs/${m.SET_JOB_ID_BY_TYPE}`, jobTypePayload, { root: true });
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        })
        .finally(() => {
          commit(m.SET_IS_UPLOADING_FOR_IMPORT, false);
        });
    });
  },
  [a.IMPORT_EXTRA_TASKS]({ commit }, payload) {
    commit(m.SET_IS_UPLOADING_FOR_IMPORT, true);
    return new Promise((resolve, reject) => {
      ImportService.uploadFileExtraTasks(payload)
        .then(({ data }) => {
          const jobTypePayload = {
            type: jobTypes.IMPORT_EXTRA_TASKS,
            jobId: data,
          };
          commit(`jobs/${m.SET_JOB_ID_BY_TYPE}`, jobTypePayload, { root: true });
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        })
        .finally(() => {
          commit(m.SET_IS_UPLOADING_FOR_IMPORT, false);
        });
    });
  },
  [a.GET_NEW_FREQUENCIES_TO_MAP]({ commit, state }) {
    return new Promise((resolve, reject) => {
      ImportService.getNewFrequencies({ job_id: state.currentJobId, configuration: state.currentConfiguration })
        .then(({ data }) => {
          commit(m.SET_MERGE_NEW_FREQS_TO_MAP, data);
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [a.GET_IMPORT_AUDIT]() {
    return new Promise((resolve, reject) => {
      ImportService.getAudit()
        .then(({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [a.GET_CSV_HEADERS](_context, payload) {
    return new Promise((resolve, reject) => {
      ImportService.getHeaders(payload)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};

const mutations = {
  [m.SET_MERGE_PLAN](state, mergePlan) {
    state.mergePlan = mergePlan;
  },
  [m.SET_IS_UPLOADING_FOR_IMPORT](state, isUploading) {
    state.isUploadingForImport = isUploading;
  },
  [m.SET_MERGE_USER_CHOSEN_ID](state, userChosenId) {
    state.userChosenId = userChosenId;
  },
  [m.SET_MERGE_NEW_FREQ_MAPPINGS](state, newFrequencyMappings) {
    state.newFrequencyMappings = newFrequencyMappings;
  },
  [m.SET_MERGE_NEW_FREQS_TO_MAP](state, newFrequenciesToMap) {
    state.newFrequenciesToMap = newFrequenciesToMap;
  },
  [m.SET_CURRENT_FIELDS_AFTER_IMPORT](state, { configuration, jobId, filename }) {
    state.currentConfiguration = configuration;
    state.currentJobId = jobId;
    state.currentFilename = filename;
  },
  [m.SET_HAS_EXECUTED_MERGE_PLAN](state, hasExecuted) {
    state.hasExecuteMergePlan = hasExecuted;
  },
  [m.SET_FILENAME](state, filename) {
    state.filename = filename;
  },
  [m.ADD_COMPLETED_MERGE_FLOW_STEP](state, step) {
    state.completedSteps.push(step);
  },
  [m.RESET_FLOW](state) {
    state.mergePlan = null;
    state.isUploadingForImport = false;
    state.userChosenId = null;
    state.newFrequencyMappings = null;
    state.newFrequenciesToMap = [];
    state.currentConfiguration = null;
    state.currentJobId = null;
    state.currentFilename = null;
    state.filename = null;
    state.hasExecuteMergePlan = false;
    state.completedSteps = [];
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
  namespaced: true,
};
