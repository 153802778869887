import ApiService from '@/common/api.service';

const AuthService = {
  login(payload) {
    const resource = 'token/';
    // eslint-disable-next-line no-param-reassign
    payload.username = payload.email;
    return ApiService.post(resource, payload);
  },
  firstLogin(payload) {
    const resource = 'token/first-login';
    return ApiService.post(resource, payload);
  },
  refreshTokens() {
    const resource = 'token/refresh/';
    return ApiService.post(resource);
  },
  checkAuth() {
    const resource = 'token/me/';
    return ApiService.get(resource);
  },
  logout(payload) {
    const resource = 'token/refresh/delete/';
    return ApiService.post(resource, payload);
  },
};

export default AuthService;
