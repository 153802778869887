import { toShort } from '@/components/table/helpers/filterTypes';

export default (link, resource, params) => {
  if (params) {
    let joinSymbol = link ? '&' : '?';
    let queryString = '';

    params.forEach((param) => {
      queryString += `${joinSymbol}${param}`;
      joinSymbol = '&';
    });

    // eslint-disable-next-line no-param-reassign
    resource += queryString;
  }

  return resource;
};

// eslint-disable-next-line arrow-body-style
const buildPlannedDisposalFilter = (value) => {
  return {
    MONDAY: 'dispose_monday=True',
    TUESDAY: 'dispose_tuesday=True',
    WEDNESDAY: 'dispose_wednesday=True',
    THURSDAY: 'dispose_thursday=True',
    FRIDAY: 'dispose_friday=True',
    SATURDAY: 'dispose_saturday=True',
    SUNDAY: 'dispose_sunday=True',
    UNKNOWN: 'dispose_unknown=True',
  }[value];
};

export const buildFilterParam = ({ value, type, column }) => {
  const extractedValue = typeof value === 'string' ? value : value.innerValue;

  if (column.field === 'planned_disposal_days') {
    return buildPlannedDisposalFilter(extractedValue);
  }

  // field_filtertype_=value
  return `${column.field}_${toShort(type)}=${extractedValue}`;
};
