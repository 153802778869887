/* eslint-disable no-param-reassign */
/* eslint-disable camelcase */
/* eslint-disable object-curly-newline */
/* eslint-disable no-shadow */
import VehicleService from '@/services/vehicles.service';

import * as a from '../actions.type';
import * as m from '../mutations.type';

const state = {
  vehicles: [],
};

const getters = {
  vehicles() {
    return state.vehicles;
  },
  vehicleOptions() {
    // ensure the unknown vehicle is displayed last in any list
    const copiedVehicleList = [...state.vehicles];
    copiedVehicleList.sort((a, b) => {
      if (a.name === 'Ukendt') {
        return 1;
      }

      return a.name.localeCompare(b.name);
    });
    return copiedVehicleList.map((v) => ({
      value: v.id,
      label: v.name,
    }));
  },
};

const actions = {
  [a.ALL_VEHICLES](context) {
    context.commit(m.SET_VEHICLES, []);
    return new Promise((resolve, reject) => {
      VehicleService.getAll()
        .then(({ data }) => {
          context.commit(m.SET_VEHICLES, data);
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [a.CREATE_VEHICLE](_context, vehicle) {
    return new Promise((resolve, reject) => {
      VehicleService.create(vehicle)
        .then(() => {
          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [a.EDIT_VEHICLE](_context, payload) {
    return new Promise((resolve, reject) => {
      VehicleService.update(payload)
        .then(() => {
          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [a.DELETE_VEHICLE](_context, id) {
    return new Promise((resolve, reject) => {
      VehicleService.delete(id)
        .then(() => {
          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};

const mutations = {
  [m.SET_VEHICLES](state, data) {
    state.vehicles = data;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
  namespaced: true,
};
