<template>
  <div class="z-max">
    <base-modal
      v-model="model"
      :width="680"
      :is-full-screen="false"
      :close-on-click-outside="true"
      title="Genvejstaster"
    >
      <div>
        <p
          class="text-gray-800 border-b pb-4"
        >Følgende genvejstaster kan bruges til hurtigere navigation</p>
        <div class="space-y-4 mt-4">
          <div class="list-container">
            <div class="w-16 list-icon">Alt + K</div>
            <div class="list-description">Åbn Kort</div>
          </div>

          <div class="list-container">
            <div class="w-16 list-icon">Alt + P</div>
            <div class="list-description">Åbn Opgaver</div>
          </div>

          <div v-if="!isLightWorkspace" class="list-container">
            <div class="w-16 list-icon">Alt + O</div>
            <div class="list-description">Åbn Optælling</div>
          </div>

          <div class="list-container">
            <div class="w-16 list-icon">Esc</div>
            <div class="list-description">Luk popup-dialoger (som denne)</div>
          </div>
        </div>
      </div>
    </base-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'TheHotkeyModal',
  props: {
    value: {
      type: Boolean,
    },
  },
  computed: {
    ...mapGetters('departments', ['isLightWorkspace']),
    model: {
      get() {
        return this.value;
      },
      set(show) {
        this.$emit('input', show);
      },
    },
  },
};
</script>

<style>

.list-container {
  @apply grid grid-cols-4 items-center;
}

.list-icon {
  @apply h-8 px-1.5 bg-gray-300 rounded-md flex items-center justify-center col-span-1;
}

.list-description {
  @apply col-span-3 text-gray-800;
}

</style>
