import Vue from 'vue';

import * as Sentry from '@sentry/vue';
import { Integrations } from '@sentry/tracing';

import router from '@/router';
import store from '@/store/index';

import ApiService from './common/api.service';

import App from './App.vue';

import '@/assets/css/tailwind.css';

import '@/plugins/northarc';
import '@/plugins/vuelidate';

// FILTERS
import '@/filters/dates.filters';
import '@/filters/strings.filters';
import '@/filters/highlight.filters';

import '@/common/polyfill';

Vue.prototype.$unhandledError = (err, showNtf = true) => {
  if (showNtf) {
    Vue.prototype.$ntf.error('Der skete en uventet fejl');
  }

  if (err) {
    Sentry.captureException(err);
    console.error(err);
  }
};

// API
ApiService.init();

Vue.config.productionTip = process.env.NODE_ENV === 'production';

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    Vue,
    dsn: 'https://ddf2f67634644dc29a227c1f4a295bd2@o520654.ingest.sentry.io/5939636',
    integrations: [
      new Integrations.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracingOrigins: ['localhost', 'urbaser.northarc.dk', /^\//],
      }),
    ],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.2,
  });
}

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
