/* eslint-disable no-param-reassign */
/* eslint-disable camelcase */
/* eslint-disable object-curly-newline */
/* eslint-disable no-shadow */

import * as jobTypes from '@/util/jobTypes';
import DataGenerationService from '@/services/datageneration.service';
import * as a from '../actions.type';
import * as m from '../mutations.type';

const state = {
    administrativeAreas: [],
};

const getters = {
  administrativeAreaOptions() {
    return state.administrativeAreas.map((administrativeArea) => ({
      value: administrativeArea.code,
      label: administrativeArea.name,
    }));
  },
};

const actions = {
  [a.DOWNLOAD_ADMINISTRATIVE_AREAS]() {
    return new Promise((resolve, reject) => {
      DataGenerationService.downloadAddresses()
        .then(() => {
          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [a.GET_ADMINISTRATIVE_AREAS](context) {
    return new Promise((resolve, reject) => {
      DataGenerationService.getAdministrativeAreas()
        .then(({ data }) => {
          console.log('data setting', data);
          context.commit(m.SET_ADMINISTRATIVE_AREAS, data);
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [a.EXPORT_ADMINISTRATIVE_AREAS](context, payload) {
    const { administrativeAreaCode, withNorthArcColumns } = payload;

    return new Promise((resolve, reject) => {
      DataGenerationService.exportAdministrativeAreaAddresses(administrativeAreaCode, withNorthArcColumns)
        .then(({ data }) => {
          const jobTypePayload = {
            type: jobTypes.DATAGENERATIONADDRESSES,
            jobId: data,
          };
          context.commit(`jobs/${m.SET_JOB_ID_BY_TYPE}`, jobTypePayload, { root: true });
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};

const mutations = {
  [m.SET_ADMINISTRATIVE_AREAS](state, data) {
    state.administrativeAreas = data.administrative_areas;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
  namespaced: true,
};
