<template>
  <span @click="handleClick">
    <button
      ref="button"
      type="button"
      class="flex items-center justify-evenly text-center border border-transparent font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900 transition ease-in-out duration-150 w-full"
      :class="[sizeClass, ...buttonClasses]"
      :disabled="disabled"
      v-bind="$attrs"
    >
      <span>{{ text }}</span>
    </button>
  </span>
</template>

<script>
import { sizings, tailwindColors } from '@/util/validator.props';

const buttonSize = {
  micro: 'px-1.5 py-1 leading-3 text-xs',
  xs: 'px-2.5 py-1.5 leading-4 text-sm',
  sm: 'px-3 py-2 leading-4 text-sm',
  md: 'px-4 py-2 leading-5 text-sm',
  lg: 'px-4 py-2 leading-6 text-base',
  xl: 'px-6 py-3 leading-6 text-base',
};

/**
 * @displayName BaseButton
 */
export default {
  name: 'BaseFlatButton',
  inheritAttrs: false,
  props: {
    /**
     * Tailwind color to style the button
     * @values red | green | blue | teal | yellow | pink | purple | gray | indigo
     */
    color: {
      type: String,
      default: '',
      validator: tailwindColors,
    },
    /**
     * Button text to be displayed if no default slot is provided
     */
    text: {
      type: String,
      default: '',
    },
    /**
     * Size of the button
     * @values xs | sm | md | lg | xl
     */
    size: {
      type: String,
      default: 'md',
      validator: sizings,
    },
    /**
     * Whether the button is disabled
     */
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    textColor() {
      return {
        red: 'text-red-600',
        green: 'text-green-600',
        blue: 'text-blue-600',
        teal: 'text-teal-600',
        yellow: 'text-yellow-600',
        pink: 'text-pink-600',
        purple: 'text-purple-600',
        gray: 'text-gray-600',
        indigo: 'text-indigo-600 ',
        orange: 'text-orange-600',
      };
    },
    sizeClass() {
      return buttonSize[this.size];
    },
    buttonClasses() {
      if (this.disabled) {
        return 'border-gray-300 cursor-not-allowed text-gray-400';
      }

      if (!this.color) {
        return 'border-gray-300 bg-white text-gray-700 hover:text-gray-500 focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-50 active:text-gray-800';
      }

      return [this.textColor[this.color]];
    },
  },
  methods: {
    handleClick(evt) {
      /**
       * Fires when button is clicked
       *
       * @event click
       */
      if (!this.disabled) {
        this.$emit('click', evt);
      }
    },
  },
};
</script>
