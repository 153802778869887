/* eslint-disable no-unused-expressions */
/* eslint-disable no-mixed-operators */
/* eslint-disable no-param-reassign */
import Vue from 'vue';
import Loading from './Loading.vue';

const Mask = Vue.extend(Loading);

const loadingDirective = {};

const getSpinnerSize = (parentBounding) => {
  if (parentBounding.height < 70 || parentBounding.width < 70) {
    return 20;
  }

  if (parentBounding.height < 200 || parentBounding.width < 200) {
    return 30;
  }

  if (parentBounding.height < 300 || parentBounding.width < 300) {
    return 40;
  }
  return 50;
};

const insertDom = (parent, el) => {
  parent.classList.add('loading-directive');
  const parentSize = parent.getBoundingClientRect();
  const spinnerSize = getSpinnerSize(parentSize);

  parent.appendChild(el.mask);
  el.instance.width = spinnerSize;
  el.instance.height = spinnerSize;
};

// eslint-disable-next-line no-shadow
loadingDirective.install = (Vue) => {
  if (Vue.prototype.$isServer) return;
  const toggleLoading = (el, binding) => {
    if (binding.value) {
      Vue.nextTick(() => {
        insertDom(el, el, binding);
      });
      el.instance.visible = true;
    } else {
      el.instance.visible = false;
    }
  };

  Vue.directive('loading', {
    bind(el, binding) {
      const mask = new Mask({
        el: document.createElement('div'),
      });
      el.instance = mask;
      el.mask = mask.$el;

      if (binding.value) {
        toggleLoading(el, binding);
      }
    },

    update(el, binding) {
      el.instance.visible = binding.value;
      if (binding.oldValue !== binding.value) {
        toggleLoading(el, binding);
      }
    },

    unbind(el) {
      if (el.mask.parentNode) {
        el.mask.parentNode.classList.remove('loading-directive');
        el.mask.parentNode.removeChild(el.mask);
        toggleLoading(el, { value: false });
      }
      el.instance && el.instance.$destroy();
    },
  });
};

export default loadingDirective;
