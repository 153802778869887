<template>
  <div>
    <div v-show="items.length">
      <vue-good-table
        style-class="vgt-table striped custom-vgt-table"
        :columns="columns"
        :rows="rows"
        :group-options="{
    enabled: true
  }"
      ></vue-good-table>
    </div>

    <image-container
      v-show="!items.length"
      asset-name="empty"
      title="Ingen tømmegrupper"
      class="mt-24"
    >
      <base-button color="indigo" class="mt-12" @click.native="$emit('close')">
        <router-link :to="{name: 'disposal-categories'}">Opret din første tømmegruppe</router-link>
      </base-button>
    </image-container>
  </div>
</template>

<script>
import columns from '@/components/table/configs/disposalCategoryAggregation';

import 'vue-good-table/dist/vue-good-table.css';
import { VueGoodTable } from 'vue-good-table';

import groupBy from 'lodash.groupby';
import zipcelx from 'zipcelx';

import ImageContainer from '@/components/ImageContainer.vue';

export default {
  name: 'DisposalCategoryAggregation',
  components: {
    ImageContainer,
    VueGoodTable,
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      columns,
    };
  },
  computed: {
    rows() {
      const groups = [];

      const groupedWeeks = groupBy(this.items, (item) => item.week_no);

      // eslint-disable-next-line no-restricted-syntax
      for (const [key, value] of Object.entries(groupedWeeks)) {
        groups.push({
          mode: 'span',
          label: `Uge ${key}`,
          children: value.sort((a, b) => (a.week_no > b.week_no ? -1 : 1)),
        });
      }

      return groups;
    },
  },
  methods: {
    exportGrid(workspaceName) {
      this.$ntf.info('Eksporterer data');

      const excelHeaders = ['Uge', 'Køretøj', 'Tømmegruppe', 'Mandag', 'Tirsdag', 'Onsdag', 'Torsdag', 'Fredag', 'Lørdag', 'Søndag', 'Ukendt', 'Sum'];

      const excelHeadersMapped = excelHeaders.map((value) => ({
        value,
        type: 'string',
      }));

      const excelData = [];

      this.rows.forEach((row) => {
        excelData.push([]);

        row.children.forEach((child) => {
          excelData.push([
            {
              value: row.label, // uge
              type: 'string',
            },
            {
              value: child.vehicle,
              type: 'string',
            },
            {
              value: child.disposal_category,
              type: 'string',
            },
            {
              value: child.monday || 0,
              type: 'number',
            },
            {
              value: child.tuesday || 0,
              type: 'number',
            },
            {
              value: child.wednesday || 0,
              type: 'number',
            },
            {
              value: child.thursday || 0,
              type: 'number',
            },
            {
              value: child.friday || 0,
              type: 'number',
            },
            {
              value: child.saturday || 0,
              type: 'number',
            },
            {
              value: child.sunday || 0,
              type: 'number',
            },
            {
              value: child.unknown || 0,
              type: 'number',
            },
            {
              value: child.sum || 0,
              type: 'number',
            },
          ]);
        });
      });

      zipcelx({
        filename: `${workspaceName}_tømmegrupper`,
        sheet: {
          data: [excelHeadersMapped, ...excelData],
        },
      });
    },
  },
};
</script>
