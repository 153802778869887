<script>
/* eslint-disable no-unused-vars */
/* eslint-disable no-multi-assign */
/* eslint-disable no-mixed-operators */
import Popper from '@/util/vue-popper';
import { debounce } from 'throttle-debounce';
import { generateId } from '@/util/id';
import Vue from 'vue';

const on = (() => {
  if (document.addEventListener) {
    return (element, event, handler) => {
      if (element && event && handler) {
        element.addEventListener(event, handler, false);
      }
    };
  }
  return (element, event, handler) => {
    if (element && event && handler) {
      element.attachEvent(`on${event}`, handler);
    }
  };
})();

const off = (() => {
  if (document.removeEventListener) {
    return (element, event, handler) => {
      if (element && event) {
        element.removeEventListener(event, handler, false);
      }
    };
  }
  return (element, event, handler) => {
    if (element && event) {
      element.detachEvent(`on${event}`, handler);
    }
  };
})();

/**
 * @displayName Tooltip
 */
export default {
  name: 'Tooltip',

  mixins: [Popper],

  props: {
    /**
     * @ignored
     */
    openDelay: {
      type: Number,
      default: 0,
    },
    /**
     * Whether tooltip is disabled
     */
    disabled: Boolean,
    /**
     * @ignored
     */
    manual: Boolean,
    /**
     * @ignored
     */
    effect: {
      type: String,
      default: 'dark',
    },
    /**
     * @ignored
     */
    arrowOffset: {
      type: Number,
      default: 0,
    },
    /**
     * @ignored
     */
    popperClass: {
      type: String,
      default: '',
    },
    content: {
      type: String,
      default: '',
    },
    /**
     * Whether a small arrow should be displayed
     */
    visibleArrow: {
      type: Boolean,
      default: true,
    },
    /**
     * @ignored
     */
    transition: {
      type: String,
      default: 'el-fade-in-linear',
    },
    /**
     * @ignored
     */
    // eslint-disable-next-line vue/require-prop-types
    popperOptions: {
      default() {
        return {
          boundariesPadding: 10,
          gpuAcceleration: false,
        };
      },
    },
    /**
     * @ignored
     */
    enterable: {
      type: Boolean,
      default: true,
    },
    /**
     * @ignored
     */
    hideAfter: {
      type: Number,
      default: 0,
    },
    /**
     * @ignored
     */
    tabindex: {
      type: Number,
      default: 0,
    },
  },

  data() {
    return {
      tooltipId: `tooltip-${generateId()}`,
      timeoutPending: null,
      focusing: false,
    };
  },
  watch: {
    focusing(val) {
      if (val) {
        this.referenceElm.classList.add('focusing');
      } else {
        this.referenceElm.classList.remove('focusing');
      }
    },
  },
  beforeCreate() {
    if (this.$isServer) return;

    this.popperVM = new Vue({
      data: { node: '' },
      render(h) {
        return this.node;
      },
    }).$mount();

    this.debounceClose = debounce(200, () => this.handleClosePopper());
  },

  mounted() {
    this.referenceElm = this.$el;
    if (this.$el.nodeType === 1) {
      this.$el.setAttribute('aria-describedby', this.tooltipId);
      this.$el.setAttribute('tabindex', this.tabindex);
      on(this.referenceElm, 'mouseenter', this.show);
      on(this.referenceElm, 'mouseleave', this.hide);
      on(this.referenceElm, 'focus', () => {
        if (!this.$slots.default || !this.$slots.default.length) {
          this.handleFocus();
          return;
        }
        const instance = this.$slots.default[0].componentInstance;
        if (instance && instance.focus) {
          instance.focus();
        } else {
          this.handleFocus();
        }
      });
      on(this.referenceElm, 'blur', this.handleBlur);
      on(this.referenceElm, 'click', this.removeFocusing);
    }
    // fix issue https://github.com/ElemeFE/element/issues/14424
    if (this.value && this.popperVM) {
      this.popperVM.$nextTick(() => {
        if (this.value) {
          this.updatePopper();
        }
      });
    }
  },

  beforeDestroy() {
    // eslint-disable-next-line no-unused-expressions
    this.popperVM && this.popperVM.$destroy();
  },

  destroyed() {
    const reference = this.referenceElm;
    if (reference.nodeType === 1) {
      off(reference, 'mouseenter', this.show);
      off(reference, 'mouseleave', this.hide);
      off(reference, 'focus', this.handleFocus);
      off(reference, 'blur', this.handleBlur);
      off(reference, 'click', this.removeFocusing);
    }
  },
  methods: {
    show() {
      this.setExpectedState(true);
      this.handleShowPopper();
    },

    hide() {
      this.setExpectedState(false);
      this.debounceClose();
    },
    handleFocus() {
      this.focusing = true;
      this.show();
    },
    handleBlur() {
      this.focusing = false;
      // this.hide();
    },
    removeFocusing() {
      this.focusing = false;
    },

    addTooltipClass(prev) {
      if (!prev) {
        return 'tooltip';
      }
      return `tooltip ${prev.replace('tooltip', '')}`;
    },

    handleShowPopper() {
      if (!this.expectedState || this.manual) return;
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        this.showPopper = true;
      }, this.openDelay);

      if (this.hideAfter > 0) {
        this.timeoutPending = setTimeout(() => {
          this.showPopper = false;
        }, this.hideAfter);
      }
    },

    handleClosePopper() {
      if ((this.enterable && this.expectedState) || this.manual) return;
      clearTimeout(this.timeout);

      if (this.timeoutPending) {
        clearTimeout(this.timeoutPending);
      }
      this.showPopper = false;

      if (this.disabled) {
        this.doDestroy();
      }
    },

    setExpectedState(expectedState) {
      if (expectedState === false) {
        clearTimeout(this.timeoutPending);
      }
      this.expectedState = expectedState;
    },

    getFirstElement() {
      const slots = this.$slots.default;
      if (!Array.isArray(slots)) return null;
      let element = null;
      for (let index = 0; index < slots.length; index++) {
        if (slots[index] && slots[index].tag) {
          element = slots[index];
        }
      }
      return element;
    },
  },

  render(h) {
    if (this.popperVM) {
      const classNames = ['tooltip__popper', `is-${this.effect}`, this.popperClass].join(' ');
      this.popperVM.node = (
        <transition name={this.transition} onAfterLeave={this.doDestroy}>
          <div
            onMouseLeave={() => {
              this.setExpectedState(false);
              this.debounceClose();
            }}
            onMouseEnter={() => {
              this.setExpectedState(true);
            }}
            ref="popper"
            role="tooltip"
            id={this.tooltipId}
            aria-hidden={this.disabled || !this.showPopper ? 'true' : 'false'}
            v-show={!this.disabled && this.showPopper}
            class={classNames}
          >
            {this.$slots.content || this.content}
          </div>
        </transition>
      );
    }

    const firstElement = this.getFirstElement();
    if (!firstElement) return null;

    const data = (firstElement.data = firstElement.data || {});
    data.staticClass = this.addTooltipClass(data.staticClass);

    return firstElement;
  },
};
</script>

<style lang="css">
.tooltip:focus:hover,
.tooltip:focus:not(.focusing) {
  outline-width: 0;
}
.tooltip__popper {
  position: absolute;
  padding: 10px;
  z-index: 2000;
  font-size: 12px;
  line-height: 1.2;
  min-width: 10px;
  word-wrap: break-word;
  @apply rounded-md p-2.5;
}
.tooltip__popper .popper__arrow,
.tooltip__popper .popper__arrow:after {
  position: absolute;
  display: block;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
}
.tooltip__popper .popper__arrow {
  border-width: 6px;
}
.tooltip__popper .popper__arrow:after {
  content: " ";
  border-width: 5px;
}
.tooltip__popper[x-placement^="top"] {
  margin-bottom: 12px;
}
.tooltip__popper[x-placement^="top"] .popper__arrow {
  bottom: -6px;
  border-top-color: #4b5563;
  border-bottom-width: 0;
}
.tooltip__popper[x-placement^="top"] .popper__arrow:after {
  bottom: 1px;
  margin-left: -5px;
  border-top-color: #4b5563;
  border-bottom-width: 0;
}
.tooltip__popper[x-placement^="bottom"] {
  margin-top: 12px;
}
.tooltip__popper[x-placement^="bottom"] .popper__arrow {
  top: -6px;
  border-top-width: 0;
  border-bottom-color: #4b5563;
}
.tooltip__popper[x-placement^="bottom"] .popper__arrow:after {
  top: 1px;
  margin-left: -5px;
  border-top-width: 0;
  border-bottom-color: #4b5563;
}
.tooltip__popper[x-placement^="right"] {
  margin-left: 12px;
}
.tooltip__popper[x-placement^="right"] .popper__arrow {
  left: -6px;
  border-right-color: #4b5563;
  border-left-width: 0;
}
.tooltip__popper[x-placement^="right"] .popper__arrow:after {
  bottom: -5px;
  left: 1px;
  border-right-color: #4b5563;
  border-left-width: 0;
}
.tooltip__popper[x-placement^="left"] {
  margin-right: 12px;
}
.tooltip__popper[x-placement^="left"] .popper__arrow {
  right: -6px;
  border-right-width: 0;
  border-left-color: #4b5563;
}
.tooltip__popper[x-placement^="left"] .popper__arrow:after {
  right: 1px;
  bottom: -5px;
  margin-left: -5px;
  border-right-width: 0;
  border-left-color: #4b5563;
}
.tooltip__popper.is-dark {
  background: #4b5563;
  color: #fff;
}
</style>
