/* eslint-disable no-param-reassign */
/* eslint-disable camelcase */
/* eslint-disable object-curly-newline */
/* eslint-disable no-shadow */
import * as jobTypes from '@/util/jobTypes';

import JobService from '@/services/job.service';
import * as m from '../mutations.type';
import * as a from '../actions.type';

const state = {
  geoCodingJobId: null,
  zoneCodingJobId: null,
  buildExperimentJobId: null,
  deleteExperimentJobId: null,
  deleteWorkspaceJobId: null,
  copyWorkspaceJobId: null,
  buildExistingRoutesJobId: null,
  buildRoutesFromScratchJobId: null,
  buildRoutesFromScratchAutomaticJobId: null,
  importTasksJobId: null,
  importExtraTasksJobId: null,
  nonInitialImportJobId: null,
  executeMergePlanJobId: null,
  generateDrivingListsJobId: null,
  transitionPeriodWorkspaceId: null,
  generateChangeListJobId: null,
  generateExportExternalJobId: null,
  generateDurationsAndDistancesJobId: null,
  generateAddressesJobId: null,
};

const getters = {
  geoCodingJobId(state) {
    return state.geoCodingJobId;
  },
  zoneCodingJobId(state) {
    return state.zoneCodingJobId;
  },
  buildExperimentJobId(state) {
    return state.buildExperimentJobId;
  },
  deleteExperimentJobId(state) {
    return state.deleteExperimentJobId;
  },
  deleteWorkspaceJobId(state) {
    return state.deleteWorkspaceJobId;
  },
  copyWorkspaceJobId(state) {
    return state.copyWorkspaceJobId;
  },
  buildExistingRoutesJobId(state) {
    return state.buildExistingRoutesJobId;
  },
  buildRoutesFromScratchJobId(state) {
    return state.buildRoutesFromScratchJobId;
  },
  buildRoutesFromScratchAutomaticJobId(state) {
    return state.buildRoutesFromScratchAutomaticJobId;
  },
  importTasksJobId(state) {
    return state.importTasksJobId;
  },
  importExtraTasksJobId(state) {
    return state.importExtraTasksJobId;
  },
  nonInitialImportJobId(state) {
    return state.nonInitialImportJobId;
  },
  executeMergePlanJobId(state) {
    return state.executeMergePlanJobId;
  },
  generateDrivingListsJobId(state) {
    return state.generateDrivingListsJobId;
  },
  transitionPeriodWorkspaceId(state) {
    return state.transitionPeriodWorkspaceId;
  },
  generateChangeListJobId(state) {
    return state.generateChangeListJobId;
  },
  generateExternalExportJobId(state) {
    return state.generateExportExternalJobId;
  },
  generateDurationsAndDistancesJobId(state) {
    return state.generateDurationsAndDistancesJobId;
  },
  generateAddressesJobId(state) {
    return state.generateAddressesJobId;
  },

};

const actions = {
  [a.CHECK_RUNNING_JOBS](context) {
    return new Promise((resolve, reject) => {
      JobService.getRunning()
        .then(({ data }) => {
          context.commit(m.SET_RUNNING_JOBS, data);
          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};

const mutations = {
  [m.SET_JOB_ID_BY_TYPE](state, payload) {
    const { type, jobId } = payload;

    switch (type) {
      case jobTypes.GEOCODING:
        state.geoCodingJobId = jobId;
        break;
      case jobTypes.ZONECODING:
        state.zoneCodingJobId = jobId;
        break;
      case jobTypes.BUILD_EXPERIMENT:
        state.buildExperimentJobId = jobId;
        break;
      case jobTypes.DELETE_EXPERIMENT:
        state.deleteExperimentJobId = jobId;
        break;
      case jobTypes.DELETE_WORKSPACE:
        state.deleteWorkspaceJobId = jobId;
        break;
      case jobTypes.COPY_WORKSPACE:
        state.copyWorkspaceJobId = jobId;
        break;
      case jobTypes.BUILD_EXISTING_ROUTES:
        state.buildExistingRoutesJobId = jobId;
        break;
      case jobTypes.BUILD_ROUTES_FROM_SCRATCH:
        state.buildRoutesFromScratchJobId = jobId;
        break;
      case jobTypes.IMPORT_TASKS:
        state.importTasksJobId = jobId;
        break;
      case jobTypes.IMPORT_EXTRA_TASKS:
        state.importExtraTasksJobId = jobId;
        break;
      case jobTypes.NONINITIALIMPORT:
        state.nonInitialImportJobId = jobId;
        break;
      case jobTypes.EXECUTEMERGEPLAN:
        state.executeMergePlanJobId = jobId;
        break;
      case jobTypes.GENERATEDRIVINGLISTS:
        state.generateDrivingListsJobId = jobId;
        break;
      case jobTypes.TRANSITIONPERIODWORKSPACE:
        state.transitionPeriodWorkspaceId = jobId;
        break;
      case jobTypes.GENERATECHANGELIST:
        state.generateChangeListJobId = jobId;
        break;
      case jobTypes.EXPORT_EXTERNAL:
        state.generateExportExternalJobId = jobId;
        break;
      case jobTypes.BUILD_ROUTES_FROM_SCRATCH_AUTOMATIC:
        state.buildRoutesFromScratchAutomaticJobId = jobId;
        break;
      case jobTypes.UPDATEDURATIONSANDDISTANCES:
        state.generateDurationsAndDistancesJobId = jobId;
        break;
      case jobTypes.DATAGENERATIONADDRESSES:
        state.generateAddressesJobId = jobId;
        break;
      default:
        break;
    }
  },
  [m.SET_RUNNING_JOBS](state, runningJobs) {
    runningJobs.forEach((job) => {
      switch (job.type) {
        case jobTypes.GEOCODING:
          state.geoCodingJobId = job.jobId;
          break;
        case jobTypes.ZONECODING:
          state.zoneCodingJobId = job.jobId;
          break;
        case jobTypes.BUILD_EXPERIMENT:
          state.buildExperimentJobId = job.jobId;
          break;
        case jobTypes.DELETE_EXPERIMENT:
          state.deleteExperimentJobId = job.jobId;
          break;
        case jobTypes.DELETE_WORKSPACE:
          state.deleteWorkspaceJobId = job.jobId;
          break;
        case jobTypes.COPY_WORKSPACE:
          state.copyWorkspaceJobId = job.jobId;
          break;
        case jobTypes.BUILD_EXISTING_ROUTES:
          state.buildExistingRoutesJobId = job.jobId;
          break;
        case jobTypes.BUILD_ROUTES_FROM_SCRATCH:
          state.buildRoutesFromScratchJobId = job.jobId;
          break;
        case jobTypes.IMPORT_TASKS:
          state.importTasksJobId = job.jobId;
          break;
        case jobTypes.IMPORT_EXTRA_TASKS:
          state.importTasksJobId = job.jobId;
          break;
        case jobTypes.NONINITIALIMPORT:
          state.nonInitialImportJobId = job.jobId;
          break;
        case jobTypes.EXECUTEMERGEPLAN:
          state.executeMergePlanJobId = job.jobId;
          break;
        case jobTypes.GENERATEDRIVINGLISTS:
          state.generateDrivingListsJobId = job.jobId;
          break;
        case jobTypes.TRANSITIONPERIODWORKSPACE:
          state.transitionPeriodWorkspaceId = job.jobId;
          break;
        case jobTypes.GENERATECHANGELIST:
          state.generateChangeListJobId = job.jobId;
          break;
        case jobTypes.EXPORT_EXTERNAL:
          state.generateExportExternalJobId = job.jobId;
          break;
        case jobTypes.BUILD_ROUTES_FROM_SCRATCH_AUTOMATIC:
          state.buildRoutesFromScratchAutomaticJobId = job.jobId;
          break;
        case jobTypes.UPDATEDURATIONSANDDISTANCES:
          state.generateDurationsAndDistancesJobId = job.jobId;
          break;
        case jobTypes.DATAGENERATIONADDRESSES:
          state.generateAddressesJobId = job.jobId;
          break;
        default:
          break;
      }
    });
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
  namespaced: true,
};
