export const focusBackground100 = {
  red: 'focus:bg-red-100',
  green: 'focus:bg-green-100',
  blue: 'focus:bg-blue-100',
  teal: 'focus:bg-teal-100',
  yellow: 'focus:bg-yellow-100',
  pink: 'focus:bg-pink-100',
  purple: 'focus:bg-purple-100',
  gray: 'focus:bg-gray-100',
  indigo: 'focus:bg-indigo-100',
  orange: 'focus:bg-orange-100',
};

export const focusBorder300 = {
  red: 'focus:border-red-300',
  green: 'focus:border-green-300',
  blue: 'focus:border-blue-300',
  teal: 'focus:border-teal-300',
  yellow: 'focus:border-yellow-300',
  pink: 'focus:border-pink-300',
  purple: 'focus:border-purple-300',
  gray: 'focus:border-gray-300',
  indigo: 'focus:border-indigo-300',
  orange: 'focus:border-orange-300',
};

export const focusBorder700 = {
  red: 'focus:border-red-700',
  green: 'focus:border-green-700',
  blue: 'focus:border-blue-700',
  teal: 'focus:border-teal-700',
  yellow: 'focus:border-yellow-700',
  pink: 'focus:border-pink-700',
  purple: 'focus:border-purple-700',
  gray: 'focus:border-gray-700',
  indigo: 'focus:border-indigo-700',
  orange: 'focus:border-orange-700',
};

export const focusShadowOutline = {
  red: 'focus:shadow-outline-indigo',
  green: 'focus:shadow-outline-green',
  blue: 'focus:shadow-outline-blue',
  teal: 'focus:shadow-outline-teal',
  yellow: 'focus:shadow-outline-yellow',
  pink: 'focus:shadow-outline-pink',
  purple: 'focus:shadow-outline-purple',
  gray: 'focus:shadow-outline-gray',
  indigo: 'focus:shadow-outline-indigo',
  orange: 'focus:shadow-outline-orange',
};
