// vue-good-table
export default [
  {
    label: 'Uge',
    field: 'week_no',
    type: 'number',
  },
  {
    label: 'Mandag',
    field: 'monday',
    type: 'number',
  },
  {
    label: 'Tirsdag',
    field: 'tuesday',
    type: 'number',
  },
  {
    label: 'Onsdag',
    field: 'wednesday',
    type: 'number',
  },
  {
    label: 'Torsdag',
    field: 'thursday',
    type: 'number',
  },
  {
    label: 'Fredag',
    field: 'friday',
    type: 'number',
  },
  {
    label: 'Lørdag',
    field: 'saturday',
    type: 'number',
  },
  {
    label: 'Søndag',
    field: 'sunday',
    type: 'number',
  },
  {
    label: 'Ukendt',
    field: 'unknown',
    type: 'number',
  },
  {
    label: 'Sum',
    field: 'sum',
    type: 'number',
  },
];
