<template>
  <svg :class="classes" viewBox="0 0 20 20" fill="currentColor">
    <path
      fill-rule="evenodd"
      d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
      clip-rule="evenodd"
    />
  </svg>
</template>

<script>
/**
 * @displayName InformationCircleSolid
 */
export default {
  name: 'IconInformationCircleSolid',
  props: {
    /**
     * css classes
     */
    classes: {
      type: String,
      default: 'h-6 w-6 text-blue-500',
    },
  },
};
</script>
