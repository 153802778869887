/* eslint-disable no-param-reassign */
/* eslint-disable camelcase */
/* eslint-disable object-curly-newline */
/* eslint-disable no-shadow */
import RouteService from '@/services/route.service';
import pollJob from '@/util/pollJob';
import { SUCCEEDED } from '@/util/jobStatus';
import * as m from '../mutations.type';
import * as a from '../actions.type';

const initialState = {
  stops: [],
  sortedStops: [],
  selectedStops: [],
  route: {
    id: null,
    isOriginal: null,
    createdAt: null,
    label: '',
  },
  startLocation: null,
  disposalPoint: null,
  versions: [],
  totalDistance: null,
  totalDuration: null,
  loading: false,
  errors: [],
  isSaving: false,
  hasUnsavedChanges: false,
};

const state = { ...initialState };

const getters = {
  stops: (state) => state.stops,
  sortedStops: (state) => state.sortedStops,
  selectedStops: (state) => state.selectedStops,
  startLocation: (state) => state.startLocation,
  versions: (state) => state.versions,
  disposalPoint: (state) => state.disposalPoint,
  totalDistance: (state) => state.totalDistance,
  totalDuration: (state) => state.totalDuration,
  routesLoading: (state) => state.loading,
  isSaving: (state) => state.isSaving,
  errors: (state) => state.errors,
  hasUnsavedChanges: (state) => state.hasUnsavedChanges,
};

function removeDuplicates(array, key) {
  const seen = new Set();
  return array.filter((item) => {
    const keyValue = item[key];
    if (seen.has(keyValue)) {
      return false;
    }
    seen.add(keyValue);
    return true;
  });
}

const actions = {
  async [a.GET_ROUTE]({ commit }, payload) {
    commit(m.SET_ROUTE_LOADING, true);
    try {
      const { data } = await RouteService.getRoute(payload);
      commit(m.SET_ROUTE_DATA, data);
      return data;
    } catch (err) {
      commit(m.SET_ROUTE_ERROR, err);
      throw err;
    } finally {
      commit(m.SET_ROUTE_LOADING, false);
    }
  },
  async [a.SAVE_STOPS_TO_BACKEND]({ commit, state }, payload) {
     commit(m.SET_IS_SAVING, true);
     try {
     const { data: jobId } = await RouteService.updateStopsByGivenOrder({
        routeId: state.route.id,
        payload,
      });

      if (!jobId) {
        throw new Error('Failed to retrieve jobId');
      }

     // Call pollJob and wait for it to resolve
      const jobResult = await pollJob(jobId);

       if (jobResult.status === SUCCEEDED) {
          const { data } = await RouteService.getRouteById(state.route.id);
          commit(m.SET_ROUTE_DATA, data);
          commit(m.SET_UNSAVED_CHANGES, false);
        }
     } catch (err) {
            commit(m.SET_ROUTE_ERROR, err);
            throw err;
          } finally {
            commit(m.SET_IS_SAVING, false);
          }
},
  async [a.UPDATE_SORTED_STOPS]({ commit }, sortedStops) {
    const uniqueSortedStops = removeDuplicates(sortedStops, 'planned_instance_id');

    commit(m.SET_SORTED_STOPS, uniqueSortedStops);
    commit(m.SET_UNSAVED_CHANGES, true);
  },
  async [a.CREATE_VERSION]({ commit }, payload) {
    commit(m.SET_ROUTE_LOADING, true);
    try {
      const { data } = await RouteService.createVersion(payload);
      return data;
    } catch (err) {
      commit(m.SET_ROUTE_ERROR, err);
      throw err;
    } finally {
      commit(m.SET_ROUTE_LOADING, false);
    }
  },
  async [a.LOAD_VERSIONS]({ commit }, routeId) {
    try {
      const { data } = await RouteService.getVersions(routeId);
      commit(m.SET_VERSIONS, data.versions);
      return data;
    } catch (err) {
      commit(m.SET_ROUTE_ERROR, err);
      throw err;
    }
  },
  async [a.DELETE_VERSION]({ commit }, routeId) {
    try {
      await RouteService.deleteVersion(routeId);

      // Check if deleted route is the current route, and change to original if so
      if (routeId === state.route.id) {
        const originalRoute = state.versions.find((version) => version.isOriginal);
        if (originalRoute) {
          commit(m.SET_ROUTE, originalRoute);
        } else {
          console.warn('No original route found, resetting route.');
          commit(m.SET_ROUTE, null);
        }
      }

      // Update versions after delete (as we've just deleted one).
      const { data } = await RouteService.getVersions(state.route.id);
      commit(m.SET_VERSIONS, data.versions);
    } catch (err) {
      commit(m.SET_ROUTE_ERROR, err);
      throw err;
    }
  },
    async [a.CHANGE_VERSION]({ commit }, routeId) {
    try {
      const { data } = await RouteService.getRouteById(routeId);
      commit(m.SET_ROUTE_DATA, data);
    } catch (err) {
      commit(m.SET_ROUTE_ERROR, err);
      throw err;
    }
  },
  [a.UPDATE_SELECTED_STOPS]({ commit }, selectedStops) {
    const uniqueSelectedStops = removeDuplicates(selectedStops, 'planned_instance_id');
    commit(m.SET_SELECTED_STOPS, uniqueSelectedStops);
  },
};

const mutations = {
  [m.SET_ROUTE_DATA](state, { stops, start_location, disposal_point, route, total_distance_in_meter, total_duration_in_seconds }) {
    state.route = {
      id: route.route_id,
      isOriginal: route.is_original,
      createdAt: route.created_at,
      label: route.label,
    };

    state.stops = stops;
    state.sortedStops = stops.slice().sort((a, b) => a.order - b.order);

    state.startLocation = start_location;
    state.disposalPoint = disposal_point;
    state.totalDistance = total_distance_in_meter;
    state.totalDuration = total_duration_in_seconds;
    state.hasUnsavedChanges = false;
  },
  [m.SET_ROUTE](state, route) {
    // If route.id is set we are working with route as an object from Vue/Javascript,
    //  and thus doesn't have to map it
    if (route?.id) {
      state.route = route;
    } else {
      state.route = {
      id: route.route_id,
      isOriginal: route.is_original,
      createdAt: route.created_at,
      label: route.label,
    };
    }
    state.hasUnsavedChanges = false;
  },
  [m.SET_SORTED_STOPS](state, sortedStops) {
    state.sortedStops = sortedStops;
  },
  [m.SET_UNSAVED_CHANGES](state, hasUnsavedChanges) {
    state.hasUnsavedChanges = hasUnsavedChanges;
  },
  [m.SET_SELECTED_STOPS](state, selectedStops) {
    state.selectedStops = selectedStops;
  },
  [m.SET_ROUTE_LOADING](state, isLoading) {
    state.loading = isLoading;
  },
  [m.SET_VERSIONS](state, versions) {
    state.versions = versions.map((route) => ({
      id: route.route_id,
      isOriginal: route.is_original,
      createdAt: route.created_at,
      label: route.label,
    }));
  },
  [m.SET_ROUTE_ERROR](state, error) {
    state.errors.push(error);
  },
  [m.SET_IS_SAVING](state, isSaving) {
    state.isSaving = isSaving;
  },
  [m.RESET_STATE](state) {
    Object.assign(state, { ...initialState });
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
  namespaced: true,
};
