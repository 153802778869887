/* eslint-disable no-param-reassign */
/* eslint-disable camelcase */
/* eslint-disable object-curly-newline */
/* eslint-disable no-shadow */
import DepartmentService from '@/services/departments.service';

import * as jobTypes from '@/util/jobTypes';
import * as a from '../actions.type';
import * as m from '../mutations.type';

import { getCookie, setCookie } from '../../util/cookies';

const state = {
    groups: [],
    lastFetchTimestamps: {},
    workspaces: [],
    groupedWorkspaces: [],
    activeWorkspaceId: null,
    workspaceShareToken: null,
};

const getters = {
    workspaces() {
        return state.workspaces;
    },
    workspaceShareToken() {
        return state.workspaceShareToken;
    },
    groupOptions: (state) => {
        const buildOptionPath = (group, path = '') => {
            if (!group || typeof group !== 'object' || group.name === 'Ingen gruppe') return [];

            const currentPath = path ? `${path}/${group.name}` : group.name;
            let options = [{ value: group.id, label: currentPath }];

            if (Array.isArray(group.groups) && group.groups.length > 0) {
                group.groups.forEach((subGroup) => {
                    options = options.concat(buildOptionPath(subGroup, currentPath));
                });
            }

            return options;
        };

        return Array.isArray(state.groups)
            ? state.groups.reduce((acc, group) => acc.concat(buildOptionPath(group)), [])
            : [];
    },
    isLightWorkspace(state, getters) {
        return getters.activeWorkspace.isLightWorkspace;
    },
    nestedGroups: (state) => {
        const buildNestedStructure = (groups) => groups.map((group) => {
            const nestedGroup = { ...group };
            if (group.groups && group.groups.length > 0) {
                nestedGroup.groups = buildNestedStructure(group.groups);
            } else {
                nestedGroup.groups = [];
            }
            return nestedGroup;
        });

        return buildNestedStructure(state.groups);
    },
    groupedWorkspaces: (state) => {
        const assignWorkspacesAndSubgroups = (group) => ({
            ...group,
            workspaces: state.workspaces.filter((ws) => ws.groupId === group.id),
            groups: (group.groups || []).map(assignWorkspacesAndSubgroups),
        });
        return state.groups.map(assignWorkspacesAndSubgroups);
    },
    activeWorkspaceId() {
        // eslint-disable-next-line no-unused-expressions
        state.activeWorkspaceId;
        return getCookie('DEPARTMENT');
    },
    activeWorkspace(state, getters) {
        const topLevelWorkspace = getters.workspaces.find((wc) => wc.id === getters.activeWorkspaceId || wc.experiments.some((exp) => exp.id === getters.activeWorkspaceId) || wc.transitionPeriods.some((exp) => exp.id === getters.activeWorkspaceId));
        const experiment = topLevelWorkspace?.experiments.find((exp) => exp.id === getters.activeWorkspaceId);

        const transitionPeriod = topLevelWorkspace?.transitionPeriods.find((exp) => exp.id === getters.activeWorkspaceId);

        return {
            id: topLevelWorkspace?.id,
            name: topLevelWorkspace?.name || 'Ukendt',
            isLightWorkspace: topLevelWorkspace?.isLightWorkspace || false,
            experiments: topLevelWorkspace?.experiments || [],
            activeExperimentName: experiment?.name || 'Main',
            activeExperimentId: experiment?.id || null,
            activeTransitionPeriodName: transitionPeriod?.name || 'Mellemtømninger',
            activeTransitionPeriodId: transitionPeriod?.id || null,
            transitionPeriods: topLevelWorkspace?.transitionPeriods,
        };
    },
    isInMainExperiment(state, getters) {
        return getters.activeWorkspace.id === getters.activeWorkspaceId;
    },
    isInTransitionPeriod(state, getters) {
        return getters.activeWorkspace.activeTransitionPeriodId === getters.activeWorkspaceId;
    },
    workspaceAddress(state, getters) {
        const current = state.workspaces.find((x) => x.id === getters.activeWorkspaceId);
        return current?.address;
    },
    getGroupById: (state) => (id) => {
        const findGroup = (groups) => {
            if (!Array.isArray(groups)) return null;

            // eslint-disable-next-line no-restricted-syntax
            for (const group of groups) {
                if (group && group.id === id) return group;
                if (group && Array.isArray(group.groups)) {
                    const found = findGroup(group.groups);
                    if (found) return found;
                }
            }
            return null;
        };
        return findGroup(state.groupedWorkspaces || []);
    },
    isCacheValid: (state) => (key) => {
        const staleTime = 5 * 60 * 1000; // Cache expires after 5 minutes
        const currentTime = new Date().getTime();
        const lastFetch = state.lastFetchTimestamps[key]; // Get the last fetch timestamp for the key

        // If there's no fetch timestamp for this key, the cache is not valid
        if (!lastFetch) return false;

        // Return whether the cache is still valid based on the timestamp
        return currentTime - lastFetch < staleTime;
    },
};

const actions = {
    [a.ALL_DEPARTMENTS](context) {
        return new Promise((resolve, reject) => {
            if (context.getters.isCacheValid('workspaces')) {
                resolve(context.state.departments);
            } else {
                DepartmentService.getAll()
                    .then(({ data }) => {
                        // Commit the departments to the store
                        context.commit(m.SET_DEPARTMENTS, data);
                        // Update the last fetch time for "workspaces"
                        context.commit(m.SET_LAST_FETCH_DEPARTMENT, { key: 'workspaces', timestamp: new Date().getTime() });
                        resolve(data);
                    })
                    .catch((err) => {
                        // In case of error, you could optionally clear departments or handle it as needed
                        context.commit(m.SET_DEPARTMENTS, []); // Optionally clear on error
                        reject(err);
                    });
            }
        });
    },
    [a.ALL_GROUPS](context) {
        return new Promise((resolve, reject) => {
            if (context.getters.isCacheValid('groups')) {
                resolve({
                    groups_and_workspaces: context.state.groups,
                    all_workspaces: context.state.departments,
                });
            } else {
                DepartmentService.getAllGroups()
                    .then(({ data }) => {
                        context.commit(m.SET_GROUPS, data.groups_and_workspaces || []);
                        if (data.all_workspaces) {
                            context.commit(m.SET_DEPARTMENTS, data.all_workspaces);
                        }
                        // Update the last fetch time for "groups"
                        context.commit(m.SET_LAST_FETCH_DEPARTMENT, { key: 'groups', timestamp: new Date().getTime() });
                        resolve(data);
                    })
                    .catch((err) => {
                        context.commit(m.SET_GROUPS, []); // Clear groups on error
                        reject(err);
                    });
            }
        });
    },
    [a.MOVE_GROUP]({ commit, dispatch }, { groupId, targetGroupId }) {
        return new Promise((resolve, reject) => {
            DepartmentService.moveGroup({ groupId, targetGroupId })
                .then(() => {
                    // Invalidate the groups cache
                    commit(m.CLEAR_CACHE_DEPARTMENT, 'groups');
                    // Fetch the updated groups
                    dispatch(a.ALL_GROUPS);
                    resolve();
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    [a.DELETE_GROUP]({ commit }, groupId) {
        return new Promise((resolve, reject) => {
            DepartmentService.deleteGroup(groupId)
                .then((response) => {
                    const { data } = response;
                    commit(m.SET_GROUPS, data.updated_groups);
                    resolve(data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },

    [a.CREATE_DEPARTMENT]({ commit, dispatch }, payload) {
        return new Promise((resolve, reject) => {
            DepartmentService.create(payload)
                .then(({ data }) => {
                    commit(m.CLEAR_CACHE_DEPARTMENT, 'groups');
                    commit(m.CLEAR_CACHE_DEPARTMENT, 'workspaces');
                    dispatch(a.ALL_DEPARTMENTS);
                    dispatch(a.ALL_GROUPS);
                    resolve(data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    [a.CREATE_GROUP]({ commit, dispatch }, payload) {
        return new Promise((resolve, reject) => {
            DepartmentService.createGroup(payload)
                .then(({ data }) => {
                    commit(m.CLEAR_CACHE_DEPARTMENT, 'groups');
                    dispatch(a.ALL_GROUPS);
                    resolve(data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    [a.RENAME_DEPARTMENT]({ commit, dispatch }, payload) {
        return new Promise((resolve, reject) => {
            DepartmentService.rename(payload)
                .then(() => {
                    commit(m.CLEAR_CACHE_DEPARTMENT, 'workspaces');
                    dispatch(a.ALL_DEPARTMENTS);
                    resolve();
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },

    [a.UPDATE_LAST_VISITED]({ commit }, payload) {
        return new Promise((resolve, reject) => {
            DepartmentService.updateLastVisited(payload)
                .then(() => {
                    commit(m.CLEAR_CACHE_DEPARTMENT, 'workspaces');
                    // dispatch(a.ALL_DEPARTMENTS);
                    resolve();
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },

    [a.RENAME_GROUP]({ commit, dispatch }, payload) {
        return new Promise((resolve, reject) => {
            DepartmentService.renameGroup(payload)
                .then(() => {
                    commit(m.CLEAR_CACHE_DEPARTMENT, 'groups');
                    dispatch(a.ALL_GROUPS);
                    resolve();
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    [a.ASSIGN_GROUP]({ commit, dispatch }, payload) {
        return new Promise((resolve, reject) => {
            DepartmentService.assignGroup(payload)
                .then(({ data }) => {
                    commit(m.CLEAR_CACHE_DEPARTMENT, 'workspaces');
                    commit(m.CLEAR_CACHE_DEPARTMENT, 'groups');
                    dispatch(a.ALL_DEPARTMENTS);
                    dispatch(a.ALL_GROUPS);
                    resolve(data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    [a.CREATE_EXPERIMENT]({ commit }, payload) {
        return new Promise((resolve, reject) => {
            DepartmentService.createExperiment(payload)
                .then(({ data }) => {
                    const jobTypePayload = {
                        type: jobTypes.BUILD_EXPERIMENT,
                        jobId: data,
                    };
                    commit(`jobs/${m.SET_JOB_ID_BY_TYPE}`, jobTypePayload, { root: true });
                    resolve(data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    [a.DELETE_DEPARTMENT]({ commit }, id) {
        return new Promise((resolve, reject) => {
            DepartmentService.delete(id)
                .then(({ data }) => {
                    const jobTypePayload = {
                        type: jobTypes.DELETE_WORKSPACE,
                        jobId: data,
                    };
                    commit(`jobs/${m.SET_JOB_ID_BY_TYPE}`, jobTypePayload, { root: true });
                    resolve(data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    [a.COPY_DEPARTMENT]({ commit }, payload) {
        return new Promise((resolve, reject) => {
            DepartmentService.copy(payload)
                .then(({ data }) => {
                    const jobTypePayload = {
                        type: jobTypes.COPY_WORKSPACE,
                        jobId: data,
                    };
                    commit(`jobs/${m.SET_JOB_ID_BY_TYPE}`, jobTypePayload, { root: true });
                    resolve(data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    [a.DELETE_EXPERIMENT]({ commit }, id) {
        return new Promise((resolve, reject) => {
            DepartmentService.deleteExperiment(id)
                .then(({ data }) => {
                    const jobTypePayload = {
                        type: jobTypes.DELETE_EXPERIMENT,
                        jobId: data,
                    };
                    commit(`jobs/${m.SET_JOB_ID_BY_TYPE}`, jobTypePayload, { root: true });
                    resolve(data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    [a.UPGRADE_EXPERIMENT_TO_MAIN]({ commit, dispatch }, payload) {
        return new Promise((resolve, reject) => {
            DepartmentService.upgradeCurrentExperimentToMain(payload)
                .then(({ data }) => {
                    commit(m.CLEAR_CACHE_DEPARTMENT, 'workspaces');
                    dispatch(a.ALL_DEPARTMENTS);
                    resolve(data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    [a.GET_SHARED_WORKSPACE_LINK]({ commit }) {
        return new Promise((resolve, reject) => {
            DepartmentService.getSharedWorkspaceLink()
                .then(({ data }) => {
                    commit(m.SET_SHARED_WORKSPACE_TOKEN, data);
                    resolve(data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    [a.CREATE_SHARED_WORKSPACE_LINK]({ commit }) {
        return new Promise((resolve, reject) => {
            DepartmentService.createdSharedSpaceLink()
                .then(({ data }) => {
                    commit(m.SET_SHARED_WORKSPACE_TOKEN, data);
                    resolve();
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    [a.DELETE_SHARED_WORKSPACE_LINK]({ commit }) {
        return new Promise((resolve, reject) => {
            DepartmentService.deleteSharedWorkspaceLink()
                .then(() => {
                    commit(m.SET_SHARED_WORKSPACE_TOKEN, null);
                    resolve();
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },

    [a.SHARE_DEPARTMENT]({ commit, dispatch }, { departmentId, userIds }) {
        return new Promise((resolve, reject) => {
            DepartmentService.shareDepartment({ departmentId, userIds })
                .then((response) => {
                    commit(m.CLEAR_CACHE_DEPARTMENT, 'workspaces');
                    dispatch(a.ALL_DEPARTMENTS);
                    resolve(response);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
};

const mutations = {
    [m.SET_DEPARTMENTS](state, data) {
        state.workspaces = data;
    },
    [m.SET_GROUPS](state, data) {
        state.groups = Array.isArray(data) ? data : [];
        state.groupedWorkspaces = Array.isArray(data) ? data : [];
    },
    [m.SET_DEPARTMENT](state, workspaceId) {
        state.activeWorkspaceId = workspaceId;
        setCookie('DEPARTMENT', workspaceId);
    },
    [m.SET_SHARED_WORKSPACE_TOKEN](state, token) {
        state.workspaceShareToken = token;
    },
    [m.SET_LAST_FETCH_DEPARTMENT](state, { key, timestamp }) {
        state.lastFetchTimestamps = {
            ...state.lastFetchTimestamps,
            [key]: timestamp,
        };
    },
    [m.CLEAR_CACHE_DEPARTMENT](state, key) {
        delete state.lastFetchTimestamps[key];
    },
};

export default {
    state,
    actions,
    mutations,
    getters,
    namespaced: true,
};
