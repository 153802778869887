<template>
  <div class="flex items-center">
    <span class="h-4 w-4 rounded-full flex items-center justify-center" :class="circle[color]">
      <span class="h-2 w-2 rounded-full" :class="inner[color]"></span>
    </span>
    <span class="ml-2 text-gray-800">
      <slot>{{ text }}</slot>
    </span>
  </div>
</template>

<script>
export default {
  name: 'BaseBadge',
  props: {
    text: {
      type: String,
      default: '',
    },
    color: {
      type: String,
      default: '',
      description: 'gray | red | orange | yellow | green | teal | blue | indigo | purple | pink',
    },
  },
  data() {
    return {
      circle: {
        gray: 'bg-gray-100',
        red: 'bg-red-100',
        orange: 'bg-orange-100',
        yellow: 'bg-yellow-100',
        green: 'bg-green-100',
        teal: 'bg-teal-100',
        blue: 'bg-blue-100',
        indigo: 'bg-indigo-100',
        purple: 'bg-purple-100',
        pink: 'bg-pink-100',
      },
      inner: {
        gray: 'bg-gray-400',
        red: 'bg-red-400',
        orange: 'bg-orange-400',
        yellow: 'bg-yellow-400',
        green: 'bg-green-400',
        teal: 'bg-teal-400',
        blue: 'bg-blue-400',
        indigo: 'bg-indigo-400',
        purple: 'bg-purple-400',
        pink: 'bg-pink-400',
      },
    };
  },
};
</script>
