  <template>
  <span
    :class="classes"
    role="checkbox"
    tabindex="0"
    aria-checked="false"
    class="relative inline-flex flex-shrink-0 h-6 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:shadow-outline"
    style="width: 2.75rem"
    @click="model = !value"
  >
    <span
      :class="[value ? 'translate-x-5' : 'translate-x-0']"
      aria-hidden="true"
      class="translate-x-0 relative inline-block h-5 w-5 rounded-full bg-white shadow transform transition ease-in-out duration-200"
    >
      <span
        :class="[
          value
            ? 'opacity-0 ease-out duration-100'
            : 'opacity-100 ease-in duration-200',
        ]"
        class="duration-200 absolute inset-0 h-full w-full flex items-center justify-center transition-opacity"
      >
        <svg class="h-3 w-3 text-gray-400" fill="none" viewBox="0 0 12 12">
          <path
            d="M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </span>

      <span
        :class="[
          value
            ? 'opacity-100 ease-in duration-200'
            : 'opacity-0 ease-out duration-100',
        ]"
        class="opacity-0 ease-out duration-100 absolute inset-0 h-full w-full flex items-center justify-center transition-opacity"
      >
        <svg class="h-3 w-3 text-indigo-600" fill="currentColor" viewBox="0 0 12 12">
          <path
            d="M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z"
          />
        </svg>
      </span>
    </span>
  </span>
</template>
<script>
export default {
  name: 'BaseSwitch',
  inheritAttrs: false,
  props: {
    value: {
      type: Boolean,
      default: false,
      description: 'Switch value',
    },
    disabled: {
      type: Boolean,
      default: false,
      description: 'Disabled value',
    },
  },
  computed: {
    model: {
      get() {
        return this.value;
      },
      set(value) {
        if (!this.disabled) {
          this.$emit('input', value);
        }
      },
    },
    classes() {
      if (this.disabled) {
        return ['cursor-not-allowed bg-gray-200 text-gray-300'];
      }

      const defaultClasses = ['cursor-pointer'];
      return this.value ? [...defaultClasses, 'bg-indigo-600'] : [...defaultClasses, 'bg-gray-200'];
    },
  },
};
</script>
