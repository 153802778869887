/* eslint-disable no-param-reassign */
/* eslint-disable camelcase */
/* eslint-disable object-curly-newline */
/* eslint-disable no-shadow */
import PlannedInstanceService from '@/services/plannedInstance.service';

import * as jobTypes from '@/util/jobTypes';
import * as a from '../actions.type';
import * as m from '../mutations.type';

const state = {
  partitionSummary: [],
  loading: false,
  distributionModalOpen: false,
};

const getters = {
  partitionSummary() {
    return state.partitionSummary;
  },
  plannedInstancesLoading() {
    return state.loading;
  },
  distributionModalOpen() {
    return state.distributionModalOpen;
  },
};

const actions = {
  [a.GET_FILTERED_INSTANCES](context, payload) {
    context.commit(m.SET_PLANNED_INSTANCE_LOADING, true);
    return new Promise((resolve, reject) => {
      PlannedInstanceService.getFiltered(payload)
        .then(({ data }) => {
          context.commit(m.SET_PARTITION_SUMMARY, data.partition_summary);
          resolve(data.planned_instances);
        })
        .catch((err) => {
          reject(err);
        })
        .finally(() => {
          context.commit(m.SET_PLANNED_INSTANCE_LOADING, false);
        });
    });
  },
  [a.BUILD_EXISTING_ROUTES]({ commit }) {
    return new Promise((resolve, reject) => {
      PlannedInstanceService.buildExistingRoutes()
        .then(({ data }) => {
          const jobTypePayload = {
            type: jobTypes.BUILD_EXISTING_ROUTES,
            jobId: data,
          };
          commit(`jobs/${m.SET_JOB_ID_BY_TYPE}`, jobTypePayload, { root: true });
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [a.BUILD_ROUTES_FROM_SCRATCH]({ commit }) {
    return new Promise((resolve, reject) => {
      PlannedInstanceService.buildRoutesFromScratch()
        .then(({ data }) => {
          const jobTypePayload = {
            type: jobTypes.BUILD_ROUTES_FROM_SCRATCH,
            jobId: data,
          };
          commit(`jobs/${m.SET_JOB_ID_BY_TYPE}`, jobTypePayload, { root: true });
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [a.BUILD_ROUTES_FROM_SCRATCH_AUTOMATIC]({ commit }) {
    return new Promise((resolve, reject) => {
      PlannedInstanceService.buildRoutesFromScratchAutomatic()
        .then(({ data }) => {
          const jobTypePayload = {
            type: jobTypes.BUILD_ROUTES_FROM_SCRATCH_AUTOMATIC,
            jobId: data,
          };
          commit(`jobs/${m.SET_JOB_ID_BY_TYPE}`, jobTypePayload, { root: true });
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};

const mutations = {
  [m.SET_PARTITION_SUMMARY](state, summary) {
    state.partitionSummary = summary;
  },
  [m.SET_PLANNED_INSTANCE_LOADING](state, isLoading) {
    state.loading = isLoading;
  },
  [m.SET_DISTRIBUTION_MODAL_OPEN](state, isOpen) {
    state.distributionModalOpen = isOpen;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
  namespaced: true,
};
