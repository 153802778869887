<template>
  <div
    v-click-outside="clickOnOutside"
    class="relative inline-block text-left"
    @click.stop="clickOpen = !clickOpen"
    @mouseenter="setHoverOpen"
    @mouseleave="setHoverClose"
  >
    <div class="cursor-pointer w-full">
      <slot name="trigger" :open="isOpen" />
    </div>

    <transition
      enter-active-class="transition ease-out duration-100"
      enter-class="transform opacity-0 scale-95"
      enter-to-class="transform opacity-100 scale-100"
      leave-active-class="transition ease-in duration-75"
      leave-class="transform opacity-100 scale-100"
      leave-to-class="transform opacity-0 scale-95"
    >
      <div
        v-if="isOpen"
        class="absolute mt-2 rounded-md shadow-lg"
        :class="[
          dropdownPositionClass,
          {'origin-top-right': placement !== 'bottom-start'},
          {'origin-top-left': placement === 'bottom-start'}
        ]"
        style="z-index: 999;"
      >
        <div class="rounded-md bg-white shadow-xs ring-1 ring-black ring-opacity-5">
          <div role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
            <div v-if="$slots.header" class="border-b border-gray-100">
              <slot name="header"></slot>
            </div>

            <div @click.stop="close">
              <slot name="menu"></slot>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import ClickOutside from 'vue-click-outside';

export default {
  name: 'BaseDropdown',
  directives: {
    ClickOutside,
  },
  props: {
    trigger: {
      type: String,
      default: 'click',
      validator: (value) => ['click', 'hover'].includes(value),
      description: 'click | hover | Way to trigger the dropdown to open',
    },
    placement: {
      type: String,
      default: 'bottom-end',
      validator: (value) => ['bottom-end', 'bottom-start', 'top-end', 'top-start'].includes(value),
      description: 'bottom-end | bottom-start | top-end | top-start',
    },
  },
  data() {
    return {
      clickOpen: false,
      hoverOpen: false,
      hoverDelay: false,
      forceOpened: false,
    };
  },
  computed: {
    isOpen() {
      if (this.trigger === 'click') {
        return this.clickOpen;
      }
      if (this.trigger === 'hover') {
        return this.hoverOpen;
      }
      return false;
    },
    dropdownPositionClass() {
      const positions = {
        'bottom-end': 'right-0 top-full',
        'bottom-start': 'left-0 top-full',
        'top-end': 'right-0 bottom-full mb-2',
        'top-start': 'left-0 bottom-full mb-2',
      };
      return positions[this.placement] || positions['bottom-end'];
    },
  },
  methods: {
    close() {
      this.$emit('closed');
      this.clickOpen = false;
      this.hoverOpen = false;
    },
    clickOnOutside() {
      if (this.isOpen && !this.forceOpened) {
        this.close();
      }
    },
    forceOpen() {
      this.forceOpened = true;
      this.clickOpen = !this.clickOpen;
      setTimeout(() => {
        this.forceOpened = false;
      }, 100);
    },
    setHoverOpen() {
      this.hoverOpen = true;
      this.hoverDelay = true;
    },
    setHoverClose() {
      this.hoverDelay = false;
      setTimeout(() => {
        if (!this.hoverDelay) {
          this.hoverOpen = false;
        }
      }, 100);
    },
  },
};
</script>

<style scoped>
.absolute {
  position: absolute;
}
</style>
