<template>
  <base-modal v-model="model" :close-on-click-outside="true" :is-full-screen="true">
    <div slot="title" class="flex items-start justify-between">
      <h3 class="leading-5 font-medium text-xl text-gray-800">Optælling</h3>

      <div class="flex items-center space-x-8">
        <focus-trap v-model="model" :initial-focus="() => $refs.initialFocus">
          <div class="flex flex-col items-center space-y-6">
            <div class="flex items-center">
              <button
                class="rounded-full px-2 py-1 bg-white font-medium uppercase text-gray-700 focus:outline-none focus:ring-2 border border-transparent hover:border-gray-300 focus:ring-offset-2 focus:ring-gray-900 transition ease-in-out duration-150"
                @click="onCountTypeChange(COUNTING_TYPE_ENUM.ONLY_SUMMER)"
              >
                <span
                  :class="{'text-indigo-600': countingType == COUNTING_TYPE_ENUM.ONLY_SUMMER}"
                >Kun sommer</span>
              </button>

              <div class="w-0.5 h-4 mx-2 bg-gray-400"></div>

              <button
                class="rounded-full px-2 py-1 bg-white font-medium uppercase text-gray-700 focus:outline-none focus:ring-2 border border-transparent hover:border-gray-300 focus:ring-offset-2 focus:ring-gray-900 transition ease-in-out duration-150"
                @click="onCountTypeChange(COUNTING_TYPE_ENUM.ONLY_ALL_YEAR)"
              >
                <span
                  :class="{'text-indigo-600': countingType == COUNTING_TYPE_ENUM.ONLY_ALL_YEAR}"
                >Kun helårs</span>
              </button>

              <div class="w-0.5 h-4 mx-2 bg-gray-400"></div>

              <button
                class="rounded-full px-2 py-1 bg-white font-medium uppercase text-gray-700 focus:outline-none focus:ring-2 border border-transparent hover:border-gray-300 focus:ring-offset-2 focus:ring-gray-900 transition ease-in-out duration-150"
                @click="onCountTypeChange(COUNTING_TYPE_ENUM.ALL)"
              >
                <span :class="{'text-indigo-600': countingType == COUNTING_TYPE_ENUM.ALL}">Begge</span>
              </button>

              <tooltip content="Bestem hvad, som optællingen skal inkludere">
                <icon-question-mark-circle class="text-gray-600 h-5 w-5 ml-2"></icon-question-mark-circle>
              </tooltip>
            </div>
            <span class="relative z-0 inline-flex shadow-sm rounded-md pt-1">
              <button
                ref="initialFocus"
                type="button"
                tabindex="1"
                class="-ml-px relative inline-flex items-center justify-center w-32 px-4 py-2 border bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
                :class="[aggregation === AGGREGATION_ENUM.VEHICLE ? 'border-indigo-500 ring-1 ring-indigo-500 z-10' : 'border-gray-300']"
                @click="onAggregationChange(AGGREGATION_ENUM.VEHICLE)"
              >Køretøjer</button>
              <button
                type="button"
                tabindex="2"
                class="-ml-px relative inline-flex items-center justify-center w-32 px-4 py-2 border bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
                :class="[aggregation === AGGREGATION_ENUM.ROUTE_GROUP ? 'border-indigo-500 ring-1 ring-indigo-500 z-10' : 'border-gray-300']"
                @click="onAggregationChange(AGGREGATION_ENUM.ROUTE_GROUP)"
              >Uger</button>
              <button
                type="button"
                tabindex="3"
                class="-ml-px relative inline-flex items-center justify-center w-32 px-4 py-2 border bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
                :class="[aggregation === AGGREGATION_ENUM.DISPOSAL_CATEGORY ? 'border-indigo-500 ring-1 ring-indigo-500 z-10' : 'border-gray-300']"
                @click="onAggregationChange(AGGREGATION_ENUM.DISPOSAL_CATEGORY)"
              >Tømmegruppe</button>
              <button
                type="button"
                tabindex="4"
                class="-ml-px relative inline-flex items-center justify-center w-32 px-4 py-2 border bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
                :class="[aggregation === AGGREGATION_ENUM.MATERIEL ? 'border-indigo-500 ring-1 ring-indigo-500 z-10' : 'border-gray-300']"
                @click="onAggregationChange(AGGREGATION_ENUM.MATERIEL)"
              >Materiel</button>
              <button
                type="button"
                tabindex="5"
                class="-ml-px relative inline-flex items-center justify-center w-32 px-4 py-2 border bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
                :class="[aggregation === AGGREGATION_ENUM.PROPERTY_ZONE ? 'border-indigo-500 ring-1 ring-indigo-500 z-10' : 'border-gray-300']"
                @click="onAggregationChange(AGGREGATION_ENUM.PROPERTY_ZONE)"
              >Ejendomsstatus</button>
            </span>
          </div>
        </focus-trap>
      </div>
      <base-button v-if="data.length" color="gray" class="mr-8" @click="exportGrid">Eksport</base-button>

      <!-- placeholder to keep button group centered -->
      <span v-else></span>
    </div>

    <div v-loading="loading">
      <image-container v-if="error" asset-name="error" title="Der skete en fejl"></image-container>

      <div v-else>
        <div class="py-4 px-6 flex items-center space-x-4" style="backgroundColor: #f3f4f5">
          <label class="text-gray-500">Filtrér køretøjer</label>
          <base-select-multi
            v-model="vehicles"
            class="w-80"
            :clearable="true"
            placeholder="Køretøj"
            :options="vehicleOptions"
            @input="filterGrid"
          ></base-select-multi>
        </div>

        <div style="height: calc(100vh - 148px - 180px)" class="overflow-y-auto slim-scrollbar">
          <vehicle-aggregation
            v-if="aggregation === AGGREGATION_ENUM.VEHICLE"
            ref="vehicleGrid"
            :items="filteredData"
          ></vehicle-aggregation>
          <route-group-aggregation
            v-if="aggregation === AGGREGATION_ENUM.ROUTE_GROUP"
            ref="routeGroupGrid"
            :items="filteredData"
          ></route-group-aggregation>
          <material-aggregation
            v-else-if="aggregation === AGGREGATION_ENUM.MATERIEL"
            ref="materielGrid"
            :items="filteredData"
          ></material-aggregation>
          <disposal-category-aggregation
            v-else-if="aggregation === AGGREGATION_ENUM.DISPOSAL_CATEGORY"
            ref="disposalCategoryGrid"
            :items="filteredData"
            @close="model = false"
          ></disposal-category-aggregation>
          <property-zone-aggregation
            v-else-if="aggregation === AGGREGATION_ENUM.PROPERTY_ZONE"
            ref="propertyZoneGrid"
            :items="filteredData"
          ></property-zone-aggregation>
        </div>
      </div>
    </div>
  </base-modal>
</template>

<script>
import { mapMutations, mapGetters, mapActions } from 'vuex';
import { SET_DISTRIBUTION_MODAL_OPEN } from '@/store/mutations.type';
import { ALL_VEHICLES } from '@/store/actions.type';
import ImageContainer from '@/components/ImageContainer.vue';

import AggregationService from '@/services/aggregation.service';

import VehicleAggregation from '@/components/aggregations/VehicleAggregation.vue';
import RouteGroupAggregation from '@/components/aggregations/RouteGroupAggregation.vue';
import PropertyZoneAggregation from '@/components/aggregations/PropertyZoneAggregation.vue';
import MaterialAggregation from '@/components/aggregations/MaterialAggregation.vue';
import DisposalCategoryAggregation from '@/components/aggregations/DisposalCategoryAggregation.vue';
import { FocusTrap } from 'focus-trap-vue';

import COUNTING_TYPE_ENUM from '@/util/countingTypes';
import AGGREGATION_ENUM from '@/util/aggregationTypes';
import SharedService from '@/services/shared.service';

export default {
  name: 'TheDistributionModal',
  components: {
    PropertyZoneAggregation,
    VehicleAggregation,
    RouteGroupAggregation,
    MaterialAggregation,
    DisposalCategoryAggregation,
    ImageContainer,
    FocusTrap,
  },
  props: {
    isSharedView: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      aggregation: AGGREGATION_ENUM.VEHICLE,
      countingType: COUNTING_TYPE_ENUM.ALL,
      AGGREGATION_ENUM,
      COUNTING_TYPE_ENUM,
      loading: false,
      error: false,
      vehicles: [],
      data: [],
    };
  },
  computed: {
    ...mapGetters('planning', ['distributionModalOpen']),
    ...mapGetters('departments', ['activeWorkspace', 'isLightWorkspace']),
    ...mapGetters('vehicles', ['vehicleOptions']),
    model: {
      get() {
        return this.distributionModalOpen;
      },
      set(show) {
        this.setModalState(show);
      },
    },
    filteredData() {
      if (!this.vehicles.length) {
        return this.data;
      }

      const vehicleNames = this.vehicleOptions.filter((x) => this.vehicles.includes(x.value)).map((x) => x.label);
      return this.data.filter((row) => vehicleNames.includes(row.vehicle));
    },
  },
  watch: {
    distributionModalOpen: {
      handler: 'onVisibilityChange',
    },
  },
  async mounted() {
    document.addEventListener('keydown', this.toggleOnHotkey);

    this.$once('hook:beforeDestroy', () => {
      document.removeEventListener('keydown', this.toggleOnHotkey);
    });

    if (!this.vehicleOptions.length) {
      if (this.isSharedView) {
        await SharedService.getAllVehicles();
      } else {
        await this.getAllVehicles();
      }
    }
  },
  methods: {
    ...mapMutations('planning', {
      setModalState: SET_DISTRIBUTION_MODAL_OPEN,
    }),
    ...mapActions('vehicles', {
      getAllVehicles: ALL_VEHICLES,
    }),
    filterGrid() {
      // const grid = this.getCurrentGrid();
      // const vehicleNames = this.vehicleOptions.filter((x) => this.vehicles.includes(x.value)).map((x) => x.label);
      // grid.filter(vehicleNames);
    },
    goToRoute(route) {
      this.$router.push({ name: route });
      this.setModalState(false);
    },
    exportGrid() {
      const grid = this.getCurrentGrid();
      grid.exportGrid(this.activeWorkspace.name);
    },
    toggleOnHotkey(event) {
      if (event.altKey && event.key === 'o' && !this.isLightWorkspace) {
        this.setModalState(true);
      }
    },
    async onVisibilityChange(isVisible) {
      if (isVisible) {
        await this.loadAggregations();
      } else {
        this.aggregation = AGGREGATION_ENUM.VEHICLE;
      }
    },
    getCurrentGrid() {
      switch (this.aggregation) {
        case AGGREGATION_ENUM.VEHICLE:
          return this.$refs.vehicleGrid;
        case AGGREGATION_ENUM.PROPERTY_ZONE:
          return this.$refs.propertyZoneGrid;
        case AGGREGATION_ENUM.ROUTE_GROUP:
          return this.$refs.routeGroupGrid;
        case AGGREGATION_ENUM.DISPOSAL_CATEGORY:
          return this.$refs.disposalCategoryGrid;
        case AGGREGATION_ENUM.MATERIEL:
          return this.$refs.materielGrid;
        default:
          return null;
      }
    },
    async onAggregationChange(value) {
      this.clearFilter();
      this.aggregation = value;
      await this.loadAggregations();
    },
    async onCountTypeChange(value) {
      this.clearFilter();
      this.countingType = value;
      await this.loadAggregations();
    },
    clearFilter() {
      this.vehicles = [];
      const grid = this.getCurrentGrid();
      if (grid) {
        this.filterGrid();
      }
    },
    async loadAggregations() {
      this.clearFilter();
      this.data = [];
      this.loading = true;
      this.error = false;
      try {
        if (this.isSharedView) {
          const { data } = await SharedService.aggregateBy(this.aggregation, this.countingType);
          this.data = data;
          return;
        }

        const { data } = await AggregationService.aggregateBy(this.aggregation, this.countingType);
        this.data = data;
      } catch (error) {
        this.error = true;

        if (error?.data?.error) {
          this.$ntf.error(error?.data?.error);
          return;
        }

        this.$unhandledError(error);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
