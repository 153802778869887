/* eslint-disable no-param-reassign */
/* eslint-disable camelcase */
/* eslint-disable object-curly-newline */
/* eslint-disable no-shadow */
import TransitionPeriodService from '@/services/transitionPeriods.service';

import * as jobTypes from '@/util/jobTypes';
import * as a from '../actions.type';
import * as m from '../mutations.type';

const state = {};

const getters = {};

const actions = {
  [a.GENERATE_TRANSITION_WORKSPACE]({ commit }, payload) {
    return new Promise((resolve, reject) => {
      TransitionPeriodService.createTransitionPeriodWorkspace(payload)
        .then(({ data }) => {
          const jobTypePayload = {
            type: jobTypes.TRANSITIONPERIODWORKSPACE,
            jobId: data,
          };
          commit(`jobs/${m.SET_JOB_ID_BY_TYPE}`, jobTypePayload, { root: true });
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [a.GET_TRANSITION_PERIOD_DETAILS](_, payload) {
    return new Promise((resolve, reject) => {
      TransitionPeriodService.getDetails(payload)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};

const mutations = {};

export default {
  state,
  actions,
  mutations,
  getters,
  namespaced: true,
};
