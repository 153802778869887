import ApiService from '@/common/api.service';

const DepartmentsService = {
  getAll() {
    const resource = 'workspaces/';
    return ApiService.get(resource);
  },

  getAllGroups() {
    const resource = 'workspaces/groups-and-workspaces/';
    return ApiService.get(resource);
  },

  assignGroup(payload) {
    const resource = `workspaces/${payload.id}/assign/`;
    return ApiService.put(resource, null, payload);
  },

  moveGroup({ groupId, targetGroupId, moveToRoot }) {
    const resource = `workspaces/groups/${groupId}/`;
    return ApiService.put(resource, 'move', { targetGroupId, moveToRoot });
  },

  deleteGroup(groupId) {
    const resource = `workspaces/groups/${groupId}/`;
    return ApiService.delete(resource);
  },

  create(payload) {
    const resource = 'workspaces/';
    return ApiService.post(resource, payload);
  },

  copy({ name }) {
    const resource = 'workspaces/copies/';
    return ApiService.post(resource, { name });
  },

  rename({ id, name }) {
    const resource = 'workspaces/';
    return ApiService.put(resource, id, { name });
  },

  updateLastVisited(id) {
    const resource = `workspaces/${id}/last-visited/`;
    return ApiService.post(resource);
  },

  renameGroup({ id, newName }) {
    // eslint-disable-next-line quotes
    const resource = `workspaces/groups/`;
    return ApiService.put(resource, id, { name: newName });
  },

  delete(id) {
    const resource = `workspaces/${id}/`;
    return ApiService.delete(resource);
  },

  deleteExperiment(id) {
    const resource = 'workspaces/experiments/';
    return ApiService.delete(resource, id);
  },

  createExperiment(payload) {
    const resource = 'workspaces/experiments/';
    return ApiService.post(resource, payload);
  },

  switchExperiment(experimentId) {
    const resource = `workspaces/experiments/${experimentId}/`;
    return ApiService.post(resource);
  },

  upgradeCurrentExperimentToMain(experimentId) {
    const resource = `workspaces/experiments/${experimentId}/upgrades/`;
    return ApiService.put(resource);
  },

  createdSharedSpaceLink() {
    const resource = 'workspaces/external-acceses/';
    return ApiService.post(resource);
  },

  deleteSharedWorkspaceLink() {
    const resource = 'workspaces/external-acceses/';
    return ApiService.deleteInCurrentWorkspace(resource);
  },

  getSharedWorkspaceLink() {
    const resource = 'workspaces/external-acceses/';
    return ApiService.get(resource);
  },

  createGroup(payload) {
    const resource = 'workspaces/groups/';
    return ApiService.post(resource, payload);
  },
  shareDepartment({ departmentId, userIds }) {
    const resource = 'workspaces/share/';
    return ApiService.post(resource, { departmentId, userIds });
  },
};

export default DepartmentsService;
