<template>
  <div class="custom-control custom-radio" :class="[inlineClass, { disabled: disabled }]">
    <input
      :id="cbId"
      v-model="radioButtonModel"
      class="custom-control-input"
      type="radio"
      autocomplete="none"
      :disabled="disabled"
      :name="name"
      v-bind="$attrs"
      :value="radioValue"
    />
    <label :for="cbId" class="custom-control-label">
      <slot></slot>
    </label>
  </div>
</template>
<script>
import { generateId } from '@/util/id';

export default {
  name: 'BaseRadio',
  inheritAttrs: false,
  props: {
    name: {
      type: String,
      default: '',
      description: 'Name of the radiogroup',
    },
    disabled: {
      type: Boolean,
      description: 'Whether radio is disabled',
    },
    value: {
      type: [String, Number],
      required: true,
      description: 'The currently selected value of all buttons in the radio-group. If it matches the radioValue it will be checked',
    },
    radioValue: {
      type: [String, Number],
      required: true,
      description: 'The value of this specific radio button',
    },
    inline: {
      type: Boolean,
      description: 'Whether radio is inline',
    },
  },
  data() {
    return {
      cbId: '',
    };
  },
  computed: {
    inlineClass() {
      if (this.inline) {
        return 'form-check-inline';
      }
      return '';
    },
    radioButtonModel: {
      get() {
        return this.value;
      },
      set() {
        this.$emit('change', this.radioValue);
      },
    },
  },
  mounted() {
    this.cbId = generateId();
  },
};
</script>
