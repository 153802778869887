/* eslint-disable no-param-reassign */
/* eslint-disable camelcase */
/* eslint-disable object-curly-newline */
/* eslint-disable no-shadow */
import StatusService from '@/services/status.service';

import * as a from '../actions.type';
import * as m from '../mutations.type';

const state = {
  hasAnyTasks: false,
  hasPlanned: false,
  hasMaterielWithoutAbbreviation: false,
  hasUnplannedTasks: false,
  hasNonGeocoded: false,
  hasNonZonecoded: false,
  hasNonFrequencyCoded: false,
};

const getters = {
  hasAnyTasks() {
    return state.hasAnyTasks;
  },
  hasPlanned() {
    return state.hasPlanned;
  },
  hasMaterielWithoutAbbreviation() {
    return state.hasMaterielWithoutAbbreviation;
  },
  hasUnplannedTasks() {
    return state.hasUnplannedTasks;
  },
  hasNonGeocoded() {
    return state.hasNonGeocoded;
  },
  hasNonZonecoded() {
    return state.hasNonZonecoded;
  },
  hasNonFrequencyCoded() {
    return state.hasNonFrequencyCoded;
  },
};

const actions = {
  async [a.CHECK_STATUS_ALL](context) {
    await context.dispatch(a.CHECK_TASK_STATUS);
    await context.dispatch(a.CHECK_GEOCODE_STATUS);
    await context.dispatch(a.CHECK_ZONECODE_STATUS);
    await context.dispatch(a.CHECK_FREQUENCY_CODED_STATUS);
    await context.dispatch(a.CHECK_PLANNED_STATUS);
    await context.dispatch(a.CHECK_MATERIEL_STATUS);
  },
  [a.CHECK_TASK_STATUS](context) {
    return new Promise((resolve, reject) => {
      StatusService.anyTasks()
        .then(({ data }) => {
          context.commit(m.SET_HAS_ANY_TASKS, data.has_records);
          resolve(data.has_records);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [a.CHECK_GEOCODE_STATUS]({ commit }) {
    return new Promise((resolve, reject) => {
      StatusService.getGeocodeStatus()
        .then(({ data }) => {
          const hasNonGeocoded = data.total > 0 && data.not_geocoded > 0;
          commit(m.SET_HAS_NON_GEOCODED, hasNonGeocoded);
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [a.CHECK_MATERIEL_STATUS]({ commit }) {
    return new Promise((resolve, reject) => {
      StatusService.getAnyWithoutAbbreviation()
        .then(({ data }) => {
          commit(m.SET_HAS_MATERIEL_WITHOUT_ABBREVIATION, data);
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [a.CHECK_ZONECODE_STATUS]({ commit }) {
    return new Promise((resolve, reject) => {
      StatusService.getZonecodeStatus()
        .then(({ data }) => {
          const hasNonZonecoded = data.total > 0 && data.not_zonecoded > 0;
          commit(m.SET_HAS_NON_ZONECODED, hasNonZonecoded);
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [a.CHECK_FREQUENCY_CODED_STATUS]({ commit }) {
    return new Promise((resolve, reject) => {
      StatusService.getFrequencyCodeStatus()
        .then(({ data }) => {
          const hasFrequencyCoded = data.total > 0 && data.not_frequency_coded > 0;
          commit(m.SET_HAS_NON_FREQUENCY_CODED, hasFrequencyCoded);
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [a.CHECK_PLANNED_STATUS]({ commit }) {
    return new Promise((resolve, reject) => {
      StatusService.getPlannedInstanceStatus()
        .then(({ data }) => {
          commit(m.SET_PLANNED_STATUS, data);
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};

const mutations = {
  [m.SET_HAS_ANY_TASKS](state, hasAnyTasks) {
    state.hasAnyTasks = hasAnyTasks;
  },
  [m.SET_HAS_NON_GEOCODED](state, hasNonGeocoded) {
    state.hasNonGeocoded = hasNonGeocoded;
  },
  [m.SET_HAS_NON_ZONECODED](state, hasNonZonecoded) {
    state.hasNonZonecoded = hasNonZonecoded;
  },
  [m.SET_HAS_NON_FREQUENCY_CODED](state, hasNonFrequencyCoded) {
    state.hasNonFrequencyCoded = hasNonFrequencyCoded;
  },
  [m.SET_HAS_MATERIEL_WITHOUT_ABBREVIATION](state, hasWithout) {
    state.hasMaterielWithoutAbbreviation = hasWithout;
  },
  [m.SET_PLANNED_STATUS](state, { anyPlannedInstances, anyUnplannedTasks }) {
    state.hasPlanned = anyPlannedInstances;
    state.hasUnplannedTasks = anyUnplannedTasks;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
  namespaced: true,
};
