<template>
  <div
    class="fixed right-0 top-0 items-end justify-center pointer-events-none sm:items-start sm:justify-end"
    :style="positionStyle"
  >
    <transition
      enter-active-class="transform ease-out duration-300 transition"
      enter-class="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
      enter-to-class="opacity-100 translate-y-0 sm:scale-100"
      leave-active-class="transition ease-in duration-100"
      leave-class="opacity-100"
      leave-to-class="opacity-0 "
    >
      <div
        v-if="visible"
        class="max-w-sm w-full shadow-lg rounded-lg pointer-events-auto bg-gray-200 border-gray-600"
      >
        <div class="rounded-lg shadow-xs overflow-hidden">
          <div class="p-4">
            <div class="flex">
              <div class="flex-shrink-0">
                <icon-check-circle-solid v-if="type === 'success'" classes="h-8 w-8 text-green-800"></icon-check-circle-solid>
                <icon-information-circle-solid
                  v-else-if="type === 'info'"
                  classes="h-8 w-8 text-blue-800"
                ></icon-information-circle-solid>
                <icon-x-circle-solid v-else-if="type === 'error'" classes="h-8 w-8 text-red-800"></icon-x-circle-solid>
                <icon-exclamation-circle-solid
                  v-else-if="type === 'warning'"
                  classes="h-8 w-8 text-yellow-800"
                ></icon-exclamation-circle-solid>
              </div>
              <div class="ml-3 w-0 flex-1 pt-0.5">
                <p
                  class="text-sm leading-5 font-medium text-gray-900"
                  :class="subtitle ? '' : 'mt-1'"
                >{{ message }}</p>
                <p v-if="subtitle" class="text-sm leading-5 text-gray-700">{{ subtitle }}</p>
              </div>
              <div v-if="showClose" class="ml-4 flex-shrink-0 flex">
                <button
                  class="inline-flex text-gray-900 focus:outline-none transition ease-in-out duration-150"
                  @click="close"
                >
                  <svg class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                    <path
                      fill-rule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script type="text/babel">
import { background400 } from '@/util/tailwind/backgrounds';
import { stateToColor } from '@/util/tailwind/util';

/**
 * @displayName Notify
 */
export default {
  data() {
    return {
      /**
       * @ignored
       */
      visible: false,
      /**
       * Message
       */
      message: '',
      /**
       * Duration in ms before fadeout
       */
      duration: 3000,
      /**
       * Type that translates into color scheme and icon
       * @values success | error | info | warning
       */
      type: 'info',
      /**
       * Text beneath title
       */
      subtitle: '',
      /**
       * onClose callback
       */
      onClose: null,
      /**
       * Whether to show X icon
       */
      showClose: true,
      /**
       * @ignored
       */
      closed: false,
      /**
       * @ignored
       */
      verticalOffset: 20,
      /**
       * @ignored
       */
      timer: null,
    };
  },
  computed: {
    positionStyle() {
      return {
        top: `${this.verticalOffset}px`,
        width: '400px',
        zIndex: 9999999,
      };
    },
    color() {
      return stateToColor(this.type);
    },
    backgroundColor() {
      return background400[this.color];
    },
  },
  watch: {
    closed(newVal) {
      if (newVal) {
        this.visible = false;
      }
    },
  },
  mounted() {
    this.startTimer();
    document.addEventListener('keydown', this.keydown);
  },
  beforeDestroy() {
    document.removeEventListener('keydown', this.keydown);
  },
  methods: {
    handleAfterLeave() {
      this.$destroy(true);
      this.$el.parentNode.removeChild(this.$el);
    },
    close() {
      this.closed = true;
      if (typeof this.onClose === 'function') {
        this.onClose(this);
      }

      setTimeout(() => {
        this.$destroy(true);
        if (this.$el.parentNode) {
          this.$el.parentNode.removeChild(this.$el);
        }
      }, 100);
    },
    clearTimer() {
      clearTimeout(this.timer);
    },
    startTimer() {
      if (this.duration > 0) {
        this.timer = setTimeout(() => {
          if (!this.closed) {
            this.close();
          }
        }, this.duration);
      }
    },
    keydown(e) {
      if (e.keyCode === 27) {
        // esc
        if (!this.closed) {
          this.close();
        }
      }
    },
  },
};
</script>
