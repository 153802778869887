import Vue from 'vue';

import Loading from '@/components/base/loading/plugin';

import Icons from '@/components/icons';
import Confirm from '@/components/base/confirm/main';
import Notify from '@/components/base/notify/main';

import BaseButton from '@/components/base/global/BaseButton.vue';
import BaseFlatButton from '@/components/base/global/BaseFlatButton.vue';
import BaseInput from '@/components/base/global/BaseInput.vue';
import BaseSelect from '@/components/base/global/BaseSelect.vue';
import BaseSelectMulti from '@/components/base/global/BaseSelectMulti.vue';
import BaseBadge from '@/components/base/global/BaseBadge.vue';
import BaseRadio from '@/components/base/global/BaseRadio.vue';
import BaseLoader from '@/components/base/global/BaseLoader.vue';
import BaseSwitch from '@/components/base/global/BaseSwitch.vue';
import BaseModal from '@/components/base/global/BaseModal.vue';
import BaseDropdown from '@/components/base/global/BaseDropdown.vue';
import BaseTooltip from '@/components/base/global/BaseTooltip.vue';
import BaseCheckbox from '@/components/base/global/BaseCheckbox.vue';
import BaseCard from '@/components/base/global/BaseCard.vue';
import BaseAlert from '@/components/base/global/BaseAlert.vue';

Vue.component(BaseButton.name, BaseButton);
Vue.component(BaseFlatButton.name, BaseFlatButton);
Vue.component(BaseInput.name, BaseInput);
Vue.component(BaseTooltip.name, BaseTooltip);
Vue.component(BaseCard.name, BaseCard);
Vue.component(BaseDropdown.name, BaseDropdown);
Vue.component(BaseModal.name, BaseModal);
Vue.component(BaseBadge.name, BaseBadge);
Vue.component(BaseSwitch.name, BaseSwitch);
Vue.component(BaseRadio.name, BaseRadio);
Vue.component(BaseSelect.name, BaseSelect);
Vue.component(BaseSelectMulti.name, BaseSelectMulti);
Vue.component(BaseCheckbox.name, BaseCheckbox);
Vue.component(BaseLoader.name, BaseLoader);
Vue.component(BaseAlert.name, BaseAlert);

Vue.use(Icons);
Vue.prototype.$confirm = Confirm;
Vue.prototype.$ntf = Notify;

Loading.install(Vue);
