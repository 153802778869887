<template>
  <div
    v-show="value"
    class="fixed z-50 inset-0 overflow-y-auto overflow-x-auto"
    aria-labelledby="modal-title"
    role="dialog"
    aria-modal="true"
    @click.self="onClose"
  >
    <div
      class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
    >
      <transition
        enter-active-class="transition-all ease-out duration-300"
        enter-class="opacity-0"
        enter-to-class="opacity-100"
        leave-active-class="transition-all ease-in duration-200"
        leave-class="opacity-100"
        leave-to-class="opacity-0"
      >
        <div
          v-show="value"
          class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
          aria-hidden="true"
        ></div>
      </transition>

      <!-- This element is to trick the browser into centering the modal contents. -->
      <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>

      <transition
        enter-active-class="ease-out duration-300"
        enter-class="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        enter-to-class="opacity-100 translate-y-0 sm:scale-100"
        leave-active-class="ease-in duration-200"
        leave-class="opacity-100 translate-y-0 sm:scale-100"
        leave-to-class="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
      >
        <div
          v-show="value"
          :style="modalSizeStyles"
          class="inline-block align-bottom bg-white rounded-lg rounded-b-lg text-left shadow-xl transform transition-all sm:my-8 sm:align-middle sm:w-full"
        >
          <slot name="full">
            <div class="bg-white p-4 sm:px-6 rounded-md px-4 pt-5 pb-4 sm:p-6">
              <!-- @slot use to substitute title -->
              <slot name="title">
                <h3 class="leading-5 font-medium text-xl text-gray-800">{{ title }}</h3>
              </slot>
              <div v-if="showClose" class="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                <button
                  type="button"
                  class="text-gray-400 hover:text-gray-500 focus:outline-none focus:text-gray-500 transition ease-in-out duration-150"
                  aria-label="Close"
                  @click.prevent="onClose"
                >
                  <svg class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              </div>
            </div>

            <div class="bg-white" :class="bodyClasses || 'px-4 pb-4 sm:px-6 sm:pb-6'">
              <!-- @slot use to control modal body content -->
              <slot />
            </div>

            <div
              v-if="$slots.footer"
              class="bg-gray-50 rounded-b-md px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse"
            >
              <!-- @slot use to substitute footer -->
              <slot name="footer" />
            </div>
          </slot>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BaseModal',
  props: {
    /**
     * Controls visibility
     * @model
     */
    value: {
      required: true,
      type: Boolean,
    },
    /**
     * Whether the modal will close on a click on the backdrop
     */
    closeOnClickOutside: {
      type: Boolean,
      default: false,
    },
    /**
     * Whether to show an X icon in the top right corner of the modal
     */
    showClose: {
      type: Boolean,
      default: true,
    },
    /**
     * Replace default css classes on the body modal
     */
    bodyClasses: {
      type: String,
      default: '',
    },
    /**
     * Title of modal
     */
    title: {
      type: String,
      default: '',
    },
    /**
     * Control width of modal
     */
    width: {
      type: Number,
      default: 512,
    },
    isFullScreen: {
      type: Boolean,
      default: false,
      description: 'Whether the modal is fullscreen and the height/width should be scaled to the screen',
    },
    canOverflow: {
      type: Boolean,
      default: false,
      description: 'Whether the modal can overflow. Useful for dawa input dropdown for example',
    },
  },
  computed: {
    modalSizeStyles() {
      const styles = {};
      if (this.isFullScreen) {
        styles.width = 'calc(100vw - 100px)';
        styles.height = 'calc(100vh - 100px)';
      } else {
        styles.width = `${this.width}px`;
      }

      if (this.canOverflow) {
        styles.overflowY = 'visible';
      } else {
        styles.overflow = 'hidden';
      }

      return styles;
    },
  },
  mounted() {
    if (this.showClose) {
      document.addEventListener('keydown', this.hideOnEsc);

      this.$once('hook:beforeDestroy', () => {
        document.removeEventListener('keydown', this.hideOnEsc);
      });
    }
  },
  methods: {
    onClose() {
      this.$emit('closed');
      this.$emit('input', false);
    },
    clickOutside() {
      if (this.closeOnClickOutside) {
        this.onClose();
      }
    },
    hideOnEsc(e) {
      if (e.keyCode === 27 && this.value) {
        this.onClose();
      }
    },
  },
};
</script>
