<template>
  <svg
    :class="classes"
    fill="none"
    stroke="currentColor"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="2"
      d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
    ></path>
  </svg>
</template>

<script>
export default {
  name: 'IconCalendar',
  data() {
    return {
      classes: '',
    };
  },
  mounted() {
    this.classes = this.$attrs.class || 'w-4 h-4 text-gray-400';
  },
};
</script>
