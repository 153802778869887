<template>
  <div
    class="bg-gray-800 text-white relative nav-link flex-center flex-shrink-0 h-16 leading-5 cursor-pointer"
  >
    <div class="flex items-center flex-shrink-0">
      <router-link :to="{ name: 'tasks' }" class="brand" tabindex="-1">
        <span class="font-semibold text-xl">NorthArc</span>
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TheSidebarBrand',
};
</script>
