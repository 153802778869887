import { mapActions } from 'vuex';
import { BLACKLIST } from '@/store/actions.type';

const logoutMixin = {
  methods: {
    logout() {
      this.blackListTokens();
      this.$ntf.success('Tak for nu!');
    },
    ...mapActions('auth', {
      blackListTokens: BLACKLIST,
    }),
  },
};

export default logoutMixin;
