<template>
  <div class="z-max">
    <base-modal
      v-model="model"
      :width="680"
      :is-full-screen="false"
      :close-on-click-outside="true"
      title="Brugerpanel"
    >
      <div>
        <p class="text-gray-800 border-b pb-4">
          Følgende er en oversigt over brugere af dit system
        </p>
        <div class="space-y-4 mt-4">
          <div class="max-h-60 overflow-y-auto">
            <div
              v-for="user in users"
              :key="user.id"
              class="mb-2 flex items-center p-2 hover:bg-gray-50 rounded-md"
            >
              <div class="flex items-center space-x-2">
                <div class="h-8 w-8 rounded-full bg-gray-200 flex items-center justify-center">
                  <span class="text-sm text-gray-600">{{ user.full_name.charAt(0) }}</span>
                </div>
                <div class="flex flex-col">
                  <span class="text-sm font-medium text-gray-900">{{ user.full_name }}</span>
                  <span class="text-sm text-gray-500">{{ user.email }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </base-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { FETCH_USERS } from '@/store/actions.type';

export default {
  name: 'UserPanelModal',
  props: {
    value: {
      type: Boolean,
    },
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    ...mapGetters('auth', ['currentUser']),
    ...mapGetters('users', ['users']),
    model: {
      get() {
        return this.value;
      },
      set(show) {
        this.$emit('input', show);
        if (show) {
          this.loadUsers();
        }
      },
    },
    filteredUsers() {
      return this.users?.filter((user) => user.id !== this.currentUser?.id) || [];
    },
  },
  watch: {
    model(newValue) {
      if (newValue) {
        this.loadInitialState();
      }
    },
  },
    methods: {
    ...mapActions('users', { fetchUsers: FETCH_USERS }),
    async loadInitialState() {
      console.log('Loading initial state...');
      this.loading = true;
      try {
        await this.fetchUsers();
        console.log('Users loaded:', this.users);
      } catch (error) {
        console.error('Error loading users:', error);
        this.$ntf.error('Kunne ikke hente brugere');
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped>
.z-max {
  z-index: 9999;
}
</style>
