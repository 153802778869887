/* eslint-disable no-param-reassign */
/* eslint-disable camelcase */
/* eslint-disable object-curly-newline */
/* eslint-disable no-shadow */
import TaskService from '@/services/tasks.service';

import { GEOCODING, ZONECODING } from '@/util/jobTypes';
import * as jobTypes from '@/util/jobTypes';
import * as a from '../actions.type';
import * as m from '../mutations.type';

const state = {};

const getters = {};

const actions = {
  [a.GET_TASKS_NOT_GEOCODED]() {
    return new Promise((resolve, reject) => {
      TaskService.getTasksNotGeocoded()
        .then(({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [a.SUBMIT_MANUALLY_GEOCODED_TASKS](context, payload) {
    return new Promise((resolve, reject) => {
      TaskService.gecodeTasksManually(payload)
        .then(() => {
          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [a.SUBMIT_FREQUENCY_MAPPINGS](context, payload) {
    return new Promise((resolve, reject) => {
      TaskService.saveFrequencyMappings(payload)
        .then(() => {
          context.dispatch(`status/${a.CHECK_FREQUENCY_CODED_STATUS}`, null, { root: true });
          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [a.SUBMIT_PROPERTYZONE_MAPPINGS](context, payload) {
    return new Promise((resolve, reject) => {
      TaskService.savePropertyZoneMappings(payload)
        .then(() => {
          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [a.GEOCODE_ALL_TASKS]({ commit }, payload) {
    return new Promise((resolve, reject) => {
      TaskService.gecodeAllTasks(payload)
        .then(({ data }) => {
          const payload = {
            type: GEOCODING,
            jobId: data,
          };
          commit(`jobs/${m.SET_JOB_ID_BY_TYPE}`, payload, { root: true });
          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [a.GET_GEOCODE_AUDIT_COUNT](_, payload) {
    return new Promise((resolve, reject) => {
      TaskService.getGeocodeCountInCurrentMonth(payload)
        .then(({ data }) => {
          resolve(data.count);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [a.ZONECODE_ALL_TASKS]({ commit }, overwriteAll) {
    return new Promise((resolve, reject) => {
      TaskService.zonecodeAllTasks(overwriteAll)
        .then(({ data }) => {
          const payload = {
            type: ZONECODING,
            jobId: data,
          };
          commit(`jobs/${m.SET_JOB_ID_BY_TYPE}`, payload, { root: true });
          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [a.UPDATE_TASK](context, payload) {
    return new Promise((resolve, reject) => {
      TaskService.update(payload)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [a.DELETE_TASK](_context, id) {
    return new Promise((resolve, reject) => {
      TaskService.bulkDelete({
        queryParams: null,
        taskIds: [id],
        isBulk: false,
      })
        .then(() => {
          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [a.SUBMIT_BULK_UPDATE](context, payload) {
    return new Promise((resolve, reject) => {
      TaskService.bulkUpdate(payload)
        .then(() => {
          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [a.DELETE_TASKS_BULK](_context, payload) {
    return new Promise((resolve, reject) => {
      TaskService.bulkDelete(payload)
        .then(() => {
          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [a.DOWNLOAD_TASKS]() {
    return new Promise((resolve, reject) => {
      TaskService.downloadTasks()
        .then(() => {
          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [a.EXPORT_TASKS](context) {
    return new Promise((resolve, reject) => {
      TaskService.export()
        .then(({ data }) => {
          const jobTypePayload = {
            type: jobTypes.EXPORT_EXTERNAL,
            jobId: data,
          };
          context.commit(`jobs/${m.SET_JOB_ID_BY_TYPE}`, jobTypePayload, { root: true });
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};

const mutations = {};

export default {
  state,
  actions,
  mutations,
  getters,
  namespaced: true,
};
