import ApiService from '@/common/api.service';

const DataGenerationService = {
  getAdministrativeAreas() {
    const resource = 'data-generation/administrative-areas/';
    return ApiService.get(resource);
  },
  exportAdministrativeAreaAddresses(administrative_area_code, northarc_columns) {
    const resource = `data-generation/addresses/${administrative_area_code}/`;
    const params = { northarc_columns };
    return ApiService.get(resource, { params });
  },
  downloadAddresses() {
    const resource = 'tasks/downloads';

    return ApiService.get(resource).then(({ data }) => {
      const link = document.createElement('a');
      link.href = data;
      link.click();
      link.remove();
    });
  },
};
export default DataGenerationService;
