/* eslint-disable import/prefer-default-export */
import Vue from 'vue';

const upperCase = (str) => (str ? str.toUpperCase() : '');

const removeTrailingZero = (str) => (str[0] === '0' ? str[1] : str);

export const duration = (string) => {
  if (!string) return '-';

  const timeSplit = string.split(':');
  const include = (time) => time !== '00';
  let formatted = '';

  if (include(timeSplit[0])) {
    const formattedH = removeTrailingZero(timeSplit[0]);
    formatted += `${formattedH}t`;
  }

  if (include(timeSplit[1])) {
    const formattedM = removeTrailingZero(timeSplit[1]);
    formatted += `${formatted.length ? ' ' : ''}${formattedM}min`;
  }

  return formatted.length ? formatted : '-';
};

Vue.filter('upperCase', upperCase);
Vue.filter('duration', duration);
