export const sizings = (s) => ['micro', 'xs', 'sm', 'md', 'lg', 'xl'].includes(s);

export const tailwindColors = (color) => ['red', 'green', 'blue', 'teal', 'yellow', 'pink', 'purple', 'gray', 'indigo', 'orange', ''].includes(color);

export const smallLarge = (size) => size === 'sm' || size === 'lg' || size === '';

export const state = (st) => ['success', 'info', 'error', 'warning', ''].includes(st);

export const headline = (h) => ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'].includes(h);

export const inputSize = (s) => ['sm', 'md', 'lg', 'xl', 'full', ''].includes(s);
