<template>
  <div class="relative flex items-start" @click.stop="model = !model">
    <div class="flex items-center h-5">
      <input
        :id="id"
        type="checkbox"
        :checked="model"
        v-bind="$attrs"
        :aria-checked="model"
        autocomplete="none"
        class="form-checkbox h-4 w-4 text-indigo-600 transition rounded duration-150 ease-in-out cursor-pointer"
        @keyup.enter="model = !model"
      />
    </div>
    <div v-if="label || $slots.default" class="ml-3 text-sm leading-5">
      <label :for="id" class="font-medium text-gray-700">
        <slot>{{ label }}</slot>
      </label>
      <p class="text-gray-500">{{ description }}</p>
    </div>
  </div>
</template>
<script>
import { generateId } from '@/util/id';

/**
 * @displayName BaseCheckbox
 */
export default {
  name: 'BaseCheckbox',
  inheritAttrs: false,
  props: {
    /**
     * model
     */
    value: {
      type: Boolean,
      default: false,
    },
    /**
     * Label to displayed next to checkbox
     */
    label: {
      type: String,
      default: '',
    },
    /**
     * Description to displayed under label
     */
    description: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      id: generateId(),
    };
  },
  computed: {
    model: {
      get() {
        return this.value;
      },
      set(value) {
        /**
         * Fires when checkbox is toggled
         */
        this.$emit('input', value);
      },
    },
  },
};
</script>
