import ApiService from '@/common/api.service';

const RouteService = {
  getRoute(payload) {
    const resource = `routes/?disposal_week=${payload.disposal_week}&vehicle_id=${payload.vehicle_id}&disposal_day=${payload.disposal_day}&include_order=${payload.include_order}`;
    return ApiService.get(resource);
  },
  getRouteById(routeId) {
    const resource = `routes/${routeId}/`;
    return ApiService.get(resource);
  },
  async getVersions(routeId) {
    if (!routeId || typeof routeId !== 'string') {
      throw new Error('Invalid routeId');
    }

    const resource = `routes/${routeId}/versions/`;
    return ApiService.get(resource);
  },
  async createVersion(routeId) {
    if (!routeId || typeof routeId !== 'string') {
      throw new Error('Invalid routeId');
    }

    const resource = `routes/${routeId}/versions/`;

    return ApiService.post(resource);
  },
  async deleteVersion(routeId) {
    if (!routeId || typeof routeId !== 'string') {
      throw new Error('Invalid routeId');
    }

    const resource = `routes/${routeId}/versions/`;

    return ApiService.delete(resource);
  },
  async updateStopsByGivenOrder({
    routeId,
    payload,
  }) {
    if (!routeId || typeof routeId !== 'string') {
      throw new Error('Invalid routeId');
    }

    const resource = `routes/${routeId}/recalculate-order-by-given/`;

    return ApiService.post(resource, payload);
  },
};

export default RouteService;
