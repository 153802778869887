import Vue from 'vue';

// eslint-disable-next-line import/prefer-default-export
export const highlight = (itemToHighlight, searchFilter) => {
  if (!searchFilter) {
    return itemToHighlight;
  }
  return itemToHighlight.replace(
    new RegExp(searchFilter, 'ig'),
    (match) => `<span class="bg-yellow-400 text-black">${ match }</span>`,
  );
};

Vue.filter('highlight', highlight);
