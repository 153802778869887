import ApiService from '@/common/api.service';

const JobService = {
  poll(id) {
    const resource = `jobs/${id}/`;
    return ApiService.get(resource);
  },
  getRunning() {
    const resource = 'jobs/';
    return ApiService.get(resource);
  },
};

export default JobService;
