import UsersService from '@/services/users.service';
import * as a from '../actions.type';
import * as m from '../mutations.type';

const state = {
  users: [],
  sharedUsers: [],
};

const getters = {
  users: (stateObject) => stateObject.users,
  sharedUsers: (stateObject) => stateObject.sharedUsers,
};

const actions = {
  [a.FETCH_USERS](context) {
    return new Promise((resolve, reject) => {
      UsersService.getAll()
        .then(({ data }) => {
          context.commit(m.SET_USERS, data);
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [a.FETCH_SHARED_USERS](context, workspaceId) {
    return new Promise((resolve, reject) => {
      UsersService.getSharedUsersForWorkspace(workspaceId)
        .then(({ data }) => {
          context.commit(m.SET_SHARED_USERS, data);
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};

const mutations = {
  [m.SET_USERS](stateObject, users) {
    Object.assign(stateObject, { users });
  },
  [m.SET_SHARED_USERS](stateObject, sharedUsers) {
    Object.assign(stateObject, { sharedUsers });
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
  namespaced: true,
};
