import ApiService from '@/common/api.service';

const ImportService = {
  uploadFile({ file, config, filename }) {
    const resource = 'tasks/imports/';

    const formData = new FormData();
    formData.append('filename', filename);
    formData.append('csvfile', file);
    formData.append('configuration', JSON.stringify(config));

    return ApiService.post(resource, formData);
  },

  uploadFileExtraTasks({ file, config, filename }) {
    const resource = 'tasks/imports/extra';

    const formData = new FormData();
    formData.append('filename', filename);
    formData.append('csvfile', file);
    formData.append('configuration', JSON.stringify(config));

    return ApiService.post(resource, formData);
  },

  getHeaders({ file }) {
    const resource = 'tasks/imports/headers/';

    const formData = new FormData();
    formData.append('csvfile', file);
    formData.append('filename', file.name);

    return ApiService.post(resource, formData);
  },

  getAudit() {
    const resource = 'tasks/imports/audits/';
    return ApiService.get(resource);
  },

  getNewFrequencies(payload) {
    const resource = 'tasks/imports/merges/frequencies/';
    return ApiService.post(resource, payload);
  },

  buildMergePlan(payload) {
    const resource = 'tasks/imports/merges/';
    return ApiService.post(resource, payload);
  },

  uploadForMergePlan({ file }) {
    const resource = 'tasks/imports/merges/upload/';

    const formData = new FormData();
    formData.append('csvfile', file);

    return ApiService.post(resource, formData);
  },

  submitMergePlan(planId) {
    const resource = `tasks/imports/merges/${planId}/activations/`;
    return ApiService.post(resource);
  },
};

export default ImportService;
