<template>
  <span>
    <div :style="styles" class="spinner spinner--jumper">
      <div :style="divStyles"></div>
      <div :style="divStyles"></div>
      <div :style="divStyles"></div>
    </div>

    <div v-if="title" class="mt-6 ml-6 text-gray-700 font-semibold text-lg">{{ title }}</div>
  </span>
</template>
<script>
export default {
  name: 'BaseLoader',
  props: {
    size: {
      type: Number,
      default: 40,
    },
    title: {
      type: String,
      default: '',
    },
  },
  computed: {
    styles() {
      return {
        width: `${this.size}px`,
        height: `${this.size}px`,
      };
    },
    divStyles() {
      return {
        width: `${this.size * 1.2}px`,
        height: `${this.size * 1.2}px`,
      };
    },
  },
};
</script>
<style>
.spinner {
  * {
    line-height: 0;
    box-sizing: border-box;
  }
}
.spinner > div {
  background-color: #6366f1;
  border-radius: 100%;
  animation-fill-mode: both;
  position: absolute;
  opacity: 0;
  animation: jumper 1.3s 0s linear infinite;
}
.spinner > div:nth-child(2) {
  animation-delay: 0.33333s;
}
.spinner > div:nth-child(3) {
  animation-delay: 0.66666s;
}
@keyframes jumper {
  0% {
    opacity: 0;
    transform: scale(0);
  }
  5% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
</style>
