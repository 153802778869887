<template>
  <div class="h-screen flex overflow-hidden bg-gray-100">
    <the-distribution-modal></the-distribution-modal>
    <div class="flex flex-col w-0 flex-1 overflow-hidden">
      <div class="relative flex-shrink-0 flex h-16 bg-white border-b border-gray-200">
        <the-dashboard-navbar></the-dashboard-navbar>
      </div>

      <main class="flex-1 relative z-0 focus:outline-none overflow-y-auto" tabindex="0">
        <fade-transition origin="center top" mode="out-in">
          <!-- Cache the MapView so the user can transition back and forth smoothly -->
          <keep-alive include="MapView">
            <router-view></router-view>
          </keep-alive>
        </fade-transition>
      </main>
    </div>
  </div>
</template>
<script>
import { FadeTransition } from 'vue2-transitions';
import TheDashboardNavbar from '@/layout/TheDashboardNavbar.vue';
import TheDistributionModal from '@/components/TheDistributionModal.vue';

import { mapActions, mapGetters } from 'vuex';
import { ALL_DEPARTMENTS, CHECK_STATUS_ALL, CHECK_RUNNING_JOBS, GET_MOST_IRREGULAR_FREQUENCY } from '@/store/actions.type';

export default {
  name: 'TheDashboardLayout',
  components: {
    TheDashboardNavbar,
    FadeTransition,
    TheDistributionModal,
  },
  computed: {
    ...mapGetters('departments', ['activeWorkspaceId']),
    ...mapGetters('status', ['hasAnyTasks']),
  },
  async mounted() {
    await this.allDepartments();
    if (!this.activeWorkspaceId || this.activeWorkspaceId === 'undefined') {
      this.$ntf.warning({
        message: 'Ingen workspace indlæst',
        subtitle: 'Vælg et workspace',
        duration: 7000,
      });
      // prevent duplicate navigation
      if (this.$route.name !== 'workspaces') {
        this.$router.push({ name: 'workspaces' });
      }
      return;
    }
    await this.checkStatusAll();
    await this.checkRunningJobs();
    await this.getMostIrregularFrequency();

    document.addEventListener('keydown', this.toggleNavigationHotkeys);

    this.$once('hook:beforeDestroy', () => {
      document.removeEventListener('keydown', this.toggleNavigationHotkeys);
    });
  },
  methods: {
    ...mapActions('departments', {
      allDepartments: ALL_DEPARTMENTS,
    }),
    ...mapActions('frequencies', {
      getMostIrregularFrequency: GET_MOST_IRREGULAR_FREQUENCY,
    }),
    ...mapActions('status', {
      checkStatusAll: CHECK_STATUS_ALL,
    }),
    ...mapActions('jobs', {
      checkRunningJobs: CHECK_RUNNING_JOBS,
    }),
    toggleNavigationHotkeys(event) {
      const currentRoute = this.$router.currentRoute.name;

      if (event.altKey && event.key === 'p' && currentRoute !== 'tasks') {
        this.$router.push({ name: 'tasks' });
      }

      if (event.altKey && event.key === 'k' && currentRoute !== 'map') {
        this.$router.push({ name: 'map' });
      }
    },
  },
};
</script>

<style>
.content {
  min-height: calc(100% - 64px);
}
</style>
