import Vue from 'vue';
import Main from './Confirm.vue';

const Confirm = (props) =>
  // eslint-disable-next-line implicit-arrow-linebreak
  new Promise((resolve, reject) => {
    const dialog = new Vue({
      render(h) {
        return h(Main, {
          props,
          on: {
            confirm: resolve,
            cancel: reject,
          },
        });
      },
    }).$mount();

    if (props.htmlContainerId) {
      document.querySelector(`#${props.htmlContainerId}`).appendChild(dialog.$el);
    } else {
      document.body.appendChild(dialog.$el);
    }
  });

export default Confirm;
