import store from '@/store/index';
import { FORCE_LOGOUT, REFRESH_TOKEN, FORBIDDEN_REQUEST } from '@/store/actions.type';

class Interceptor {
  isAlreadyFetchingAccessToken;

  subscribers;

  constructor(axiosInstance) {
    this.isAlreadyFetchingAccessToken = false;
    this.subscribers = [];

    axiosInstance.interceptors.response.use(
      (response) => response,
      (error) => {
        const {
          config,
          response: { status },
        } = error;

        const expiredAccessToken = error.response.headers.access_token_invalid;
        const originalRequest = config;

        if (status === 403) {
          store.dispatch(`auth/${FORBIDDEN_REQUEST}`);
          return Promise.reject(error.response);
        }

        if (status === 401) {
          if (expiredAccessToken) {
            if (!this.isAlreadyFetchingAccessToken) {
              this.isAlreadyFetchingAccessToken = true;
              store
                .dispatch(`auth/${REFRESH_TOKEN}`)
                .then(() => {
                  this.onAccessTokenFetched();
                })
                .catch(() => {
                  this.subscribers = [];
                  store.dispatch(`auth/${FORCE_LOGOUT}`);
                })
                .finally(() => {
                  this.isAlreadyFetchingAccessToken = false;
                });
            }

            const retryOriginalRequest = new Promise((resolve) => {
              this.addSubscriber(() => {
                resolve(axiosInstance(originalRequest));
              });
            });
            return retryOriginalRequest;
          }
          // we can force logout the user if we reach here
          // this includes: blacksted token, refresh token expired
          // and completely unauthenticated requests
          store.dispatch(`auth/${FORCE_LOGOUT}`);
        }
        return Promise.reject(error.response);
      },
    );
  }

  onAccessTokenFetched() {
    const filtered = this.subscribers.filter((callback) => callback());
    this.subscribers = filtered;
  }

  addSubscriber(callback) {
    this.subscribers.push(callback);
  }
}

export default Interceptor;
