<template>
  <div v-click-outside="close" :class="width">
    <div class="relative">
      <button
        ref="trigger"
        type="button"
        class="relative w-full bg-white border border-gray-300 shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
        :class="[{ 'rounded-md': rounded, 'bg-gray-200': disabled }]"
        :disabled="disabled"
        :style="heightStyle"
        @click.stop="toggleOpen"
      >
        <span class="block truncate z-0">
          <span v-if="selected">{{selected.label}}</span>
          <span v-else class="text-gray-500">{{placeholder}}</span>
        </span>

        <span
          v-if="clearable && value && !disabled"
          class="absolute top-2 right-8 z-20"
          :class="[heightStyle ? 'top-3.5': 'top-2']"
          @click.stop="onClear"
        >
          <icon-x class="w-5 h-5 text-gray-400 hover:text-gray-600 cursor-pointer"></icon-x>
        </span>

        <span
          v-if="!disabled"
          class="absolute inset-y-0 right-0 flex items-center pr-2 cursor-pointer z-20"
        >
          <!-- Heroicon name: solid/selector -->
          <svg
            class="h-5 w-5 text-gray-400"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true"
          >
            <path
              fill-rule="evenodd"
              d="M10 3a1 1 0 01.707.293l3 3a1 1 0 01-1.414 1.414L10 5.414 7.707 7.707a1 1 0 01-1.414-1.414l3-3A1 1 0 0110 3zm-3.707 9.293a1 1 0 011.414 0L10 14.586l2.293-2.293a1 1 0 011.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
              clip-rule="evenodd"
            />
          </svg>
        </span>
      </button>

      <div
        v-if="!disabled"
        class="absolute left-0 z-10 pr-8 w-full border border-transparent top-0 h-8"
        :style="heightStyle"
        :class="{'bg-transparent': !searchValue}"
      >
        <input
          ref="inputSearch"
          v-model="searchModel"
          autocomplete="none"
          tabindex="-1"
          class="h-full w-full focus:outline-none pl-3 py-2 ml-0.5"
          :class="{'bg-transparent': !searchValue}"
          @focus="isOpen = true"
        />
      </div>
      <transition
        leave-active-class="transition ease-in duration-100"
        leave-class="opacity-100"
        leave-to-class="opacity-0"
      >
        <ul
          v-if="isOpen"
          class="absolute z-30 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm"
          tabindex="-1"
          role="listbox"
        >
          <li v-if="options.length === 0">
            <span class="py-2 pl-8 pr-4 block text-gray-900">Ingen elementer</span>
          </li>

          <li
            v-for="(option, index) in filteredOptions"
            :key="option.value"
            :ref="`list-item-${option.value}`"
            class="cursor-default select-none relative py-2 pl-8 pr-4"
            :class="[index === activeIndex ? 'text-white bg-indigo-600': 'text-gray-900']"
            role="option"
            @click="onSelect(option.value)"
            @mouseover="onMouseover(index)"
            @mouseleave="onMouseleave"
          >
            <span
              class="block truncate"
              :class="[value === option.value ? 'font-semibold' : 'font-normal']"
            >{{option.label}}</span>

            <span
              v-if="value === option.value"
              :class="[index === activeIndex ? 'text-white' : 'text-indigo-600']"
              class="absolute inset-y-0 left-0 flex items-center pl-1.5"
            >
              <!-- Heroicon name: solid/check -->
              <svg
                class="h-5 w-5"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              >
                <path
                  fill-rule="evenodd"
                  d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                  clip-rule="evenodd"
                />
              </svg>
            </span>
          </li>

          <li
            v-if="!filteredOptions.length && searchModel"
            class="cursor-default select-none relative py-2 pl-8 pr-4 text-yellow-800"
          >
            <span class="block truncate font-semibold">Intet match fundet</span>
          </li>
        </ul>
      </transition>
    </div>
  </div>
</template>

<script>
import ClickOutside from 'vue-click-outside';

export default {
  name: 'BaseSelect',
  directives: {
    ClickOutside,
  },
  props: {
    options: {
      type: Array,
      default: () => [],
    },
    value: {
      type: [Number, String],
      required: true,
    },
    placeholder: {
      type: String,
      default: 'Vælg..',
    },
    clearable: {
      type: Boolean,
      default: false,
      description: 'Whether to show a small X icon to clear the current selection',
    },
    width: {
      type: String,
      default: 'w-64',
      description: 'The width of the select input. Must be a tailwind width class',
    },
    rounded: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    height: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      isOpen: false,
      activeIndex: -1,
      searchValue: '',
    };
  },
  computed: {
    selected() {
      if (this.value === null || this.value === undefined) return null;
      return this.options.find((option) => option.value === this.value);
    },
    heightStyle() {
      if (!this.height) {
        return null;
      }

      return {
        height: this.height,
      };
    },
    filteredOptions() {
      return this.options.filter((option) => option.label.toLowerCase().includes(this.searchValue.toLowerCase()));
    },
    searchModel: {
      get() {
        return this.searchValue;
      },
      set(val) {
        this.activeIndex = -1;
        this.searchValue = val;
      },
    },
  },
  mounted() {
    this.$el.addEventListener('keydown', this.navigate);

    this.$once('hook:beforeDestroy', () => {
      this.$el.removeEventListener('keydown', this.navigate);
    });
  },
  methods: {
    onSelect(value) {
      if (this.disabled) return;
      this.$emit('input', value);
      this.$refs.trigger.blur();
      this.searchValue = '';

      this.close();
    },
    onClear() {
      this.onSelect('');
    },
    toggleOpen() {
      if (this.disabled) return;
      this.isOpen = !this.isOpen;
    },
    close() {
      const { inputSearch } = this.$refs;
      if (inputSearch) {
        inputSearch.blur();
      }
      this.isOpen = false;
    },
    focus() {
      setTimeout(() => {
        this.$refs.inputSearch.focus();
      }, 100);
    },
    onMouseover(index) {
      this.activeIndex = index;
    },
    onMouseleave() {
      this.activeIndex = -1;
    },
    scrollTo(value) {
      const item = this.$refs[`list-item-${value}`];
      item[0].scrollIntoView({
        block: 'nearest',
        behavior: 'smooth',
      });
    },
    navigate({ key }) {
      if (!this.isOpen) return;

      const len = this.filteredOptions.length;

      switch (key) {
        case 'ArrowDown':
          this.activeIndex = this.activeIndex === len - 1 ? 0 : (this.activeIndex += 1);
          this.scrollTo(this.filteredOptions[this.activeIndex].value);
          break;
        case 'ArrowUp':
          this.activeIndex = this.activeIndex === 0 ? len - 1 : (this.activeIndex -= 1);
          this.scrollTo(this.filteredOptions[this.activeIndex].value);
          break;
        case 'Enter':
          if (this.activeIndex !== -1) {
            const activeValue = this.filteredOptions[this.activeIndex].value;
            this.onSelect(activeValue);
          }
          break;
        case 'Escape':
          this.close();
          break;
        default:
          break;
      }
    },
  },
};
</script>
