import ApiService from '@/common/api.service';

const UsersService = {
    getAll() {
    const resource = 'users/';
    return ApiService.get(resource);
  },

  getSharedUsersForWorkspace(workspaceId) {
    const resource = `workspaces/${workspaceId}/shared-users/`;
    return ApiService.get(resource);
  },
};

export default UsersService;
