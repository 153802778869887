import IconCheckCircle from './outline/IconCheckCircle.vue';
import IconXCircle from './outline/IconXCircle.vue';
import IconExclamationCircle from './outline/IconExclamationCircle.vue';
import IconInformationCircle from './outline/IconInformationCircle.vue';
import IconTrash from './outline/IconTrash.vue';
import IconPlus from './outline/IconPlus.vue';
import IconTable from './outline/IconTable.vue';
import IconMinus from './outline/IconMinus.vue';
import IconHome from './outline/IconHome.vue';
import IconTruck from './outline/IconTruck.vue';
import IconExclamation from './outline/IconExclamation.vue';
import IconCalendar from './outline/IconCalendar.vue';
import IconQuestionMarkCircle from './outline/IconQuestionMarkCircle.vue';
import IconCollection from './outline/IconCollection.vue';
import IconChevDown from './outline/IconChevDown.vue';
import IconChevRight from './outline/IconChevRight.vue';
import IconUser from './outline/IconUser.vue';
import IconOfficeBuilding from './outline/IconOfficeBuilding.vue';
import IconClock from './outline/IconClock.vue';
import IconUserGroup from './outline/IconUserGroup.vue';
import IconPencil from './outline/IconPencil.vue';
import IconX from './outline/IconX.vue';
import IconRefresh from './outline/IconRefresh.vue';
import IconCog from './outline/IconCog.vue';
import IconShieldExclamation from './outline/IconShieldExclamation.vue';
import IconBeaker from './outline/IconBeaker.vue';
import IconDownload from './outline/IconDownload.vue';
import IconDocumentText from './outline/IconDocumentText.vue';
import IconLightningBolt from './outline/IconLightningBolt.vue';
import IconLightBulb from './outline/IconLightBulb.vue';
import IconAdjustments from './outline/IconAdjustments.vue';
import IconLibrary from './outline/IconLibrary.vue';
import IconSearch from './outline/IconSearch.vue';
import IconReceiptTax from './outline/IconReceiptTax.vue';
import IconViewGrid from './outline/IconViewGrid.vue';
import IconViewGridAdd from './outline/IconViewGridAdd.vue';
import IconSortAscending from './outline/IconSortAscending.vue';
import IconSortDescending from './outline/IconSortDescending.vue';
import IconFilter from './outline/IconFilter.vue';
import IconFolder from './outline/IconFolder.vue';
import IconSubFolder from './outline/IconSubFolder.vue';
import IconChevDoubleLeft from './outline/IconChevDoubleLeft.vue';
import IconChevDoubleRight from './outline/IconChevDoubleRight.vue';
import IconClipboard from './outline/IconClipboard.vue';
import IconCheckCircleSolid from './solid/IconCheckCircleSolid.vue';
import IconXCircleSolid from './solid/IconXCircleSolid.vue';
import IconExclamationCircleSolid from './solid/IconExclamationCircleSolid.vue';
import IconInformationCircleSolid from './solid/IconInformationCircleSolid.vue';
import IconArrowRight from './solid/IconArrowRight.vue';
import IconArrowNarrowRight from './solid/IconArrowNarrowRight.vue';
import IconCheck from './solid/IconCheck.vue';
import IconStar from './solid/IconStar.vue';
import IconCursor from './solid/IconCursor.vue';
import IconErrorIcon from './IconErrorIcon.vue';

export default {
  install(Vue) {
    Vue.component(IconCheckCircle.name, IconCheckCircle);
    Vue.component(IconXCircle.name, IconXCircle);
    Vue.component(IconExclamationCircle.name, IconExclamationCircle);
    Vue.component(IconInformationCircle.name, IconInformationCircle);
    Vue.component(IconCheckCircleSolid.name, IconCheckCircleSolid);
    Vue.component(IconQuestionMarkCircle.name, IconQuestionMarkCircle);
    Vue.component(IconCalendar.name, IconCalendar);
    Vue.component(IconHome.name, IconHome);
    Vue.component(IconSearch.name, IconSearch);
    Vue.component(IconBeaker.name, IconBeaker);
    Vue.component(IconStar.name, IconStar);
    Vue.component(IconCollection.name, IconCollection);
    Vue.component(IconXCircleSolid.name, IconXCircleSolid);
    Vue.component(IconExclamationCircleSolid.name, IconExclamationCircleSolid);
    Vue.component(IconInformationCircleSolid.name, IconInformationCircleSolid);
    Vue.component(IconChevDown.name, IconChevDown);
    Vue.component(IconChevRight.name, IconChevRight);
    Vue.component(IconTrash.name, IconTrash);
    Vue.component(IconPlus.name, IconPlus);
    Vue.component(IconTable.name, IconTable);
    Vue.component(IconMinus.name, IconMinus);
    Vue.component(IconUser.name, IconUser);
    Vue.component(IconOfficeBuilding.name, IconOfficeBuilding);
    Vue.component(IconClock.name, IconClock);
    Vue.component(IconUserGroup.name, IconUserGroup);
    Vue.component(IconX.name, IconX);
    Vue.component(IconPencil.name, IconPencil);
    Vue.component(IconArrowRight.name, IconArrowRight);
    Vue.component(IconArrowNarrowRight.name, IconArrowNarrowRight);
    Vue.component(IconAdjustments.name, IconAdjustments);
    Vue.component(IconRefresh.name, IconRefresh);
    Vue.component(IconCog.name, IconCog);
    Vue.component(IconShieldExclamation.name, IconShieldExclamation);
    Vue.component(IconCheck.name, IconCheck);
    Vue.component(IconDownload.name, IconDownload);
    Vue.component(IconDocumentText.name, IconDocumentText);
    Vue.component(IconLightningBolt.name, IconLightningBolt);
    Vue.component(IconLightBulb.name, IconLightBulb);
    Vue.component(IconFolder.name, IconFolder);
    Vue.component(IconSubFolder.name, IconSubFolder);
    Vue.component(IconLibrary.name, IconLibrary);
    Vue.component(IconErrorIcon.name, IconErrorIcon);
    Vue.component(IconTruck.name, IconTruck);
    Vue.component(IconExclamation.name, IconExclamation);
    Vue.component(IconReceiptTax.name, IconReceiptTax);
    Vue.component(IconCursor.name, IconCursor);
    Vue.component(IconViewGrid.name, IconViewGrid);
    Vue.component(IconViewGridAdd.name, IconViewGridAdd);
    Vue.component(IconSortAscending.name, IconSortAscending);
    Vue.component(IconSortDescending.name, IconSortDescending);
    Vue.component(IconFilter.name, IconFilter);
    Vue.component(IconClipboard.name, IconClipboard);
    Vue.component(IconChevDoubleRight.name, IconChevDoubleRight);
    Vue.component(IconChevDoubleLeft.name, IconChevDoubleLeft);
  },
};
