export const background50 = {
  red: 'bg-red-50',
  green: 'bg-green-50',
  blue: 'bg-blue-50',
  teal: 'bg-teal-50',
  yellow: 'bg-yellow-50',
  pink: 'bg-pink-50',
  purple: 'bg-purple-50',
  gray: 'bg-gray-50',
  indigo: 'bg-indigo-50',
  orange: 'bg-orange-50',
};

export const background100 = {
  red: 'bg-red-100',
  green: 'bg-green-100',
  blue: 'bg-blue-100',
  teal: 'bg-teal-100',
  yellow: 'bg-yellow-100',
  pink: 'bg-pink-100',
  purple: 'bg-purple-100',
  gray: 'bg-gray-100',
  indigo: 'bg-indigo-100',
  orange: 'bg-orange-100',
};

export const background200 = {
  red: 'bg-red-200',
  green: 'bg-green-200',
  blue: 'bg-blue-200',
  teal: 'bg-teal-200',
  yellow: 'bg-yellow-200',
  pink: 'bg-pink-200',
  purple: 'bg-purple-200',
  gray: 'bg-gray-200',
  indigo: 'bg-indigo-200',
  orange: 'bg-orange-200',
};

export const background400 = {
  red: 'bg-red-400',
  green: 'bg-green-400',
  blue: 'bg-blue-400',
  teal: 'bg-teal-400',
  yellow: 'bg-yellow-400',
  pink: 'bg-pink-400',
  purple: 'bg-purple-400',
  gray: 'bg-gray-400',
  indigo: 'bg-indigo-400',
  orange: 'bg-orange-400',
};

export const background600 = {
  red: 'bg-red-600',
  green: 'bg-green-600',
  blue: 'bg-blue-600',
  teal: 'bg-teal-600',
  yellow: 'bg-yellow-600',
  pink: 'bg-pink-600',
  purple: 'bg-purple-600',
  gray: 'bg-gray-600',
  indigo: 'bg-indigo-600',
  orange: 'bg-orange-600',
};

export const background700 = {
  red: 'bg-red-700',
  green: 'bg-green-700',
  blue: 'bg-blue-700',
  teal: 'bg-teal-700',
  yellow: 'bg-yellow-700',
  pink: 'bg-pink-700',
  purple: 'bg-purple-700',
  gray: 'bg-gray-700',
  indigo: 'bg-indigo-700',
  orange: 'bg-orange-700',
};

export const background800 = {
  red: 'bg-red-800',
  green: 'bg-green-800',
  blue: 'bg-blue-800',
  teal: 'bg-teal-800',
  yellow: 'bg-yellow-800',
  pink: 'bg-pink-800',
  purple: 'bg-purple-800',
  gray: 'bg-gray-800',
  indigo: 'bg-indigo-800',
  orange: 'bg-orange-800',
};

export const background900 = {
  red: 'bg-red-900',
  green: 'bg-green-900',
  blue: 'bg-blue-900',
  teal: 'bg-teal-900',
  yellow: 'bg-yellow-900',
  pink: 'bg-pink-900',
  purple: 'bg-purple-900',
  gray: 'bg-gray-900',
  indigo: 'bg-indigo-900',
  orange: 'bg-orange-900',
};
