<template>
  <svg :class="classes" viewBox="0 0 20 20" fill="currentColor">
    <path
      fill-rule="evenodd"
      d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
      clip-rule="evenodd"
    />
  </svg>
</template>

<script>
/**
 * @displayName CheckCircleSolid
 */
export default {
  name: 'IconCheckCircleSolid',
  props: {
    /**
     * css classes
     */
    classes: {
      type: String,
      default: 'h-6 w-6 text-green-500',
    },
  },
};
</script>
