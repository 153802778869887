/* eslint-disable no-param-reassign */
import Vue from 'vue';
import Main from './Notify.vue';

const NotificationConstructor = Vue.extend(Main);

let instance;
const instances = [];
let seed = 1;

const NorthArcNotification = (options) => {
  options = options || {};
  if (typeof options === 'string') {
    options = {
      message: options,
    };
  }
  const userOnClose = options.onClose;
  const id = `message_${seed++}`;

  options.onClose = () => {
    NorthArcNotification.close(id, userOnClose);
  };
  instance = new NotificationConstructor({
    data: options,
  });
  instance.id = id;

  instance.$mount();
  document.body.appendChild(instance.$el);
  let verticalOffset = options.offset || 20;

  instances.forEach((item) => {
    verticalOffset += item.$el.offsetHeight + 16;
  });
  instance.verticalOffset = verticalOffset;
  instance.visible = true;
  instances.push(instance);

  return instance;
};

['success', 'warning', 'info', 'error'].forEach((type) => {
  NorthArcNotification[type] = (options) => {
    if (typeof options === 'string') {
      options = {
        message: options,
      };
    }
    options.type = type;
    return NorthArcNotification(options);
  };
});

NorthArcNotification.close = (id, userOnClose) => {
  const len = instances.length;
  let index = -1;
  let removedHeight;
  for (let i = 0; i < len; i++) {
    if (id === instances[i].id) {
      removedHeight = instances[i].$el.offsetHeight;
      index = i;
      if (typeof userOnClose === 'function') {
        userOnClose(instances[i]);
      }
      instances.splice(i, 1);
      break;
    }
  }
  if (len <= 1 || index === -1 || index > instances.length - 1) return;
  for (let i = index; i < len - 1; i++) {
    const dom = instances[i].$el;
    dom.style.top = `${parseInt(dom.style.top, 10) - removedHeight - 16}px`;
  }
};

NorthArcNotification.closeAll = () => {
  for (let i = instances.length - 1; i >= 0; i--) {
    instances[i].close();
  }
};

export default NorthArcNotification;
