import ApiService from '@/common/api.service';

const AggregationService = {
  aggregateBy(aggregation, countingType) {
    const resource = `aggregations/${aggregation}/${countingType}`;
    return ApiService.get(resource);
  },
};

export default AggregationService;
