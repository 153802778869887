<template>
  <svg
    fill="none"
    class="text-indigo-500"
    stroke="currentColor"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="2"
      d="M3 4h13M3 8h9m-9 4h9m5-4v12m0 0l-4-4m4 4l4-4"
    ></path>
  </svg>
</template>

<script>
export default {
  name: 'IconSortDescending',
};
</script>
