<template>
  <div class="flex flex-col items-center justify-center mt-4 min-h-384px">
    <img :src="imgSrc" class="h-auto w-52" alt />

    <h3 class="mt-4 text-sm font-medium text-gray-900">{{ title }}</h3>
    <p v-if="subtitle" class="mt-1 text-sm text-gray-500">{{ subtitle }}</p>

    <slot />
  </div>
</template>

<script>
/* eslint-disable import/no-dynamic-require */
/* eslint-disable global-require */
export default {
  name: 'ImageContainer',
  props: {
    assetName: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      default: '',
    },
    subtitle: {
      type: String,
      default: '',
    },
  },
  computed: {
    imgSrc() {
      if (this.assetName) {
        return require(`@/assets/images/${this.assetName}.svg`);
      }

      return '';
    },
  },
};
</script>
