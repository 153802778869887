import ApiService from '@/common/api.service';

const StatusService = {
  anyTasks() {
    const resource = 'tasks/details/';
    return ApiService.get(resource);
  },

  getGeocodeStatus() {
    const resource = 'tasks/geocodes/status';
    return ApiService.get(resource);
  },

  getZonecodeStatus() {
    const resource = 'tasks/zonecodes/status';
    return ApiService.get(resource);
  },

  getFrequencyCodeStatus() {
    const resource = 'tasks/frequencies/status/';
    return ApiService.get(resource);
  },

  getPlannedInstanceStatus() {
    const resource = 'planning';
    return ApiService.get(resource);
  },

  getAnyWithoutAbbreviation() {
    const resource = 'materiels/status/';
    return ApiService.get(resource);
  },
};

export default StatusService;
