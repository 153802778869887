export const EXACT = 'exact';
export const CONTAINS = 'contains';
export const NOTCONTAINS = 'notcontains';
export const NOT = 'not';

const shortMap = {};
shortMap[EXACT] = 'ext';
shortMap[CONTAINS] = 'con';
shortMap[NOTCONTAINS] = 'ncon';
shortMap[NOT] = 'not';

export const toShort = (filter) => shortMap[filter];

const translations = {};
translations[EXACT] = '=';
translations[CONTAINS] = 'indeholder';
translations[NOTCONTAINS] = 'indeholder ikke';
translations[NOT] = '[ikke]=';

export const toLocale = (filter) => translations[filter];
