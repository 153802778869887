/* eslint-disable no-param-reassign */
/* eslint-disable camelcase */
/* eslint-disable object-curly-newline */
/* eslint-disable no-shadow */
import FrequencyService from '@/services/frequencies.service';

import * as a from '../actions.type';
import * as m from '../mutations.type';

const state = {
  mostIrregularInterval: 0,
  frequencyOptions: [],
};

const getters = {
  mostIrregularInterval() {
    return state.mostIrregularInterval;
  },
  plannedWeekNoOptions(state, getters) {
    const options = [];

    for (let index = 1; index < getters.mostIrregularInterval + 1; index++) {
      options.push({ label: `Uge ${index}`, value: index });
    }

    return options;
  },
  plannedWeekNoFilterOptions(state, getters) {
    return [...getters.plannedWeekNoOptions, { label: 'Ukendt', value: -1 }];
  },
  frequencyOptions(state) {
    return state.frequencyOptions;
  },
};

const actions = {
  [a.GET_MOST_IRREGULAR_FREQUENCY](context) {
    return new Promise((resolve, reject) => {
      FrequencyService.getMostIrregularFrequencyInUse()
        .then(({ data }) => {
          context.commit(m.SET_MOST_IRREGULAR_FREQUENCY, data);
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [a.GET_FREQUENCY_OPTIONS](context) {
    return new Promise((resolve, reject) => {
      FrequencyService.getSelectOptions()
        .then(({ data }) => {
          context.commit(m.SET_FREQUENCY_OPTIONS, data);
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};

const mutations = {
  [m.SET_MOST_IRREGULAR_FREQUENCY](state, interval) {
    state.mostIrregularInterval = interval;
  },
  [m.SET_FREQUENCY_OPTIONS](state, options) {
    state.frequencyOptions = options;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
  namespaced: true,
};
