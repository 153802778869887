/* eslint-disable no-param-reassign */
/* eslint-disable camelcase */
/* eslint-disable object-curly-newline */
/* eslint-disable no-shadow */
import DrivingListsService from '@/services/drivingLists.service';

import * as jobTypes from '@/util/jobTypes';

import * as a from '../actions.type';
import * as m from '../mutations.type';

const state = {
  drivingLists: [],
  loading: false,
};

const getters = {
  drivingLists(state) {
    return state.drivingLists;
  },
  drivingListsLoading(state) {
    return state.loading;
  },
};

const actions = {
  [a.GENERATE_DRIVING_LISTS]({ commit }, payload) {
    return new Promise((resolve, reject) => {
      DrivingListsService.create({ ...payload })
        .then(({ data }) => {
          const jobTypePayload = {
            type: jobTypes.GENERATEDRIVINGLISTS,
            jobId: data,
          };
          commit(`jobs/${m.SET_JOB_ID_BY_TYPE}`, jobTypePayload, { root: true });
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [a.GET_DRIVING_LISTS]({ commit }) {
    commit(m.SET_DRIVING_LISTS_LOADING, true);
    return new Promise((resolve, reject) => {
      DrivingListsService.getAll()
        .then(({ data }) => {
          commit(m.SET_DRIVING_LISTS, data);
          resolve();
        })
        .catch((err) => {
          reject(err);
        })
        .finally(() => {
          commit(m.SET_DRIVING_LISTS_LOADING, false);
        });
    });
  },

  [a.GET_DRIVING_LIST_CANDIDATES]({ commit }) {
    commit(m.SET_DRIVING_LISTS_LOADING, true);
    return new Promise((resolve, reject) => {
      DrivingListsService.getAllCandidates()
        .then(({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  [a.GET_ZIPPED_DRIVING_LIST_INFO]() {
    return new Promise((resolve, reject) => {
      DrivingListsService.getZippedDrivingList()
        .then(({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};

const mutations = {
  [m.SET_DRIVING_LISTS_LOADING](state, isLoading) {
    state.loading = isLoading;
  },
  [m.SET_DRIVING_LISTS](state, drivingLists) {
    state.drivingLists = drivingLists;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
  namespaced: true,
};
