import ApiService from '@/common/api.service';

const FrequencyService = {
  getAll() {
    const resource = 'frequencies/';
    return ApiService.get(resource);
  },

  getSelectOptions() {
    const resource = 'frequencies/?selectOptions=1';
    return ApiService.get(resource);
  },

  getFrequenciesInUseAsSelectOptions() {
    const resource = 'frequencies/uses/?selectOptions=1';
    return ApiService.get(resource);
  },

  getFrequenciesInUse() {
    const resource = 'frequencies/uses/';
    return ApiService.get(resource);
  },

  getMostIrregularFrequencyInUse() {
    const resource = 'frequencies/intervals/most-irregular';
    return ApiService.get(resource);
  },
};

export default FrequencyService;
