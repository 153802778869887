import store from '@/store/index';
import { FORBIDDEN_EXTERNAL_ACCCESS_REQUEST } from '@/store/actions.type';

class ExternalAccessInterceptor {
  constructor(axiosInstance) {
    this.isAlreadyRedirecting = false;

    axiosInstance.interceptors.response.use(
      (response) => response,
      (error) => {
        const {
          response: { status },
        } = error;

        if (this.isAlreadyRedirecting) {
          return {};
        }

        if (status === 403 || status === 401) {
          store.dispatch(`auth/${FORBIDDEN_EXTERNAL_ACCCESS_REQUEST}`);
          this.isAlreadyRedirecting = true;
          return Promise.reject(error.response);
        }

        return Promise.reject(error.response);
      },
    );
  }
}

export default ExternalAccessInterceptor;
