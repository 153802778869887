/* eslint-disable no-param-reassign */
/* eslint-disable no-shadow */
import AuthService from '@/services/auth.service';
import router from '@/router';

import * as a from '../actions.type';
import * as m from '../mutations.type';

const state = {
  user: {},
  settings: {},
  loading: false,
  isAuthenticated: false,
  errors: [],
};

const getters = {
  currentUser() {
    return state.user;
  },
  isAuthenticated() {
    return state.isAuthenticated;
  },
  loading() {
    return state.loading;
  },
  errors() {
    return state.errors;
  },
};

const actions = {
  [a.LOGIN](context, credentials) {
    return new Promise((resolve, reject) => {
      context.commit(m.SET_AUTH_LOADING, true);
      AuthService.login(credentials)
        .then((data) => {
          context.commit(m.SET_AUTH, {
            user: data,
          });
          resolve(data);
        })
        .catch((err) => {
          reject(err.status);
        })
        .finally(() => context.commit(m.SET_AUTH_LOADING, false));
    });
  },
  [a.FIRST_LOGIN](context, payload) {
    return new Promise((resolve, reject) => {
      context.commit(m.SET_AUTH_LOADING, true);
      AuthService.firstLogin(payload)
        .then(() => {
          resolve();
        })
        .catch((err) => {
          reject(err);
        })
        .finally(() => context.commit(m.SET_AUTH_LOADING, false));
    });
  },
  [a.REFRESH_TOKEN]() {
    return AuthService.refreshTokens();
  },
  [a.CHECK_AUTH](context) {
    return new Promise((resolve) => {
      AuthService.checkAuth()
        .then(({ data }) => {
          context.commit(m.SET_AUTH, {
            user: data,
          });

          resolve(data);
        })
        .catch(() => {
          context.commit(m.PURGE_AUTH);
        });
    });
  },
  [a.FORCE_LOGOUT](context) {
    context.commit(m.PURGE_AUTH);
    if (router.history.current.name !== 'login') {
      router.push({
        name: 'login',
        query: { redirect: router.currentRoute.path },
      });
    }
  },
  [a.FORBIDDEN_REQUEST]() {
    router.push({ name: 'forbidden' });
  },
  [a.FORBIDDEN_EXTERNAL_ACCCESS_REQUEST]() {
    router.push({ name: 'forbidden-external-access' });
  },
  [a.BLACKLIST](context) {
    AuthService.logout().catch(() => {});
    context.commit(m.PURGE_AUTH);
    router.push({ name: 'login' });
  },
};

const mutations = {
  [m.SET_AUTH](state, { user }) {
    state.user = user;
    state.isAuthenticated = true;
  },
  [m.PURGE_AUTH](state) {
    state.user = {};
    state.isAuthenticated = false;
  },
  [m.SET_AUTH_LOADING](state, isLoading) {
    state.loading = isLoading;
  },
  [m.SET_AUTH_ERROR](state, errors) {
    if (!errors) {
      state.errors = [];
      return;
    }
    // eslint-disable-next-line no-param-reassign
    delete errors.data.status_code;
    const formattedErrors = Object.values(errors.data).map((err) => err.join(''));
    state.errors = formattedErrors;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
  namespaced: true,
};
