/* eslint-disable no-shadow */
import Vue from 'vue';
import format from 'date-fns/format';
import { da } from 'date-fns/esm/locale';

export const dateTime = (date) => format(new Date(date), 'dd-MM HH:mm');

export const longDateTime = (date) => format(new Date(date), 'dd-MM-yyyy HH:mm');

export const day = (date) => {
  if (!date) return '';
  return format(new Date(date), 'EEE', {
    locale: da,
  });
};

export const hourMinutes = (date) => {
  if (!date) return '-';

  return format(new Date(date), 'HH:mm');
};

export const time = (timeString) => {
  if (!timeString) return '-';

  return timeString.substring(0, 5);
};

export const shortDate = (date) => {
  if (!date) return '';
  return format(new Date(date), 'dd-MM-yyyy');
};

export const longDate = (date) => {
  if (!date) return '';
  return format(new Date(date), 'd. MMM yyyy', {
    locale: da,
  });
};

Vue.filter('dateTime', dateTime);
Vue.filter('day', day);
Vue.filter('time', time);
Vue.filter('shortDate', shortDate);
Vue.filter('longDate', longDate);
Vue.filter('hourMinutes', hourMinutes);
Vue.filter('longDateTime', longDateTime);
