import Vue from 'vue';
import Router from 'vue-router';
import store from '@/store/index';
import { CHECK_AUTH } from '@/store/actions.type';

import TheDashboardLayout from '@/layout/TheDashboardLayout.vue';

Vue.use(Router);

const router = new Router({
  linkActiveClass: 'active-nav-link',
  linkExactActiveClass: 'active-nav-link',
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }
    return { x: 0, y: 0 };
  },
  routes: [
    {
      path: '/',
      component: TheDashboardLayout,
      redirect: { name: 'workspaces' },
      name: 'dashboard',
      children: [
        {
          path: '/workspaces',
          name: 'workspaces',
          // route level code-splitting
          // this generates a separate chunk (about.[hash].js) for this route
          // which is lazy-loaded when the route is visited.
          component: () => import(/* webpackChunkName: "plan" */ './views/WorkspaceView.vue'),
        },
        {
          path: '/plan',
          name: 'plan',
          // route level code-splitting
          // this generates a separate chunk (about.[hash].js) for this route
          // which is lazy-loaded when the route is visited../views/configuration/VehicleView.vue
          redirect: { name: 'build-routes' },
          component: () => import(/* webpackChunkName: "routes" */ './views/plan/PlanView.vue'),
          children: [
            {
              path: '/plan/build',
              name: 'build-routes',
              redirect: { name: 'paths' },
              // route level code-splitting
              // this generates a separate chunk (about.[hash].js) for this route
              // which is lazy-loaded when the route is visited.
              component: () => import(/* webpackChunkName: "build" */ './views/plan/BuildRoutesView.vue'),
              children: [
                {
                  path: '/',
                  name: 'paths',
                  component: () => import(/* webpackChunkName: "build" */ './views/plan/PlanPathsView.vue'),
                },
                {
                  path: '/plan/build/done',
                  name: 'all-planned',
                  component: () => import(/* webpackChunkName: "build" */ './views/plan/AllPlanned.vue'),
                  meta: {
                    title: 'Alt er fordelt',
                  },
                },
                {
                  path: '/plan/build/existing',
                  name: 'existing',
                  component: () => import(/* webpackChunkName: "build" */ './views/plan/PlanExistingRoutesView.vue'),
                  meta: {
                    title: 'Med eksisterender ruter',
                  },
                },
                {
                  path: '/plan/build/scratch',
                  name: 'scratch',
                  component: () => import(/* webpackChunkName: "build" */ './views/plan/PlanScratchView.vue'),
                  meta: {
                    title: 'Fra bunden',
                  },
                },
                {
                  path: '/plan/build/scratch/automatic',
                  name: 'scratch-automatic',
                  component: () => import(/* webpackChunkName: "build" */ './views/plan/PlanScratchAutomaticView.vue'),
                  meta: {
                    title: 'Fra bunden automatisk',
                  },
                },
              ],
            },
            {
              path: '/plan/map',
              name: 'map',
              // route level code-splitting
              // this generates a separate chunk (about.[hash].js) for this route
              // which is lazy-loaded when the route is visited.
              component: () => import(/* webpackChunkName: "map" */ './views/map/MapView.vue'),
              meta: {
                fullNav: true,
              },
            },
            {
              path: '/plan/route-viewer',
              name: 'route-viewer',
              // route level code-splitting
              // this generates a separate chunk (about.[hash].js) for this route
              // which is lazy-loaded when the route is visited.
              component: () => import(/* webpackChunkName: "map" */ './views/route-viewer/RouteViewerView.vue'),
              meta: {
                fullNav: true,
              },
            },
            {
              path: '/plan/tasks',
              name: 'tasks',
              meta: {
                fullNav: true,
              },
              // route level code-splitting
              // this generates a separate chunk (about.[hash].js) for this route
              // which is lazy-loaded when the route is visited.
              component: () => import(/* webpackChunkName: "tasks" */ './views/plan/TaskList.vue'),
            },
            {
              path: '/plan/transition-period-details',
              name: 'transition-period-details',
              // route level code-splitting
              // this generates a separate chunk (about.[hash].js) for this route
              // which is lazy-loaded when the route is visited.
              component: () => import(/* webpackChunkName: "transition-period-tasks-details" */ './views/plan/TransitionPeriodDetails.vue'),
              meta: {
                fullNav: false,
              },
            },
            {
              path: '/plan/transition-period-tasks',
              name: 'transition-period-tasks',
              // route level code-splitting
              // this generates a separate chunk (about.[hash].js) for this route
              // which is lazy-loaded when the route is visited.
              component: () => import(/* webpackChunkName: "transition-period-tasks" */ './views/plan/TransitionPeriodTaskList.vue'),
              meta: {
                fullNav: true,
              },
            },
          ],
        },
        {
          path: '/export',
          name: 'export',
          // route level code-splitting
          // this generates a separate chunk (about.[hash].js) for this route
          // which is lazy-loaded when the route is visited../views/configuration/VehicleView.vue
          redirect: { name: 'driving-lists' },
          component: () => import(/* webpackChunkName: "routes" */ './views/export/ExportView.vue'),
          children: [
            {
              path: '/export/driving-lists',
              name: 'driving-lists',
              // route level code-splitting
              // this generates a separate chunk (about.[hash].js) for this route
              // which is lazy-loaded when the route is visited.
              component: () => import(/* webpackChunkName: "routes" */ './views/export/DrivingListsView.vue'),
            },
            {
              path: '/export/transition-periods',
              name: 'transition-periods',
              // route level code-splitting
              // this generates a separate chunk (about.[hash].js) for this route
              // which is lazy-loaded when the route is visited.
              component: () => import(/* webpackChunkName: "transitionperiod" */ './views/export/TransitionPeriodsView.vue'),
            },
            {
              path: '/export/change-lists',
              name: 'change-lists',
              // route level code-splitting
              // this generates a separate chunk (about.[hash].js) for this route
              // which is lazy-loaded when the route is visited.
              component: () => import(/* webpackChunkName: "changelist" */ './views/export/ChangeListsView.vue'),
            },
            {
              path: '/export/external',
              name: 'task-export',
              // route level code-splitting
              // this generates a separate chunk (about.[hash].js) for this route
              // which is lazy-loaded when the route is visited.
              component: () => import(/* webpackChunkName: "tasks" */ './views/export/ExternalExport.vue'),
            },
            {
              path: '/export/municipality-addresses',
              name: 'municipality-address-export',
              // route level code-splitting
              // this generates a separate chunk (about.[hash].js) for this route
              // which is lazy-loaded when the route is visited.
              component: () => import(/* webpackChunkName: "tasks" */ './views/export/MunicipalityAddressExport.vue'),
            },
          ],
        },
        {
          path: '/configuration',
          name: 'configuration',
          redirect: { name: 'task-import-overview' },
          // route level code-splitting
          // this generates a separate chunk (about.[hash].js) for this route
          // which is lazy-loaded when the route is visited../views/configuration/VehicleView.vue
          component: () => import(/* webpackChunkName: "configuration" */ './views/configuration/ConfigurationView.vue'),
          children: [
            {
              path: '/configuration/import',
              name: 'task-import-overview',
              redirect: { name: 'task-import' },
              component: () => import(/* webpackChunkName: "configuration" */ './views/configuration/import/ImportOverView.vue'),
              children: [
                {
                  path: '/',
                  name: 'task-import',
                  component: () => import(/* webpackChunkName: "configuration" */ './views/configuration/import/ImportSelectionView.vue'),
                },
                {
                  path: '/configuration/import/extra',
                  name: 'task-import-extra',
                  component: () => import(/* webpackChunkName: "configuration" */ './views/configuration/import/ImportExtraView.vue'),
                },
                {
                  path: '/configuration/import/upload',
                  name: 'tasks-import-upload',
                  component: () => import(/* webpackChunkName: "configuration" */ './views/configuration/import/ImportUploadView.vue'),
                },
                {
                  path: '/configuration/import/merge',
                  name: 'task-import-merge',
                  component: () => import(/* webpackChunkName: "configuration" */ './views/configuration/import/MergeFlowPlan.vue'),
                },
                {
                  path: '/configuration/import/flow',
                  redirect: { name: 'task-import-merge-id' },
                  name: 'task-import-flow',
                  component: () => import(/* webpackChunkName: "configuration" */ './views/configuration/import/MergeFlow.vue'),
                  children: [
                    {
                      path: '/configuration/import/merge/id',
                      name: 'task-import-merge-id',
                      component: () => import(/* webpackChunkName: "configuration" */ './views/configuration/import/MergeFlowMergeId.vue'),
                    },
                    {
                      path: '/configuration/import/merge/frequencies',
                      name: 'task-import-merge-frequencies',
                      component: () => import(/* webpackChunkName: "configuration" */ './views/configuration/import/MergeFlowFrequencies.vue'),
                    },
                    {
                      path: '/configuration/import/merge/plan',
                      name: 'task-import-merge-plan',
                      component: () => import(/* webpackChunkName: "configuration" */ './views/configuration/import/MergeFlowPlan.vue'),
                    },
                    {
                      path: '/configuration/import/merge/execute',
                      name: 'task-import-merge-execute',
                      component: () => import(/* webpackChunkName: "configuration" */ './views/configuration/import/MergeFlowExecute.vue'),
                    },
                  ],
                },
              ],
            },
            {
              path: '/configuration/geocoding',
              name: 'geocode',
              component: () => import(/* webpackChunkName: "configuration" */ './views/configuration/ConfigurationGeocodeView.vue'),
            },
            {
              path: '/configuration/zonecoding',
              name: 'zonecode',
              component: () => import(/* webpackChunkName: "configuration" */ './views/configuration/ConfigurationZonecodeView.vue'),
            },
            {
              path: '/configuration/vehicles',
              name: 'vehicles',
              component: () => import(/* webpackChunkName: "configuration" */ './views/configuration/ConfigurationVehicleView.vue'),
            },
            {
              path: '/configuration/frequencies',
              name: 'frequencies',
              component: () => import(/* webpackChunkName: "configuration" */ './views/configuration/ConfigurationFrequencyView.vue'),
            },
            {
              path: '/configuration/materiel',
              name: 'materiel',
              component: () => import(/* webpackChunkName: "configuration" */ './views/configuration/ConfigurationMaterielView.vue'),
            },
            {
              path: '/configuration/disposal-category',
              name: 'disposal-categories',
              component: () => import(/* webpackChunkName: "configuration" */ './views/configuration/ConfigurationDisposalCategoryView.vue'),
            },
          ],
        },
      ],
    },
    {
      path: '/shared/:token',
      name: 'shared',
      component: () => import('./views/map/SharedMapView.vue'),
      props: true,
      meta: {
        shared: true,
      },
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('./views/auth/Login.vue'),
      meta: {
        public: true,
      },
    },
    {
      path: '/login-first-time',
      name: 'login-first',
      component: () => import('./views/auth/FirstLogin.vue'),
    },
    {
      path: '*',
      name: 'NotFoundPage',
      component: () => import('@/views/NotFoundPage'),
      meta: {
        public: true,
      },
    },
    {
      path: '/forbidden',
      name: 'forbidden',
      component: () => import('@/views/ForbiddenView'),
    },
    {
      path: '/forbidden-external-access',
      name: 'forbidden-external-access',
      component: () => import('@/views/ForbiddenExternalAccessView'),
    },
  ],
});

router.beforeEach(async (to, _from, next) => {
  const isPublic = to.matched.some((route) => route.meta.public);
  const isShared = to.matched.some((route) => route.meta.shared);
  if (!isPublic && !isShared) {
    try {
      await store.dispatch(`auth/${CHECK_AUTH}`);
    } catch {
      next({ name: 'login', query: { redirect: router.currentRoute.path } });
    }
  }

  next();
});

export default router;
