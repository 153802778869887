<template>
  <div
    class="fixed inset-0 px-4 pb-4 sm:flex sm:items-center sm:justify-center"
    style="z-index: 999999"
  >
    <transition
      enter-active-class="transition-all ease-out duration-100"
      enter-class="opacity-0"
      enter-to-class="opacity-100"
      leave-active-class="transition-all ease-in duration-200"
      leave-class="opacity-100"
      leave-to-class="opacity-0"
    >
      <div v-if="visible" class="fixed inset-0 transition-opacity">
        <div class="absolute inset-0 bg-black opacity-75"></div>
      </div>
    </transition>

    <transition
      enter-active-class="ease-out duration-100"
      enter-class="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
      enter-to-class="opacity-100 translate-y-0 sm:scale-100"
      leave-active-class="ease-in duration-200"
      leave-class="opacity-100 translate-y-0 sm:scale-100"
      leave-to-class="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
    >
      <div
        v-if="visible"
        class="bg-gray-900 rounded-lg overflow-hidden shadow-xl transform transition-all sm:max-w-lg sm:w-full"
        role="dialog"
        aria-modal="true"
        aria-labelledby="modal-headline"
      >
        <div class="bg-gray-200 px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
          <div class="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
            <button
              type="button"
              class="text-gray-900 hover:text-gray-500 focus:outline-none focus:text-gray-500 transition ease-in-out duration-150"
              aria-label="Close"
              @click="cancel"
            >
              <svg class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>
          <div class="sm:flex sm:items-start">
            <div
              class="flex flex-shrink-0 items-center justify-center h-12 w-12 rounded-full"
              :class="iconBackground"
            >
              <icon-check-circle v-if="type === 'success'" classes="h-6 w-6 text-green-200"></icon-check-circle>
              <icon-information-circle v-else-if="type === 'info'" classes="h-6 w-6 text-blue-200"></icon-information-circle>
              <icon-x-circle v-else-if="type === 'error'" classes="h-6 w-6 text-red-200"></icon-x-circle>
              <icon-exclamation-circle
                v-else-if="type === 'warning'"
                classes="h-6 w-6 text-yellow-900"
              ></icon-exclamation-circle>
            </div>
            <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
              <h3 class="text-lg leading-6 font-medium text-gray-900">{{ title }}</h3>
              <div class="mt-2">
                <p class="text-sm leading-5 text-gray-700">
                  <!-- eslint-disable-next-line vue/no-v-html -->
                  <span v-if="isHTML" v-html="bodyText"></span>
                  <span v-else>{{ bodyText }}</span>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="bg-gray-300 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
          <span class="flex w-full rounded-md shadow-sm sm:ml-3 sm:w-auto">
            <button
              type="button"
              class="confirm inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 text-base leading-6 font-medium shadow-sm focus:outline-none focus:shadow-outline-red transition ease-in-out duration-150 sm:text-sm sm:leading-5"
              :class="confirmClasses"
              @click="confirm"
            >{{ confirmText }}</button>
          </span>
          <span v-if="!hideCancel" class="mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:w-auto">
            <button
              type="button"
              class="cancel inline-flex justify-center w-full rounded-md border border-gray-300 px-4 py-2 bg-gray-100 text-base leading-6 font-medium text-gray-800 shadow-sm hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue transition ease-in-out duration-150 sm:text-sm sm:leading-5"
              @click="cancel"
            >{{ cancelText }}</button>
          </span>
        </div>
      </div>
    </transition>
  </div>
</template>

<script type="text/babel">
import { state } from '@/util/validator.props';

import { background600 } from '@/util/tailwind/backgrounds';
import { focusBorder700 } from '@/util/tailwind/focus';
import { hoverBackground500 } from '@/util/tailwind/hover';
import { stateToColor } from '@/util/tailwind/util';

/**
 * @displayName Confirm
 */
export default {
  props: {
    /**
     * State that will translate to a color scheme and icon
     * @values success | error | warning | info
     */
    type: {
      type: String,
      default: 'error',
      validator: state,
    },
    /**
     * Text inside cancel button
     */
    cancelText: {
      type: String,
      default: 'Fortryd',
    },
    /**
     * Whether cancel button should be hidden
     */
    hideCancel: {
      type: Boolean,
      default: false,
    },
    /**
     * Text inside confirm button
     */
    confirmText: {
      type: String,
      default: 'Slet',
    },
    /**
     * Title of dialog
     */
    title: {
      type: String,
      default: 'Slet ressource',
    },
    /**
     * Body text of dialog
     */
    bodyText: {
      type: String,
      default: 'Handlingen kan ikke fortrydes',
    },
    /**
     * Whether the bodyText should be rendered as HTML
     */
    isHTML: {
      type: Boolean,
      default: false,
    },
    /**
     * The css id of the html container id. The dialog will be attached to this element
     */
    htmlContainerId: {
      type: String,
      default: 'The id of the html container element the dialog will be attached to',
    },
  },
  data() {
    return {
      visible: false,
    };
  },
  computed: {
    color() {
      return stateToColor(this.type);
    },
    confirmClasses() {
      if (this.color === 'yellow') {
        return ['bg-yellow-200', 'text-yellow-900', focusBorder700[this.color], 'hover:bg-yellow-100'];
      }
      return [background600[this.color], 'text-white', focusBorder700[this.color], hoverBackground500[this.color]];
    },
    iconBackground() {
      if (this.color === 'yellow') {
        return 'bg-yellow-200';
      }
      return [background600[this.color]];
    },
  },
  mounted() {
    this.visible = true;
    document.addEventListener('keydown', this.keydown);
  },
  destroyed() {
    document.removeEventListener('keydown', this.keydown);
  },
  methods: {
    handleClose() {
      this.visible = false;

      setTimeout(() => {
        this.$destroy(true);
        this.$el.parentNode.removeChild(this.$el);
      }, 200);
    },
    keydown(e) {
      if (e.keyCode === 27) {
        // esc
        if (this.visible) {
          this.cancel();
        }
      }
    },
    confirm() {
      this.handleClose();
      /**
       * Fired when confirm button is pressed
       */
      this.$emit('confirm');
    },
    cancel() {
      this.handleClose();
      /**
       * Fired when dialog is closed or cancel button is clicked
       */
      this.$emit('cancel');
    },
  },
};
</script>
