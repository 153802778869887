export const text400 = {
  red: 'text-red-400',
  green: 'text-green-400',
  blue: 'text-blue-400',
  teal: 'text-teal-400',
  yellow: 'text-yellow-400',
  pink: 'text-pink-400',
  purple: 'text-purple-400',
  gray: 'text-gray-400',
  indigo: 'text-indigo-400',
  orange: 'text-orange-400',
};

export const text500 = {
  red: 'text-red-500',
  green: 'text-green-500',
  blue: 'text-blue-500',
  teal: 'text-teal-500',
  yellow: 'text-yellow-500',
  pink: 'text-pink-500',
  purple: 'text-purple-500',
  gray: 'text-gray-500',
  indigo: 'text-indigo-500',
  orange: 'text-orange-500',
};

export const text600 = {
  red: 'text-red-600',
  green: 'text-green-600',
  blue: 'text-blue-600',
  teal: 'text-teal-600',
  yellow: 'text-yellow-600',
  pink: 'text-pink-600',
  purple: 'text-purple-600',
  gray: 'text-gray-600',
  indigo: 'text-indigo-600',
  orange: 'text-orange-600',
};

export const text700 = {
  red: 'text-red-700',
  green: 'text-green-700',
  blue: 'text-blue-700',
  teal: 'text-teal-700',
  yellow: 'text-yellow-700',
  pink: 'text-pink-700',
  purple: 'text-purple-700',
  gray: 'text-gray-700',
  indigo: 'text-indigo-700',
  orange: 'text-orange-700',
};

export const text800 = {
  red: 'text-red-800',
  green: 'text-green-800',
  blue: 'text-blue-800',
  teal: 'text-teal-800',
  yellow: 'text-yellow-800',
  pink: 'text-pink-800',
  purple: 'text-purple-800',
  gray: 'text-gray-800',
  indigo: 'text-indigo-800',
  orange: 'text-orange-800',
};
