import { render, staticRenderFns } from "./PropertyZoneAggregation.vue?vue&type=template&id=4005307d&"
import script from "./PropertyZoneAggregation.vue?vue&type=script&lang=js&"
export * from "./PropertyZoneAggregation.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports