import ApiService from '@/common/api.service';

const ChangeListsService = {
  getAll() {
    const resource = 'change-lists/';
    return ApiService.get(resource);
  },

  downloadExcel(id) {
    const resource = `change-lists/${id}/excel`;
    ApiService.get(resource).then(({ data }) => {
      const link = document.createElement('a');
      link.href = data;
      link.click();
      link.remove();
    });
  },

  create() {
    const resource = 'change-lists/';

    return ApiService.post(resource);
  },
};

export default ChangeListsService;
