/* eslint-disable no-param-reassign */
/* eslint-disable camelcase */
/* eslint-disable object-curly-newline */
/* eslint-disable no-shadow */
import ChangeListsService from '@/services/changeLists.service';

import * as jobTypes from '@/util/jobTypes';
import * as a from '../actions.type';
import * as m from '../mutations.type';

const state = {
  changeLists: [],
  loading: false,
};

const getters = {
  changeLists(state) {
    return state.changeLists;
  },
  changeListsLoading(state) {
    return state.loading;
  },
};

const actions = {
  [a.GENERATE_CHANGE_LIST]({ commit }, payload) {
    return new Promise((resolve, reject) => {
      ChangeListsService.create(payload)
        .then(({ data }) => {
          const jobTypePayload = {
            type: jobTypes.GENERATECHANGELIST,
            jobId: data,
          };
          commit(`jobs/${m.SET_JOB_ID_BY_TYPE}`, jobTypePayload, { root: true });
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [a.GET_CHANGE_LISTS]({ commit }) {
    commit(m.SET_CHANGE_LISTS_LOADING, true);
    return new Promise((resolve, reject) => {
      ChangeListsService.getAll()
        .then(({ data }) => {
          commit(m.SET_CHANGE_LISTS, data);
          resolve();
        })
        .catch((err) => {
          reject(err);
        })
        .finally(() => {
          commit(m.SET_CHANGE_LISTS_LOADING, false);
        });
    });
  },
};

const mutations = {
  [m.SET_CHANGE_LISTS_LOADING](state, isLoading) {
    state.loading = isLoading;
  },
  [m.SET_CHANGE_LISTS](state, changeLists) {
    state.changeLists = changeLists;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
  namespaced: true,
};
