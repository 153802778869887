<template>
  <div class="w-full">
    <div class="flex flex-1 justify-center bg-gray-800 h-16 mx-auto w-full">
      <div class="flex flex-1 w-full transition-all" :class="{ 'desktop-container': !isFullWidth }">
        <the-brand :class="{ 'pl-4': isFullWidth }"></the-brand>

        <div class="flex flex-shrink-0 h-16 flex-1 justify-between px-4 sm:px-6 lg:px-8">
          <the-main-nav></the-main-nav>

          <div class="flex items-center ml-auto space-x-4">
            <router-link :to="{ name: 'workspaces' }" class="flex items-center">
            <span class="flex flex-col">
              <span
                  v-if="activeWorkspace"
                  class="text-sm text-white mr-2"
              >{{ activeWorkspace.name }} ({{ isInTransitionPeriod ? activeWorkspace.activeTransitionPeriodName : activeWorkspace.activeExperimentName }})</span>
            </span>
              <icon-cog class="w-5 h-5 text-white"></icon-cog>
            </router-link>

            <span
                v-if="isDev"
                class="cursor-pointer text-xs bg-yellow-200 text-yellow-800 px-4 py-2 rounded-md flex-center"
                @click="copyWorkspaceId"
            >
            <span>{{ activeWorkspace.activeTransitionPeriodId || activeWorkspace.id }}</span>
            <icon-clipboard class="h-4 w-4 ml-2"></icon-clipboard>
          </span>

            <base-dropdown placement="bottom-end">
              <icon-user slot="trigger" class="h-5 w-5 text-white"></icon-user>

              <div slot="menu" class="w-56">
                <div class="flex flex-col justify-center px-4 py-2 text-sm leading-5 text-gray-700">
                  <p class="block">{{ currentUser.email }}</p>
                </div>

                <button
                    class="group flex items-center px-4 py-2 w-full text-sm leading-5 text-gray-700 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 cursor-pointer"
                    @click="showUserPanelModal = true"
                >
                  <span class="text-gray-800 font-semibold">Brugerpanel</span>
                  <svg
                      class="w-5 h-5 ml-auto text-gray-400 group-hover:text-gray-500"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z"
                    />
                  </svg>
                </button>

                <button
                    class="border-t border-gray-100 group flex items-center px-4 py-2 w-full text-sm leading-5 text-gray-700 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 cursor-pointer"
                    @click="showHotkeyModal = true"
                >
                  <span class="text-gray-800 font-semibold">Genveje</span>

                  <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 32 32"
                      class="w-5 h-5 ml-auto"
                      fill="currentColor"
                  >
                    <path
                        style="text-indent:0;text-align:start;line-height:normal;text-transform:none;block-progression:tb;-inkscape-font-specification:Bitstream Vera Sans"
                        d="M 3 7 C 1.9069372 7 1 7.9069372 1 9 L 1 23 C 1 24.093063 1.9069372 25 3 25 L 29 25 C 30.093063 25 31 24.093063 31 23 L 31 9 C 31 7.9069372
                30.093063 7 29 7 L 3 7 z M 3 9 L 29 9 L 29 23 L 3 23 L 3 9 z M 5 11 L 5 13 L 7 13 L 7 11 L 5 11 z M 9 11 L 9 13 L 11 13 L 11 11 L 9 11 z M 13
                 11 L 13 13 L 15 13 L 15 11 L 13 11 z M 17 11 L 17 13 L 19 13 L 19 11 L 17 11 z M 21 11 L 21 13 L 23 13 L 23 11 L 21 11 z M 25 11 L 25 13 L 27
                  13 L 27 11 L 25 11 z M 5 15 L 5 17 L 9 17 L 9 15 L 5 15 z M 11 15 L 11 17 L 13 17 L 13 15 L 11 15 z M 15 15 L 15 17 L 17 17 L 17 15 L 15 15
                   z M 19 15 L 19 17 L 21 17 L 21 15 L 19 15 z M 23 15 L 23 17 L 27 17 L 27 15 L 23 15 z M 5 19 L 5 21 L 9 21 L 9 19 L 5 19 z M 11 19 L 11 21
                    L 21 21 L 21 19 L 11 19 z M 23 19 L 23 21 L 27 21 L 27 19 L 23 19 z"
                    />
                  </svg>
                </button>

                <button
                    class="border-t rounded-b border-gray-100 group flex items-center px-4 py-2 w-full text-sm leading-5 text-gray-700 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 cursor-pointer"
                    @click="logout"
                >
                  <span class="text-gray-800 font-semibold">Log ud</span>

                  <svg
                      class="w-5 h-5 ml-auto"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"
                    />
                  </svg>
                </button>
              </div>
            </base-dropdown>
          </div>
        </div>
      </div>
    </div>

    <the-hotkey-modal v-model="showHotkeyModal"></the-hotkey-modal>
    <user-panel-modal v-model="showUserPanelModal"></user-panel-modal>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import logoutMixin from '@/mixins/logoutMixin';
import TheBrand from '@/layout/TheBrand.vue';
import TheMainNav from '@/layout/nav/TheMainNav.vue';
import TheHotkeyModal from '@/components/TheHotkeyModal.vue';
import UserPanelModal from '@/components/UserPanelModal';

export default {
  name: 'TheDashboardNavbar',
  components: {
    UserPanelModal,
    TheBrand,
    TheMainNav,
    TheHotkeyModal,
  },
  mixins: [logoutMixin],
  data() {
    return {
      showHotkeyModal: false,
      showUserPanelModal: false,
      isDev: process.env.NODE_ENV !== 'production',
      isDropdownOpen: false,
    };
  },
  computed: {
    ...mapGetters('auth', ['currentUser']),
    ...mapGetters('departments', ['activeWorkspace', 'isInTransitionPeriod']),
    isFullWidth() {
      return this.$route.matched.some((x) => x.meta.fullNav);
    },
  },
  methods: {
    copyWorkspaceId() {
      navigator.clipboard.writeText(this.activeWorkspace.activeTransitionPeriodId || this.activeWorkspace.id);
      this.$ntf.success('Copied workspace id');
    },
    toggleDropdown() {
      this.isDropdownOpen = !this.isDropdownOpen;
    },
  },
};
</script>
