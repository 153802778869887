// eslint-disable-next-line no-unused-vars
import ApiService from '@/common/api.service';

const PlannedInstanceService = {
  // eslint-disable-next-line no-unused-vars
  getFiltered(payload) {
    const resource = 'planning/';
    return ApiService.post(resource, payload);
  },
  buildValidation() {
    const resource = 'planning/validations/build/';
    return ApiService.get(resource);
  },
  buildExistingRoutes() {
    const resource = 'planning/existing/build/';
    return ApiService.post(resource);
  },
  buildRoutesFromScratch() {
    const resource = 'planning/scratch/build/';
    return ApiService.post(resource);
  },
  buildRoutesFromScratchAutomatic() {
    const resource = 'planning/scratch/automatic/build/';
    return ApiService.post(resource);
  },
};

export default PlannedInstanceService;
