import JobService from '@/services/job.service';
import * as jobStatus from '@/util/jobStatus';

export default function pollJob(jobId, emitFn = null, updateProgress = null) {
  const pollingInterval = 1500; // Poll every 1.5 seconds
  return new Promise((resolve, reject) => {
    const interval = setInterval(async () => {
      try {
        const { data } = await JobService.poll(jobId);
        const { status, progress_message, related_ids } = data;

        // Optionally update progress message
        if (typeof updateProgress === 'function') {
          updateProgress(progress_message);
        }

        // Optionally emit related IDs event
        if (typeof emitFn === 'function') {
          emitFn('update-related-ids', related_ids);
        }

        if (status === jobStatus.SUCCEEDED) {
          clearInterval(interval); // Stop polling
          resolve(data); // Resolve with job result
        } else if (status === jobStatus.FAILED) {
          clearInterval(interval); // Stop polling
          reject(new Error(data.error || 'Job failed'));
        }
      } catch (error) {
        clearInterval(interval); // Stop polling on API failure
        reject(error); // Reject with the caught error
      }
    }, pollingInterval);
  });
}
