export const activeBackground50 = {
  red: 'active:bg-red-50',
  green: 'active:bg-green-50',
  blue: 'active:bg-blue-50',
  teal: 'active:bg-teal-50',
  yellow: 'active:bg-yellow-50',
  pink: 'active:bg-pink-50',
  purple: 'active:bg-purple-50',
  gray: 'active:bg-gray-50',
  indigo: 'active:bg-indigo-50',
  orange: 'active:bg-orange-50',
};

export const activeBackground200 = {
  red: 'active:bg-red-200',
  green: 'active:bg-green-200',
  blue: 'active:bg-blue-200',
  teal: 'active:bg-teal-200',
  yellow: 'active:bg-yellow-200',
  pink: 'active:bg-pink-200',
  purple: 'active:bg-purple-200',
  gray: 'active:bg-gray-200',
  indigo: 'active:bg-indigo-200',
  orange: 'active:bg-orange-200',
};

export const activeBackground700 = {
  red: 'active:bg-red-700',
  green: 'active:bg-green-700',
  blue: 'active:bg-blue-700',
  teal: 'active:bg-teal-700',
  yellow: 'active:bg-yellow-700',
  pink: 'active:bg-pink-700',
  purple: 'active:bg-purple-700',
  gray: 'active:bg-gray-700',
  indigo: 'active:bg-indigo-700',
  orange: 'active:bg-orange-700',
};

export const activeText800 = {
  red: 'active:text-red-800',
  green: 'active:text-green-800',
  blue: 'active:text-blue-800',
  teal: 'active:text-teal-800',
  yellow: 'active:text-yellow-800',
  pink: 'active:text-pink-800',
  purple: 'active:text-purple-800',
  gray: 'active:text-gray-800',
  indigo: 'active:text-indigo-800',
  orange: 'active:text-orange-800',
};
