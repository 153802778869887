import ApiService from '@/common/api.service';

const DrivingListsService = {
  getAll() {
    const resource = 'driving-lists/';
    return ApiService.get(resource);
  },

  getAllCandidates() {
    const resource = 'driving-lists/candidates/';
    return ApiService.get(resource);
  },

  download(id) {
    const resource = `driving-lists/${id}/downloads/`;
    ApiService.get(resource).then(({ data }) => {
      const link = document.createElement('a');
      link.href = data;
      link.click();
      link.remove();
    });
  },

  create(payload) {
    const resource = 'driving-lists/';

    return ApiService.post(resource, payload);
  },
  exists() {
    const resource = 'driving-lists/status/';
    return ApiService.get(resource);
  },

  getZippedDrivingList() {
    const resource = 'driving-lists/zipped/';
    return ApiService.get(resource);
  },

  downloadZip() {
    const resource = 'driving-lists/zipped/downloads/';
    ApiService.get(resource).then(({ data }) => {
      const link = document.createElement('a');
      link.href = data;
      link.click();
      link.remove();
    });
  },
  createVersion(drivingListId, payload) {
    return ApiService.post(
        `driving-lists/${String(drivingListId)}/versions/`,
        payload,
    );
  },
};

export default DrivingListsService;
