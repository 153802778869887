import SharedService from '@/services/shared.service';
import * as a from '../actions.type';
import * as m from '../mutations.type';

const actions = {
  [a.SHARED_ALL_VEHICLES](context) {
    // Initially clear vehicles state, if necessary
    context.commit(m.SET_VEHICLES_WITH_MODULE, [], { root: true });
    return new Promise((resolve, reject) => {
      SharedService.getAllVehicles()
        .then(({ data }) => {
          context.commit(m.SET_VEHICLES_WITH_MODULE, data, { root: true });
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [a.SHARED_GET_MOST_IRREGULAR_FREQUENCY](context) {
    return new Promise((resolve, reject) => {
      SharedService.getMostIrregularFrequencyIntervals()
        .then(({ data }) => {
          context.commit(m.SET_MOST_IRREGULAR_FREQUENCY_WITH_MODULE, data, { root: true });
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [a.SHARED_GET_FREQUENCY_OPTIONS](context) {
    return new Promise((resolve, reject) => {
      SharedService.getFrequencies()
        .then(({ data }) => {
          context.commit(m.SET_FREQUENCY_OPTIONS_WITH_MODULE, data, { root: true });
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};

export default {
  actions, namespaced: true,
};
