export const GEOCODING = 'GEOCODING';
export const ZONECODING = 'ZONECODING';
export const BUILD_EXPERIMENT = 'BUILDEXPERIMENT';
export const DELETE_EXPERIMENT = 'DELETEEXPERIMENT';
export const DELETE_WORKSPACE = 'DELETEWORKSPACE';
export const COPY_WORKSPACE = 'COPYWORKSPACE';
export const IMPORT_TASKS = 'IMPORTTASKS';
export const IMPORT_EXTRA_TASKS = 'IMPORTEXTRATASKS';

export const NONINITIALIMPORT = 'NONINITIALIMPORT';
export const EXECUTEMERGEPLAN = 'EXECUTEMERGEPLAN';
export const GENERATEDRIVINGLISTS = 'GENERATEDRIVINGLISTS';

export const UPDATEDURATIONSANDDISTANCES = 'UPDATEDURATIONSANDDISTANCES';
export const TRANSITIONPERIODWORKSPACE = 'TRANSITIONPERIODWORKSPACE';
export const GENERATECHANGELIST = 'GENERATECHANGELIST';
export const EXPORT_EXTERNAL = 'EXPORTEXTERNAL';

/* Planning with manual */
export const BUILD_EXISTING_ROUTES = 'BUILDEXISTINGROUTES';
export const BUILD_ROUTES_FROM_SCRATCH = 'BUILDROUTESFROMSCRATCH';

/* Planning with algorithm */
export const BUILD_ROUTES_FROM_SCRATCH_AUTOMATIC = 'BUILDROUTESFROMSCRATCHAUTOMATIC';

/* Data generation */
export const DATAGENERATIONADDRESSES = 'DATAGENERATIONADDRESSES';
